import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  MenuItem,
  TableCell,
  TableContainer,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

function PaymentEntry() {
  const [branches, setBranches] = useState([]);
  const [ledgers, setLedgers] = useState([]);
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    branchCode: "",
    txnDate: "",
    ledgerId: "",
    selectType: "",
    receiptNo: "",
    directOrTransfer: "",
    amount: "",
    narration: "",
  });
  const [shouldUpdate, setShouldUpdate] = useState(false); // State to trigger entry updates
  const token = localStorage.getItem("token");

  // Fetch branches
  useEffect(() => {
    if (token) {
      axios
        .get("https://api.vlnidhi.com/api/admin/getallbid", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setBranches(response.data);
        })
        .catch((error) => {
          console.error("Error fetching branches:", error);
        });
    } else {
      console.error("Token not found");
    }
  }, [token]);

  // Fetch ledgers
  useEffect(() => {
    if (token) {
      axios
        .get("https://api.vlnidhi.com/api/admin/ledgers", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setLedgers(response.data);
        })
        .catch((error) => {
          console.error("Error fetching ledgers:", error);
        });
    } else {
      console.error("Token not found");
    }
  }, [token]);

  // Fetch payment entries
  const fetchEntries = () => {
    if (token) {
      axios
        .get("https://api.vlnidhi.com/api/admin/payment-entries", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setEntries(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching payment entries:", error);
        });
    } else {
      console.error("Token not found");
    }
  };

  useEffect(() => {
    fetchEntries(); // Fetch entries on initial load and whenever shouldUpdate changes
  }, [token, shouldUpdate]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const payload = { ...formData };
      const response = await axios.post(
        "https://api.vlnidhi.com/api/admin/payment-entries",
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const { voucherNo } = response.data;

      // Show SweetAlert with the voucher number
      await Swal.fire({
        title: "Payment Entry Saved!",
        text: `Your Voucher Number is: ${voucherNo}`,
        icon: "success",
        confirmButtonText: "OK",
      });

      // Reset the form
      setFormData({
        branchCode: "",
        txnDate: "",
        ledgerId: "",
        selectType: "",
        receiptNo: "",
        directOrTransfer: "",
        amount: "",
        narration: "",
      });

      // Trigger update for entries
      setShouldUpdate((prev) => !prev);

    } catch (error) {
      console.error("Error saving payment entry:", error);
      Swal.fire({
        title: "Error!",
        text: "There was an issue saving the payment entry.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setLoading(false);
    }
  };

  const textFieldStyleSearch = {
    width: { xs: "100%", md: "100%" },
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { md: "48px", xs: "40px" },
    },
  };

  const textFieldStyleEntry = {
    width: { xs: "100%", md: "100%" },
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { md: "48px", xs: "40px" },
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
  };
  


  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Payment Voucher
      </Typography>

      {/* Search Box */}
      <Paper
        sx={{
          padding: 3,
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          "@media (max-width: 600px)": {
            padding: "10px",
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Search Box
        </Typography>

        <Grid container spacing={1}>
          {/* Branch Dropdown */}
          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Branch
              </Typography>
              <TextField
                select
                name="branch"
                sx={textFieldStyleSearch}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.branchCode}>
                    {branch.branchName} ({branch.branchCode})
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>

          {/* From Date */}
          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                From Date
              </Typography>
              <TextField
                type="date"
                name="fromDate"
                variant="outlined"
                sx={{
                  ...textFieldStyleSearch,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Box>
          </Grid>

          {/* To Date */}
          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                To Date
              </Typography>
              <TextField
                type="date"
                name="toDate"
                sx={{
                  ...textFieldStyleSearch,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  fontFamily: "Poppins",
                  background: "#DFBF41",
                  width: { xs: "100%", md: "157px" },
                  height: { md: "48px", xs: "30px" },
                  borderRadius: "10px",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "#B89B3E" },
                  color: "white",
                  mt: { md: 2.5, xs: 1 },
                }}
              >
                Search
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* Entry Details */}
      <Paper
        sx={{
          padding: 3,
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          "@media (max-width: 600px)": {
            padding: "10px",
          },
          mt: 2,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Entry Details
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Select Branch<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                select
                name="branchCode"
                value={formData.branchCode}
                onChange={handleInputChange}
                sx={textFieldStyleEntry}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.branchCode}>
                    {branch.branchName} ({branch.branchCode})
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Select Type<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                select
                name="selectType"
                value={formData.selectType}
                onChange={handleInputChange}
                sx={textFieldStyleEntry}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
              <MenuItem>Select</MenuItem>
              <MenuItem value="DIRECT">Direct</MenuItem>
              <MenuItem value="TRANSFER">Transfer</MenuItem>
              </TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Receipt No.(Auto)<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
              name="voucherNo"
                sx={textFieldStyleEntry}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Direct/Transfer<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                select
                name="directOrTransfer"
                value={formData.directOrTransfer}
                onChange={handleInputChange}
                sx={textFieldStyleEntry}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
              <MenuItem >Select</MenuItem>
                <MenuItem value="CASH_AC">Cash A/C</MenuItem>
                <MenuItem value="Account_1">QUINT120239021559927</MenuItem>
                <MenuItem value="Account_2">000888700000461</MenuItem>
                <MenuItem value="Account_3">QUINT220239021559927</MenuItem>

              </TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Txn Date<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                type="date"
                name="txnDate"
                value={formData.txnDate}
                onChange={handleInputChange}
                sx={textFieldStyleEntry}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Amount<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                name="amount"
                sx={textFieldStyleEntry}
                value={formData.amount}
                onChange={handleInputChange}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
  <Box sx={{ display: "flex", flexDirection: "column" }}>
    <Typography
      sx={{
        width: "100%",
        fontSize: { xs: "16px", md: "18px" },
        ...fontStyle,
      }}
    >
      Select Ledger<span style={{ color: "red" }}>*</span>
    </Typography>
            <TextField
          select
          name="ledgerId"
          value={formData.ledgerId}
          onChange={handleInputChange}
          sx={textFieldStyleEntry}
          InputProps={{
            style: { borderRadius: "10px" },
          }}
        >
          {ledgers.map((ledger) => (
            <MenuItem key={ledger.id} value={ledger.id}>
              {ledger.ledgerName} ({ledger.id})
            </MenuItem>
          ))}
        </TextField>
          </Box>
        </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Narration (if any)
              </Typography>
              <TextField
              name="narration"
               value={formData.narration}
               onChange={handleInputChange}
                sx={textFieldStyleEntry}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              textTransform: "none",
              fontFamily: "Poppins",
              background: "#DFBF41",
              width: { xs: "100%", md: "157px" },
              height: { md: "48px", xs: "30px" },
              borderRadius: "10px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#B89B3E" },
              color: "white",
              mt: { md: 2.5, xs: 1 },
            }}
          >
            Save
          </Button>
        </Box>
      </Paper>

            {/* Search Result */}
      <Paper sx={{ marginTop: "20px", p: 3, borderRadius: "20px" }}>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 2,
            mt: 1,
          }}
        >
          Search Result
        </Typography>
        <TableContainer
          
          sx={{ borderRadius: "20px 20px 0px 0px" }}
        >
          <Table >
            <TableHead sx={{ background: "#413AF1" }}>
              <TableRow>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Vochuer No
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Branch
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  TDate
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Debit From
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Ledger
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Amount
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Approve
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {entries.map((entry) => (
              <TableRow key={entry.voucherNo}>
                <TableCell>{entry.voucherNo}</TableCell>
                <TableCell>{entry.branch}</TableCell>
                <TableCell>{entry.tDate}</TableCell>
                <TableCell>{entry.debitFrom}</TableCell>
                <TableCell>{entry.ledger}</TableCell>
                <TableCell>{entry.amount}</TableCell>
                <TableCell>{entry.status}</TableCell>
                <TableCell>
                  <Button >
                    <VisibilityIcon/>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

export default PaymentEntry;







// import {
//   Box,
//   Grid,
//   Paper,
//   TextField,
//   Typography,
//   MenuItem,
//   TableCell,
//   TableContainer,
//   Button,
//   Table,
//   TableHead,
//   TableBody,
//   TableRow,
// } from "@mui/material";
// import React, { useState, useEffect } from "react";
// import axios from "axios";

// function PaymentEntry() {
//   const [branches, setBranches] = useState([]);
//   const token = localStorage.getItem("token"); // Make sure this token is valid

//   const textFieldStyleSearch = {
//     width: { xs: "100%", md: "100%" },
//     backgroundColor: "#FFFFFF",
//     "& .MuiInputBase-root": {
//       height: { md: "48px", xs: "40px" },
//     },
//   };

//   const textFieldStyleEntry = {
//     width: { xs: "100%", md: "100%" },
//     backgroundColor: "#FFFFFF",
//     "& .MuiInputBase-root": {
//       height: { md: "48px", xs: "40px" },
//     },
//   };

//   const fontStyle = {
//     fontFamily: "Poppins, sans-serif",
//     fontSize: "14px",
//   };

//   // Fetch branches from API
//   useEffect(() => {
//     if (token) {
//       axios
//         .get("https://api.vlnidhi.com/api/admin/getallbid", {
//           headers: {
//             Authorization: `Bearer ${token}`, // Correct header property
//           },
//         })
//         .then((response) => {
//           setBranches(response.data);
//         })
//         .catch((error) => {
//           console.error("Error fetching branches:", error);
//         });
//     } else {
//       console.error("Token not found");
//     }
//   }, [token]);


//   return (
//     <Box>
//       <Typography
//         sx={{
//           fontFamily: "Poppins",
//           fontSize: "24px",
//           fontWeight: 600,
//           lineHeight: "36px",
//           mb: 1,
//         }}
//       >
//         Payment Voucher
//       </Typography>

//       {/* Search Box */}
//       <Paper
//         sx={{
//           padding: 3,
//           borderRadius: "20px",
//           boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
//           "@media (max-width: 600px)": {
//             padding: "10px",
//           },
//         }}
//       >
//         <Typography
//           sx={{
//             fontFamily: "Poppins",
//             fontSize: "20px",
//             fontWeight: 600,
//             lineHeight: "36px",
//             mb: 1,
//             mt: 0,
//           }}
//         >
//           Search Box
//         </Typography>

//         <Grid container spacing={1}>
//           {/* Branch Dropdown */}
//           <Grid item xs={12} md={3}>
//             <Box sx={{ display: "flex", flexDirection: "column" }}>
//               <Typography
//                 sx={{
//                   width: "100%",
//                   fontSize: { xs: "16px", md: "18px" },
//                   ...fontStyle,
//                 }}
//               >
//                 Branch
//               </Typography>
//               <TextField
//                 select
//                 name="branch"
//                 sx={textFieldStyleSearch}
//                 InputProps={{
//                   style: { borderRadius: "10px" },
//                 }}
//               >
//                 {branches.map((branch) => (
//                   <MenuItem key={branch.id} value={branch.branchCode}>
//                     {branch.branchName} ({branch.branchCode})
//                   </MenuItem>
//                 ))}
//               </TextField>
//             </Box>
//           </Grid>

//           {/* From Date */}
//           <Grid item xs={12} md={3}>
//             <Box sx={{ display: "flex", flexDirection: "column" }}>
//               <Typography
//                 sx={{
//                   width: "100%",
//                   fontSize: { xs: "16px", md: "18px" },
//                   ...fontStyle,
//                 }}
//               >
//                 From Date
//               </Typography>
//               <TextField
//                 type="date"
//                 name="fromDate"
//                 variant="outlined"
//                 sx={{
//                   ...textFieldStyleSearch,
//                 }}
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 InputProps={{
//                   style: {
//                     borderRadius: "10px",
//                   },
//                 }}
//               />
//             </Box>
//           </Grid>

//           {/* To Date */}
//           <Grid item xs={12} md={3}>
//             <Box sx={{ display: "flex", flexDirection: "column" }}>
//               <Typography
//                 sx={{
//                   width: "100%",
//                   fontSize: { xs: "16px", md: "18px" },
//                   ...fontStyle,
//                 }}
//               >
//                 To Date
//               </Typography>
//               <TextField
//                 type="date"
//                 name="toDate"
//                 sx={{
//                   ...textFieldStyleSearch,
//                 }}
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 InputProps={{
//                   style: {
//                     borderRadius: "10px",
//                   },
//                 }}
//               />
//             </Box>
//           </Grid>
//           <Grid item xs={12} md={3}>
//             <Box sx={{ display: "flex", justifyContent: "center" }}>
//               <Button
//                 variant="contained"
//                 sx={{
//                   textTransform: "none",
//                   fontFamily: "Poppins",
//                   background: "#DFBF41",
//                   width: { xs: "100%", md: "157px" },
//                   height: { md: "48px", xs: "30px" },
//                   borderRadius: "10px",
//                   fontWeight: "bold",
//                   "&:hover": { backgroundColor: "#B89B3E" },
//                   color: "white",
//                   mt: { md: 2.5, xs: 1 },
//                 }}
//               >
//                 Search
//               </Button>
//             </Box>
//           </Grid>
//         </Grid>
//       </Paper>

//       {/* Entry Details */}
//       <Paper
//         sx={{
//           padding: 3,
//           borderRadius: "20px",
//           boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
//           "@media (max-width: 600px)": {
//             padding: "10px",
//           },
//           mt: 2,
//         }}
//       >
//         <Typography
//           sx={{
//             fontFamily: "Poppins",
//             fontSize: "20px",
//             fontWeight: 600,
//             lineHeight: "36px",
//             mb: 1,
//             mt: 0,
//           }}
//         >
//           Entry Details
//         </Typography>

//         <Grid container spacing={2}>
//           <Grid item xs={12} md={6}>
//             <Box sx={{ display: "flex", flexDirection: "column" }}>
//               <Typography
//                 sx={{
//                   width: "100%",
//                   fontSize: { xs: "16px", md: "18px" },
//                   ...fontStyle,
//                 }}
//               >
//                 Select Branch<span style={{ color: "red" }}>*</span>
//               </Typography>
//               <TextField
//                 select
//                 name="branch"
//                 sx={textFieldStyleEntry}
//                 InputProps={{
//                   style: { borderRadius: "10px" },
//                 }}
//               >
//                 {branches.map((branch) => (
//                   <MenuItem key={branch.id} value={branch.branchCode}>
//                     {branch.branchName} ({branch.branchCode})
//                   </MenuItem>
//                 ))}
//               </TextField>
//             </Box>
//           </Grid>

//           <Grid item xs={12} md={6}>
//             <Box sx={{ display: "flex", flexDirection: "column" }}>
//               <Typography
//                 sx={{
//                   width: "100%",
//                   fontSize: { xs: "16px", md: "18px" },
//                   ...fontStyle,
//                 }}
//               >
//                 Select Type<span style={{ color: "red" }}>*</span>
//               </Typography>
//               <TextField
//                 select
//                 sx={textFieldStyleEntry}
//                 InputProps={{
//                   style: { borderRadius: "10px" },
//                 }}
//               >
//               <MenuItem>Select</MenuItem>
//               <MenuItem value="Direct">Direct</MenuItem>
//               <MenuItem value="Transfer">Transfer</MenuItem>
//               </TextField>
//             </Box>
//           </Grid>

//           <Grid item xs={12} md={6}>
//             <Box sx={{ display: "flex", flexDirection: "column" }}>
//               <Typography
//                 sx={{
//                   width: "100%",
//                   fontSize: { xs: "16px", md: "18px" },
//                   ...fontStyle,
//                 }}
//               >
//                 Receipt No.(Auto)<span style={{ color: "red" }}>*</span>
//               </Typography>
//               <TextField
//                 sx={textFieldStyleEntry}
//                 InputProps={{
//                   style: { borderRadius: "10px" },
//                 }}
//               ></TextField>
//             </Box>
//           </Grid>

//           <Grid item xs={12} md={6}>
//             <Box sx={{ display: "flex", flexDirection: "column" }}>
//               <Typography
//                 sx={{
//                   width: "100%",
//                   fontSize: { xs: "16px", md: "18px" },
//                   ...fontStyle,
//                 }}
//               >
//                 Direct/Transfer<span style={{ color: "red" }}>*</span>
//               </Typography>
//               <TextField
//                 select
//                 sx={textFieldStyleEntry}
//                 InputProps={{
//                   style: { borderRadius: "10px" },
//                 }}
//               >
//               <MenuItem >Select</MenuItem>
//                 <MenuItem value="CASH_AC">Cash A/C</MenuItem>
//               </TextField>
//             </Box>
//           </Grid>

//           <Grid item xs={12} md={6}>
//             <Box sx={{ display: "flex", flexDirection: "column" }}>
//               <Typography
//                 sx={{
//                   width: "100%",
//                   fontSize: { xs: "16px", md: "18px" },
//                   ...fontStyle,
//                 }}
//               >
//                 Txn Date<span style={{ color: "red" }}>*</span>
//               </Typography>
//               <TextField
//                 type="date"
//                 sx={textFieldStyleEntry}
//                 InputProps={{
//                   style: { borderRadius: "10px" },
//                 }}
//               ></TextField>
//             </Box>
//           </Grid>

//           <Grid item xs={12} md={6}>
//             <Box sx={{ display: "flex", flexDirection: "column" }}>
//               <Typography
//                 sx={{
//                   width: "100%",
//                   fontSize: { xs: "16px", md: "18px" },
//                   ...fontStyle,
//                 }}
//               >
//                 Amount<span style={{ color: "red" }}>*</span>
//               </Typography>
//               <TextField
//                 sx={textFieldStyleEntry}
//                 InputProps={{
//                   style: { borderRadius: "10px" },
//                 }}
//               ></TextField>
//             </Box>
//           </Grid>

//           <Grid item xs={12} md={6}>
//             <Box sx={{ display: "flex", flexDirection: "column" }}>
//               <Typography
//                 sx={{
//                   width: "100%",
//                   fontSize: { xs: "16px", md: "18px" },
//                   ...fontStyle,
//                 }}
//               >
//                 Select Ledger<span style={{ color: "red" }}>*</span>
//               </Typography>
//               <TextField
//                 select
//                 sx={textFieldStyleEntry}
//                 InputProps={{
//                   style: { borderRadius: "10px" },
//                 }}
//               ></TextField>
//             </Box>
//           </Grid>

//           <Grid item xs={12} md={6}>
//             <Box sx={{ display: "flex", flexDirection: "column" }}>
//               <Typography
//                 sx={{
//                   width: "100%",
//                   fontSize: { xs: "16px", md: "18px" },
//                   ...fontStyle,
//                 }}
//               >
//                 Narration (if any)
//               </Typography>
//               <TextField
//                 sx={textFieldStyleEntry}
//                 InputProps={{
//                   style: { borderRadius: "10px" },
//                 }}
//               ></TextField>
//             </Box>
//           </Grid>
//         </Grid>
//         <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
//           <Button
//             variant="contained"
//             sx={{
//               textTransform: "none",
//               fontFamily: "Poppins",
//               background: "#DFBF41",
//               width: { xs: "100%", md: "157px" },
//               height: { md: "48px", xs: "30px" },
//               borderRadius: "10px",
//               fontWeight: "bold",
//               "&:hover": { backgroundColor: "#B89B3E" },
//               color: "white",
//               mt: { md: 2.5, xs: 1 },
//             }}
//           >
//             Save
//           </Button>
//         </Box>
//       </Paper>
      // {/* Search Result */}
      // <Paper sx={{ marginTop: "20px", p: 3, borderRadius: "20px" }}>
      //   <Typography
      //     sx={{
      //       fontFamily: "Poppins",
      //       fontSize: "20px",
      //       fontWeight: 600,
      //       lineHeight: "36px",
      //       mb: 2,
      //       mt: 1,
      //     }}
      //   >
      //     Search Result
      //   </Typography>
      //   <TableContainer
      //     component={Paper}
      //     sx={{ borderRadius: "20px 20px 0px 0px" }}
      //   >
      //     <Table aria-label="simple table" dense size="small">
      //       <TableHead sx={{ background: "#413AF1" }}>
      //         <TableRow>
      //           <TableCell sx={{ color: "white", fontWeight: "bold" }}>
      //             Vochuer No
      //           </TableCell>
      //           <TableCell sx={{ color: "white", fontWeight: "bold" }}>
      //             Branch
      //           </TableCell>
      //           <TableCell sx={{ color: "white", fontWeight: "bold" }}>
      //             TDate
      //           </TableCell>
      //           <TableCell sx={{ color: "white", fontWeight: "bold" }}>
      //             Debit From
      //           </TableCell>
      //           <TableCell sx={{ color: "white", fontWeight: "bold" }}>
      //             Ledger
      //           </TableCell>
      //           <TableCell sx={{ color: "white", fontWeight: "bold" }}>
      //             Amount
      //           </TableCell>
      //           <TableCell sx={{ color: "white", fontWeight: "bold" }}>
      //             Approve
      //           </TableCell>
      //           <TableCell sx={{ color: "white", fontWeight: "bold" }}>
      //             Action
      //           </TableCell>
      //         </TableRow>
      //       </TableHead>
      //       <TableBody>
      //         <TableRow>
      //           <TableCell>Vochuer No</TableCell>
      //           <TableCell>Branch</TableCell>
      //           <TableCell>TDate</TableCell>
      //           <TableCell>Debit From</TableCell>
      //           <TableCell>Ledger</TableCell>
      //           <TableCell>Amount</TableCell>
      //           <TableCell>Approve</TableCell>
      //           <TableCell>Action</TableCell>
      //         </TableRow>
      //       </TableBody>
      //     </Table>
      //   </TableContainer>
      // </Paper>
//     </Box>
//   );
// }

// export default PaymentEntry;

