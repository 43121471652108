import React from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

function AddNewBeneficiary() {
  const textFieldStyle = {
    width: { xs: "100%", md: "100%" },
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    width: "100%",
    fontSize: { xs: "12px", md: "14px" },
  };
  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Add New Beneficiary
      </Typography>
      <Paper
        sx={{
          padding: 3,
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          "@media (max-width: 600px)": {
            padding: "10px",
          },
        }}
      >
        <Box>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "36px",
              mb: 1,
              mt: 0,
            }}
          >
            Search Details
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Search By Bank A/C*
              </Typography>
              <TextField
                sx={{ ...textFieldStyle }}
                InputProps={{ style: { borderRadius: "10px" } }}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                sx={{
                  background: "#E8C30B",
                  borderRadius: "10px",
                  textTransform: "none",
                  color: "white",
                  width: "118px#E8C30B",
                  mt: 2.3,
                  width: "118px",
                  height: "48px",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Paper
        sx={{
          padding: 3,
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          "@media (max-width: 600px)": {
            padding: "10px",
          },
          mt: 2,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Beneficiary List
        </Typography>
        <TableContainer
          component={Paper}
          sx={{ borderRadius: "20px 20px 0px 0px" }}
        >
          <Table>
            <TableHead sx={{ background: "#413AF1", color: "white" }}>
              <TableRow>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Sl No</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Ben ID</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Name</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Mobile</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Email</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Bank Account</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>IFSC</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>UPI</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Action</strong>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

export default AddNewBeneficiary;
