import { React, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  Card,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Container,
} from "@mui/material";

const textFieldStyle = {
  // width: { xs: "290px", lg: "578px" },
  height: "48px",
  borderRadius: "80%",
  borderColor: "#D9D9D9CC",
  backgroundColor: "#FFFFFF",
  "& .MuiInputBase-root": {
    height: "48px",
  },
};

const fontStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "14px",
  opacity: 0.6,
};

const MandateDepositBank = () => {
  const [showform, setShowform] = useState(false);

  const handleSearchClick = () => {
    setShowform(true);
  };

  return (
    <Box>
      <Typography
        sx={{
          fontSize: { sx: "20px", md: "24px" },
          fontWeight: 600,
          lineHeight: "36px",
          textAlign: "start",
        }}
      >
        Unencumbered Term Deposit
      </Typography>
      <Paper
        sx={{
          width: "100%",
          p: 2,
          borderRadius: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Search Box
        </Typography>

        <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              From Date :
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              sx={textFieldStyle}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              To Date :
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              sx={textFieldStyle}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            textAlign: { xs: "center", sm: "right" },
            marginTop: "20px",
            display: "flex",
            justifyContent: "flex-end",
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="contained"
            onClick={handleSearchClick}
            sx={{
              backgroundColor: "#FFD700",
              color: "#FFFFFF",
              borderRadius: "10px",
              marginRight: { xs: "0", md: "10px" },
              width: { xs: "100%", md: "150px" },
              marginBottom: { xs: "10px", sm: "0" },
            }}
          >
            Search
          </Button>
        </Grid>
      </Paper>

      {/* Second Paper */}
      {showform && (
        <Paper sx={{ marginTop: "2%", borderRadius: "16px" }}>
          <Box
            sx={{
              width: "100%",
              maxWidth: "95%", // Maximum width for large screens
              padding: "20px",
              backgroundColor: "#fff",
              borderRadius: "15px",
              borderRadius: "100px",
              margin: "auto",
            }}
          >
            <Grid container spacing={2}>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    FD Deposit*
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Bank Name*
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    MIS Deposit*
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Bank Address*
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    RD/DRD Deposit*
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    FD NO.*
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Savings Deposit*
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    FD Amt*
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Savings Withdrawal*
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Maturity Amt*
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Flexi Deposit*
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    FD Date*
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Flexi Withdrawal*
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Maturity Date*
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Maturity Principal*
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Payment By
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Total Deposit*
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Remarks*
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Total Withdrawal*
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Total Balance*
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Previous FD Deposited*
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Unencumbered (10% )*
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      ...fontStyle,
                    }}
                  >
                    Unencumbered Amount (10%)*
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              {/* button */}
              <Grid container spacing={2} mt={1} justifyContent="flex-end">
                <Grid item>
                  <Button
                    sx={{
                      backgroundColor: "#3029D9",
                      color: "#FFFFFF",
                      borderRadius: "10px",
                      marginRight: { xs: "0", md: "10px" },
                      width: { xs: "100%", md: "150px" },
                      marginBottom: { xs: "10px", sm: "0" },
                    }}
                  >
                    Add
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    sx={{
                      backgroundColor: "#E8C30B",
                      color: "#FFFFFF",
                      borderRadius: "10px",
                      marginRight: { xs: "0", md: "10px" },
                      width: { xs: "100%", md: "150px" },
                      marginBottom: { xs: "10px", sm: "0" },
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default MandateDepositBank;
