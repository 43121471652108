import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Divider,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import GroupsIcon from "@mui/icons-material/Groups";
import DescriptionIcon from "@mui/icons-material/Description";

const SummaryCard = ({ title, number, background }) => {
  const isGroupsIcon = ["Member", "Daily Policy", "Loan"].includes(title);

  return (
    <Card
      sx={{
        width: "100%",
        // margin: 2,
        borderRadius: "20px",
        background: `linear-gradient(90deg, ${background[0]}, ${background[1]})`,
      }}
    >
      <CardContent>
        <Box display="flex" flexDirection="column" mb={1}>
          {isGroupsIcon ? (
            <GroupsIcon sx={{ color: "white", fontSize: "30px" }} />
          ) : (
            <DescriptionIcon sx={{ color: "white", fontSize: "30px" }} />
          )}
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography
            sx={{
              color: "white",
              fontSize: "20px",
              fontFamily: "Poppins",
              fontWeight: 500,
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontSize: "20px",
              fontFamily: "Poppins",
              fontWeight: 500,
            }}
          >
            {number}
          </Typography>
        </Box>
        <Divider sx={{ background: "white", mt: 2 }} />
        <Box display="flex" justifyContent="space-between" marginTop={2}>
          <Button
            sx={{
              color: "white",
              fontSize: "14px",
              fontFamily: "Poppins",
              fontWeight: 500,
              textTransform: "none",
            }}
            variant="text"
          >
            <RemoveRedEyeIcon sx={{ mr: 1 }} />
            View
          </Button>
          <Button
            sx={{
              color: "white",
              fontSize: "14px",
              fontFamily: "Poppins",
              fontWeight: 500,
              textTransform: "none",
            }}
            variant="text"
          >
            + Add
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SummaryCard;
