import React, { useEffect, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  MenuItem,
  TextField,
  Button,
  Box,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

function ShareTransfer2() {
  const token = localStorage.getItem("token");
  const [members, setMembers] = useState([]);
  const [promoters, setPromoters] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");
  const [shareBalance, setShareBalance] = useState("");
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState("");

  const formik = useFormik({
    initialValues: {
      searchCode: "",
      memberName: "",
      branch: "",
      previousBalance: "",
      previousShares: "",
    },
    validationSchema: Yup.object({
      searchCode: Yup.string().required("Required"),
      memberName: Yup.string().required("Required"),
      branch: Yup.string().required("Required"),
      previousBalance: Yup.number().required("Required"),
      previousShares: Yup.number().required("Required"),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  useEffect(() => {
    // Fetch all necessary data
    const fetchData = async () => {
      try {
        // Fetch approved members
        const membersResponse = await axios.get(
          "https://api.vlnidhi.com/api/admin/approvedmembers",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setMembers(membersResponse.data);

        // Fetch promoters
        const promotersResponse = await axios.get(
          "https://api.vlnidhi.com/api/admin/promotors",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPromoters(promotersResponse.data);

        // Fetch payment options
        const paymentOptionsResponse = await axios.get(
          "https://api.vlnidhi.com/api/admin/payment-by",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPaymentOptions(paymentOptionsResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [token]);

  const handlePaymentChange = (event) => {
    setSelectedPayment(event.target.value);
  };

  // Handle member selection
  const handleMemberSelect = (event) => {
    const selectedCode = event.target.value;
    const member = members.find(
      (m) => `${m.memberName} ${m.memberCode}` === selectedCode
    );
    setSelectedMember(member);
    if (member) {
      formik.setFieldValue("memberName", member.memberName);
      formik.setFieldValue(
        "branch",
        `${member.branch.branchName} (${member.branch.branchCode})`
      );
      formik.setFieldValue("previousBalance", member.shareAmount);
      formik.setFieldValue("previousShares", member.noOfShare);
    }
    formik.setFieldValue("searchCode", selectedCode); // Update search code
  };

  // Handle selection of a member
  const handleMemberChange = (event) => {
    const memberId = event.target.value;
    setSelectedMember(memberId);

    // Find selected member data
    const selectedMemberData = promoters.find(
      (member) => member.id === parseInt(memberId)
    );
    if (selectedMemberData) {
      setShareBalance(selectedMemberData.shareAmount);
    } else {
      setShareBalance(0);
    }
  };

  const textFieldStyle = {
    width: { xs: "100%", md: "100%" },
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    width: "100%",
    fontSize: { xs: "12px", md: "14px" },
  };

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Share Transfer
      </Typography>
      <Paper
        sx={{
          width: "100%",
          p: 2,
          borderRadius: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          px: 3,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Share Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Search by Code *
            </Typography>
            <TextField
              select
              name="searchCode"
              value={formik.values.searchCode}
              onChange={handleMemberSelect}
              error={
                formik.touched.searchCode && Boolean(formik.errors.searchCode)
              }
              helperText={formik.touched.searchCode && formik.errors.searchCode}
              sx={{ ...textFieldStyle }}
              InputProps={{ style: { borderRadius: "10px" } }}
            >
              {members.map((member) => (
                <MenuItem
                  key={member.id}
                  value={`${member.memberName} ${member.memberCode}`}
                >
                  {`${member.memberName} - ${member.memberCode}`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Branch *
            </Typography>
            <TextField
              name="branch"
              value={formik.values.branch}
              onChange={formik.handleChange}
              disabled
              sx={{ ...textFieldStyle }}
              InputProps={{
                style: { borderRadius: "10px", background: "#eee" },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Member Name *
            </Typography>
            <TextField
              name="memberName"
              value={formik.values.memberName}
              onChange={formik.handleChange}
              disabled
              sx={{ ...textFieldStyle }}
              InputProps={{
                style: { borderRadius: "10px", background: "#eee" },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Transfer Date *
            </Typography>
            <TextField
              type="date"
              value={new Date().toISOString().split("T")[0]} // Set current date
              sx={{
                ...textFieldStyle,
              }}
              disabled
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Date Of Join
            </Typography>
            <TextField
              type="date"
              name="registrationDate"
              value={selectedMember ? selectedMember.registrationDate : ""}
              disabled
              sx={{ ...textFieldStyle }}
              InputProps={{
                style: { borderRadius: "10px", background: "#eee" },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Share Alloted From *
            </Typography>
            <TextField
              select
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
              onChange={handleMemberChange}
              value={selectedMember}
              //   displayEmpty
            >
              {promoters.map((member) => (
                <MenuItem key={member.id} value={member.id}>
                  {`${member.memberName} - ${member.memberCode}`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Previous Balance
            </Typography>
            <TextField
              name="previousBalance"
              value={formik.values.previousBalance}
              onChange={formik.handleChange}
              disabled
              sx={{ ...textFieldStyle }}
              InputProps={{
                style: { borderRadius: "10px", background: "#eee" },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Share Balance
            </Typography>
            <TextField
              value={shareBalance}
              sx={{
                ...textFieldStyle,
              }}
              disabled
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Previous No Of Share
            </Typography>
            <TextField
              name="previousShares"
              value={formik.values.previousShares}
              onChange={formik.handleChange}
              disabled
              sx={{ ...textFieldStyle }}
              InputProps={{
                style: { borderRadius: "10px", background: "#eee" },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Transfer Amount *
            </Typography>
            <TextField
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Face Value
            </Typography>
            <TextField
              value="10"
              sx={{
                ...textFieldStyle,
              }}
              disabled
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              No. of Share *
            </Typography>
            <TextField
              sx={{
                ...textFieldStyle,
              }}
              disabled
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                },
              }}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        sx={{
          width: "100%",
          p: 2,
          borderRadius: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          px: 3,
          mt: 2,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Payment Details
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Payment By <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              value={selectedPayment}
              onChange={handlePaymentChange}
              sx={{ ...textFieldStyle }}
              InputProps={{ style: { borderRadius: "10px" } }}
            >
              {paymentOptions.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Remarks
            </Typography>
            <TextField
              fullWidth
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              textAlign: { xs: "center", sm: "right" },
              marginTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
              flexWrap: "wrap",
            }}
          >
            <Button
              variant="contained"
              type="submit"
              sx={{
                backgroundColor: "#E8C30B",
                borderRadius: "10px",
                color: "#fff",
                width: { xs: "100%", md: "118px" },
                textTransform: "none",
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#05ACD6",
                color: "white",
                borderRadius: "10px",
                marginRight: { xs: "0", md: "10px" },
                width: { xs: "100%", md: "118px" },
                marginBottom: { xs: "10px", sm: "0" },
                ml: 2,
                textTransform: "none",
              }}
            >
              New
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default ShareTransfer2;
