

import React from 'react'
import {
  Box, Grid, Paper, Typography, Button, TextField, 
  Card, FormControl, MenuItem, InputLabel, Select, 
  TableContainer, Table, TableHead, TableRow, TableCell, 
  TableBody, IconButton,Container
} from "@mui/material";

const textFieldStyle = {
  // width: { xs: "290px", lg: "578px" },
  height: "48px",
  borderRadius: "80%",
  borderColor: "#D9D9D9CC",
  backgroundColor: "#FFFFFF",
  "& .MuiInputBase-root": {
    height: "48px",
  },
};

const fontStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "14px",
  opacity: 0.6,
};

const InsentivePayment = () => {
  return (
    <Box>
       <Typography
           sx={{
          fontSize:{sx:'20px',md:'24px'},
          fontWeight: 600,
         lineHeight: "36px",
         textAlign: "start",
          }}
       >
        Incentive Payment
       </Typography> 
       <Paper 
      sx={{
          width: "100%",
          p: 2,
          borderRadius: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
       >
       <Typography
        sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
       >
       Search Box
      </Typography>
     
     <Grid container spacing={2} alignItems="center" sx={{mb:2,}}>
     <Grid item xs={12} sm={6} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
             Month Name :
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              sx={textFieldStyle}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
        </Grid>
       <Grid item xs={12} sm={6} md={6}>
         <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
             Code :
            </Typography>
            <TextField
           select
              fullWidth
              variant="outlined"
              sx={textFieldStyle}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            >
                <MenuItem>
                <MenuItem value={10}>Policy 1</MenuItem>
                <MenuItem value={20}>Policy 2</MenuItem>
                <MenuItem value={30}>Policy 3</MenuItem>
                </MenuItem>
            </TextField>
         </Grid>       
   </Grid>
   <Grid
          item
          xs={12}
          sx={{textAlign: { xs: "center", sm: "right" },  
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'flex-end',  
                flexWrap: 'wrap',}}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#FFD700',
               color: '#FFFFFF',
              borderRadius: '10px',
              marginRight: { xs: '0', md: '10px' },
              width: { xs: '100%', md: '150px' },
              marginBottom: { xs: '10px', sm: '0' },
             
            }}
          >
            Search
          </Button>
        </Grid>
    </Paper>

      {/* Second Paper */}
      <Paper sx={{ marginTop: "2%",borderRadius: "16px" }}>
      <Box
      sx={{
        width: '100%',
        maxWidth: '95%', // Maximum width for large screens
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '15px',
        borderRadius: '100px',        
        margin: 'auto',
      }}
    >
      <h2>Incentive Details</h2>
      <Grid container spacing={2}>
        <Grid container spacing={2} mt={1}>
         <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
         Name *
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
            Total Incentive *
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
         </Grid>
         <Grid container spacing={2} mt={1}>
         <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
          Position *
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
             TDS (-) *
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
            Self Business *
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
          Service Charge (-) *
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
         </Grid>   
         <Grid container spacing={2} mt={1}>
         <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
         Team Business*
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
         Allowance (If Any)
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>

         </Grid>   
         <Grid container spacing={2} mt={1}>
         <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
         Total Business *
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
        Net Payable *
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>

         </Grid>   

       </Grid>
       </Box>
      </Paper>
      {/* Third paper */}
      <Paper sx={{ marginTop: "2%",borderRadius: "16px" }}>
      <Box
      sx={{
        width: '100%',
        maxWidth: '95%', // Maximum width for large screens
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '15px',
        borderRadius: '100px',        
        margin: 'auto',
      }}
    >
      <h2>Payment Details</h2>
      <Grid container spacing={2}>
        <Grid container spacing={2} mt={1}>
         <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
         Pay Branch
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
            Pay Date *
            </Typography>
            <TextField fullWidth 
            type='date'
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
         </Grid>
         <Grid container spacing={2} mt={1}>
         <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
          Payment By *
            </Typography>
            <TextField
           select
              fullWidth
              variant="outlined"
              sx={textFieldStyle}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            >
                <MenuItem>
                <MenuItem >Policy 1</MenuItem>
                <MenuItem >Policy 2</MenuItem>
                <MenuItem >Policy 3</MenuItem>
                </MenuItem>
            </TextField>
          </Grid>
         </Grid>      
        <Grid
          item
          xs={12}
          sx={{textAlign: { xs: "center", sm: "right" },  
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'flex-end',  
                flexWrap: 'wrap',}}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#FFD700',
               color: '#FFFFFF',
              borderRadius: '10px',
              marginRight: { xs: '0', md: '10px' },
              width: { xs: '100%', md: '150px' },
              marginBottom: { xs: '10px', sm: '0' },
             
            }}
          >
           Pay
          </Button>
        </Grid>
       </Grid>
       </Box>
      </Paper>
   </Box>
  )
}

export default InsentivePayment


