import React, { useState } from "react";
import DailyDeposit from "./DailyDeposit";
import RecurringDeposit from "./RecurringDeposit";
import FixedDeposit from "./FixedDeposit";
import MISDeposit from "./MISDeposit";
import { Tabs, Tab, Box, Paper, Typography } from "@mui/material";
import DailyDeposit2 from "./DailyDeposit2";

const PlanMaster = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Plan Master
      </Typography>
      <Paper sx={{borderRadius: "20px"}}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="fullWidth"
          indicatorColor="primary"
            textColor="primary"
          // TabIndicatorProps={{
          //   style: { backgroundColor: '#3029D9' },
          // }}
          sx={{
            '& .MuiTab-root': {
              textTransform: 'none',
              fontFamily: 'Poppins',
              fontWeight: 'bold',
              color: '#000',
            },
            '& .Mui-selected': {
              color: '#3029D9',
            },
          }}
        >
          <Tab label="Daily Deposit" />
          <Tab label="Recurring Deposit" />
          <Tab label="Fixed Deposit" />
          <Tab label="MIS Deposit" />
          {/* <Tab label="Daily Deposit 2" /> */}
        </Tabs>
        <Box sx={{ p: 3 }}>
          {activeTab === 0 && <DailyDeposit />}
          {activeTab === 1 && <RecurringDeposit />}
          {activeTab === 2 && <FixedDeposit />}
          {activeTab === 3 && <MISDeposit />}
          {/* {activeTab === 4 && <DailyDeposit2 />} */}
        </Box>
      </Paper>
    </Box>
  );
};

export default PlanMaster;
