import { Box, TableRow, Table, TableBody, TableCell, TableContainer, Container, Paper, Button, Typography, Grid, TextField, MenuItem, TableHead, Dialog, DialogTitle, DialogContent, Checkbox, IconButton } from '@mui/material'
import { React, useState } from 'react'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CloseIcon from '@mui/icons-material/Close';

const AppUserCreation = () => {
  const [showform, setShowform] = useState(false);
  const [openDialog, setOpenDialog] = useState(false); // state for dialog open/close

  const textFieldStyle = {
    width: { xs: "100%", md: "100%" },
    height: "48px",
    borderRadius: "10px",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: "48px",
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
    opacity: 0.6,
  };

  const handleSearchClick = () => {
    setShowform(true);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <Box>
      <Typography
        sx={{
          fontSize: { xs: "20px", md: "24px" },
          fontWeight: 600,
          lineHeight: "36px",
          fontFamily: "Poppins",
          mb: 1,
        }}
      >
        App User Create
      </Typography>
      <Paper sx={{ padding: "2%", marginTop: "1%", borderRadius: "20px" }}>
        {/* Search Box */}
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
          }}
        >
          Search Box
        </Typography>

        <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              User Type
            </Typography>
            <TextField select sx={textFieldStyle}>
              <MenuItem value='policy1'>Policy 1</MenuItem>
              <MenuItem value='policy1'>Policy 2</MenuItem>
              <MenuItem value='policy1'>Policy 3</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              Search By Name
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              sx={textFieldStyle}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              textAlign: { xs: "center", sm: "right" },
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'flex-end',
              flexWrap: 'wrap',
            }}
          >
            <Button
              variant="contained"
              onClick={handleSearchClick}
              sx={{
                backgroundColor: '#FFD700',
                color: '#FFFFFF',
                borderRadius: '10px',
                marginRight: { xs: '0', md: '10px' },
                width: { xs: '100%', md: '150px' },
                marginBottom: { xs: '10px', sm: '0' },
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {/* Table with Eye Icon */}
      {showform && (
        <Paper sx={{ marginTop: '2%', borderRadius: '16px' }}>
          <Paper sx={{ mt: 2, p: 2, borderRadius: "20px" }}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "36px",
                mb: 2,
                mt: 0,
              }}
            >
              Mobile User List
            </Typography>
            <Box sx={{ overflowX: "auto" }}>
              <TableContainer component={Paper} sx={{ borderRadius: "20px 20px 0px 0px" }}>
                <Table aria-label="simple table" size="small">
                  <TableHead sx={{ background: "#413AF1" }}>
                    <TableRow>
                      <TableCell sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}>User ID</TableCell>
                      <TableCell sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}>Name</TableCell>
                      <TableCell sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}>Mobile Access Status</TableCell>
                      <TableCell sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}>Block Status</TableCell>
                      <TableCell sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}>Access ON</TableCell>
                      <TableCell sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}>Access OFF</TableCell>
                      <TableCell sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}>Block</TableCell>
                      <TableCell sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}>Menu</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>51651</TableCell>
                      <TableCell>Pratik</TableCell>
                      <TableCell >ON</TableCell>
                      <TableCell >UNBLOCK</TableCell>
                      <TableCell sx={{color:'#01A165'}}>ON</TableCell>
                      <TableCell sx={{color:'#FF0000'}}>OFF</TableCell>
                      <TableCell>Block/Unblock</TableCell>
                      <TableCell>
                        {/* Eye icon to trigger dialog */}
                        <IconButton onClick={handleDialogOpen}>
                          <RemoveRedEyeIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Paper>
        </Paper>
      )}

      {/* Dialog (Popup) */}
      <Dialog 
      open={openDialog} 
      onClose={handleDialogClose} 
      maxWidth="md" 
      fullWidth 
      sx={{ ml: { xs: 2, md: 6 }, p: { xs: 1, md: 2 } }} // Margin and padding adjustments for mobile & desktop
    >
      {/* Dialog Title */}
      <Typography
        sx={{
          fontSize: { xs: '18px', md: '24px' }, // Adjust font size for mobile & desktop
          fontWeight: 600,
          lineHeight: { xs: '28px', md: '36px' },
          fontFamily: 'Poppins',
          mb: 1,
          mt: 1,
          ml: 1,
        }}
      >
        Menu Details
      </Typography>

      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Menu List
        <IconButton onClick={handleDialogClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {/* Dialog Content */}
      <DialogContent>
        {/* Menu List Table */}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ fontWeight: 'bold', backgroundColor: '#3b3cfd', color: 'white', fontSize: { xs: '12px', md: '14px' } }}
                >
                  MID
                </TableCell>
                <TableCell
                  sx={{ fontWeight: 'bold', backgroundColor: '#3b3cfd', color: 'white', fontSize: { xs: '12px', md: '14px' } }}
                >
                  Menu Name
                </TableCell>
                <TableCell
                  sx={{ fontWeight: 'bold', backgroundColor: '#3b3cfd', color: 'white', fontSize: { xs: '12px', md: '14px' } }}
                >
                  Select
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontSize: { xs: '12px', md: '14px' } }}>01</TableCell>
                <TableCell sx={{ fontSize: { xs: '12px', md: '14px' } }}>New Member</TableCell>
                <TableCell><Checkbox /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontSize: { xs: '12px', md: '14px' } }}>02</TableCell>
                <TableCell sx={{ fontSize: { xs: '12px', md: '14px' } }}>New Member</TableCell>
                <TableCell><Checkbox /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontSize: { xs: '12px', md: '14px' } }}>03</TableCell>
                <TableCell sx={{ fontSize: { xs: '12px', md: '14px' } }}>New Member</TableCell>
                <TableCell><Checkbox /></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* User Name and Password Fields */}
        <Typography sx={{ mt: 2, fontWeight: 'bold', fontSize: { xs: '18px', md: '24px' } }}>User Name And Password</Typography>

        {/* User ID Field */}
        <Typography sx={{ mt: 2, fontWeight: 'medium', fontSize: { xs: '14px', md: '16px' } }}>User ID :</Typography>
        <TextField fullWidth sx={{ mt: 1 }} />

        {/* Password Field */}
        <Typography sx={{ mt: 2, fontWeight: 'medium', fontSize: { xs: '14px', md: '16px' } }}>Password :</Typography>
        <TextField fullWidth sx={{ mt: 1 }} type="password" />

        {/* Cash Limit Field */}
        <Typography sx={{ mt: 2, fontWeight: 'medium', fontSize: { xs: '14px', md: '16px' } }}>Cash Limit :</Typography>
        <TextField fullWidth sx={{ mt: 1 }} />

        {/* Checkbox Option */}
        <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
          <Checkbox />
          <Typography sx={{ fontSize: { xs: '12px', md: '14px' } }}>Send Password to Mobile Number</Typography>
        </div>

        {/* Save Access Button */}
        <Grid
          item
          xs={12}
          sx={{
            textAlign: { xs: 'center', sm: 'right' },
            mt: 3,
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-end' },
            flexWrap: 'wrap',
          }}
        >
          <Button
            variant="contained"
            sx={{
              mt: 2,
              backgroundColor: '#f5c500',
              color: '#fff',
              '&:hover': { backgroundColor: '#d1a700' },
              borderRadius: '8px',
              width: { xs: '100%', sm: 'auto' }, // Full width button on small screens
            }}
          >
            Save Access
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
    </Box>
  );
}

export default AppUserCreation;
