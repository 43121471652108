import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";

const styles = {
  paper: {
    width: "100%",
    p: 2,
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    px: 3,
  },
  header: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  sectionHeader: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  label: {
    fontFamily: "Poppins, sans-serif",
    fontSize: { xs: "12px", md: "14px" },
  },
  textField: {
    width: "100%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  },
  button: {
    borderRadius: "10px",
    width: { xs: "100%", md: "247px" },
  },
  newButton: {
    backgroundColor: "#FFD700",
    color: "#000",
  },
  saveButton: {
    backgroundColor: "#3F51B5",
    color: "#fff",
  },
};

const SearchEmployee = () => {
  const [employees, setEmployees] = useState([]);

  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [designationName, setDesignationName] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  // Function to fetch all advisors (for "Get All" button)
  const fetchEmployees = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        "https://api.vlnidhi.com/api/admin/employees/all",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEmployees(response.data); // Save fetched data to state
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  // Fetch data initially (you can adjust if needed)
  useEffect(() => {
    fetchEmployees(); // Fetch all advisors on component load
  }, []);

  useEffect(() => {
    // Fetch branches from the API
    const fetchBranches = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://api.vlnidhi.com/api/admin/getallbid",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setBranches(response.data);
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    };

    fetchBranches();
  }, []);

  const handleSearch = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        "https://api.vlnidhi.com/api/admin/employees/all",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const employeesData = response.data;
      setEmployees(employeesData);

      // Apply filters
      const filtered = employeesData.filter((employee) => {
        const matchBranch = selectedBranch
          ? employee.branch.branchCode === selectedBranch
          : true;

        const matchDate =
          fromDate && toDate
            ? new Date(employee.joiningDate) >= new Date(fromDate) &&
              new Date(employee.joiningDate) <= new Date(toDate)
            : true;

        const matchEmployeeName = employeeName
          ? employee.employeeName
              .toLowerCase()
              .includes(employeeName.toLowerCase())
          : true;

        const matchEmployeeCode = employeeCode
          ? employee.employeeCode === employeeCode
          : true;

        const matchDesignationName = designationName
          ? employee.designation.designationName === designationName
          : true;

        const matchDepartmentName = departmentName
          ? employee.department.departmentName === departmentName
          : true;

        const matchMobileNo = mobileNo ? employee.mobileNo === mobileNo : true;

        return (
          matchBranch &&
          matchDate &&
          matchEmployeeName &&
          matchEmployeeCode &&
          matchMobileNo &&
          matchDesignationName &&
          matchDepartmentName
        );
      });

      setFilteredEmployees(filtered);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  return (
    <Box>
      <Typography sx={styles.header}>Search Employee</Typography>
      <Paper sx={styles.paper}>
        <Typography sx={styles.sectionHeader}>Search Box</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>Branch :</Typography>
              <TextField
                select
                value={selectedBranch}
                onChange={(e) => setSelectedBranch(e.target.value)}
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.branchCode}>
                    {`${branch.branchName} (${branch.branchCode})`}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>From Date :</Typography>
            <TextField
              type="date"
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>To Date :</Typography>
            <TextField
              type="date"
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>Employee Name :</Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
              value={employeeName}
              onChange={(e) => setEmployeeName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>Employee Code :</Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
              value={employeeCode}
              onChange={(e) => setEmployeeCode(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>Mobile No. :</Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
              value={mobileNo}
              onChange={(e) => setMobileNo(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>Designation :</Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
              value={designationName}
              onChange={(e) => setDesignationName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>Department :</Typography>
            <TextField
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
              value={departmentName}
              onChange={(e) => setDepartmentName(e.target.value)}
            />
          </Grid>
        </Grid>
        {/* Buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
            gap: "16px",
            marginTop: "20px",
          }}
        >
          <Button
            onClick={handleSearch}
            variant="contained"
            sx={{
              textTransform: "none",
              fontFamily: "Poppins",
              background: "#DFBF41",
              width: { xs: "50%", md: "157px" },
              height: "48px",
              borderRadius: "10px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#B89B3E" },
              color: "white",
            }}
          >
            Search
          </Button>

          {/* Print Button */}

          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              fontFamily: "Poppins",
              background: "#F37D01",
              width: { xs: "50%", md: "157px" },
              height: "48px",
              borderRadius: "10px",
              fontWeight: "bold",
              color: "white",
              ":hover": { background: "#9c5f1e" },
            }}
          >
            Print
          </Button>
        </Box>
      </Paper>

      {/* Table */}
      <Paper sx={{ ...styles.paper, mt: 2 }}>
        <Typography sx={styles.sectionHeader}>Employee Data List</Typography>
        <Box sx={{ overflowX: "auto" }}>
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: "20px",
              width: { md: "100%", xs: "296px" },
              // maxWidth: "1210px",
              overflowX: "auto",
            }}
          >
            <Table size="small">
              <TableHead sx={{ backgroundColor: "#413AF1" }}>
                <TableRow>
                  {[
                    "Sl No",
                    "BRANCH",
                    "E.CODE",
                    "E.NAME",
                    "DESIGNATION",
                    "D.O.J",
                    "DEPARTMENT",
                    "Phone No.",
                    "PAN",
                    "BANK AC",
                    "IFSC CODE",
                    // "STATUS",
                    // "Action",
                  ].map((header, index) => (
                    <TableCell
                      key={index}
                      sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredEmployees.length > 0 ? (
                  filteredEmployees.map((employee, index) => (
                    <TableRow
                      key={employee.slNo}
                      sx={{
                        height: 48,
                        backgroundColor:
                          index % 2 === 0 ? "#F2F6FA" : "#C1E0FB", // Alternate row color
                      }}
                    >
                      <TableCell>{employee.id}</TableCell>
                      <TableCell>{employee.branch.branchName}</TableCell>
                      <TableCell>{employee.employeeCode}</TableCell>
                      <TableCell>{employee.employeeName}</TableCell>
                      <TableCell>
                        {employee.designation.designationName}
                      </TableCell>
                      <TableCell>
                        {new Date(employee.joiningDate).toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          }
                        )}
                      </TableCell>
                      <TableCell>
                        {employee.department.departmentName}
                      </TableCell>
                      <TableCell>{employee.mobileNo}</TableCell>
                      <TableCell>{employee.pan}</TableCell>
                      <TableCell>{employee.bankAccount}</TableCell>
                      <TableCell>{employee.ifscCode}</TableCell>
                      {/* <TableCell>{employee.empStatus}</TableCell> */}
                      {/* <TableCell>{employee.id}</TableCell> */}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={12}
                      align="center"
                      sx={{ color: "red" }}
                    >
                      No results found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
};

export default SearchEmployee;
