import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  TextField,
  Button,
  Box,
  MenuItem,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import ReactToPrint from "react-to-print";
import axios from "axios";

const textFieldStyle = {
  width: { xs: "100%", md: "100%" },
  height: "48px",
  borderRadius: "10px",
  borderColor: "#D9D9D9CC",
  backgroundColor: "#FFFFFF",
  "& .MuiInputBase-root": {
    height: "48px",
  },
};

const fontStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "14px",
  opacity: 0.6,
};

const CheckClearing = () => {


const [showform,setShowform]=useState(false);

const handleSearchClick = () =>{
  setShowform(true);
}

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Cheque Clear
      </Typography>
      <Paper
        sx={{
          padding: 4,
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          "@media (max-width: 600px)": {
            padding: "10px",
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Search Box
        </Typography>
        <Grid container spacing={1}>
          
          {/* Branch Dropdown */}
          <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  marginBottom: "8px",
                  ...fontStyle,
                }}
              >
               Type :
              </Typography>
              <TextField
                select
                name="branch"
                sx={textFieldStyle}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
               
              </TextField>
            </Box>
          </Grid>

          {/* From Date */}
          <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  marginBottom: "8px",
                  ...fontStyle,
                }}
              >
                Branch
              </Typography>
              <TextField
                select
                name="branch"
                sx={textFieldStyle}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
               
              </TextField>
            </Box>
          </Grid>

          {/* To Date */}
          <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  marginBottom: "8px",
                  ...fontStyle,
                }}
              >
                From Date :
              </Typography>
              <TextField
                type="date"
                name="toDate"
                sx={{
                  ...textFieldStyle,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Box>
          </Grid>

          {/* Applicant Name */}
          <Grid item xs={12} md={4}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              To Date :
            </Typography>
            <TextField
              name="applicantName"
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>

          {/* Account No */}
          <Grid item xs={12} md={4}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              Cheque No. :
            </Typography>
            <TextField
              name="accountNo"
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
        </Grid>

        {/* Buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
            gap: "16px",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            onClick={handleSearchClick}
            sx={{
              textTransform: "none",
              fontFamily: "Poppins",
              background: "#DFBF41",
              width: { xs: "50%", md: "157px" },
              height: "48px",
              borderRadius: "10px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#B89B3E" },
              color: "white",
            }}
          >
            Search
          </Button>       
          {/* Print Button */}
        </Box>
      </Paper>
      {showform && (
        <TableContainer component={Paper} sx={{borderRadius:'10px',mt:3}}>
        <Box sx={{ mt: 3, p: 2 }}>
        <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>Detail Search List</Typography>
        </Box>
          <Table sx={{}}>
            <TableHead>
              <TableRow sx={{background:'#413AF1'}}>
                <TableCell >SlNo</TableCell>
                <TableCell>SectionName</TableCell>
                <TableCell>Branch</TableCell>
                <TableCell>AC.No</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>TDate</TableCell>
                <TableCell>InstNo</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>ChkNo</TableCell>
                <TableCell>ChkDate</TableCell>


              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1234567890</TableCell>
                <TableCell>2022-05-15</TableCell>
                <TableCell>1000</TableCell>
                <TableCell>Branch A</TableCell>
                <TableCell>John Doe</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )};
      <Paper>
      </Paper> 
    </Box>
  );
};

export default CheckClearing;