import React, { useEffect, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  MenuItem,
  TextField,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";

function SBInterestTransfer() {
  const textFieldStyle = {
    width: { xs: "100%", md: "100%" },
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    width: "100%",
    fontSize: { xs: "12px", md: "14px" },
  };

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        SB Account Interest
      </Typography>
      <Paper
        sx={{
          width: "100%",
          p: 2,
          borderRadius: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          px: 3,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Interest Master
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Gen Name *
            </Typography>
            <TextField
              sx={{ ...textFieldStyle }}
              InputProps={{ style: { borderRadius: "10px" } }}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              From Date *
            </Typography>
            <TextField
              type="date"
              sx={{ ...textFieldStyle }}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              To Date *
            </Typography>
            <TextField
              type="date"
              sx={{ ...textFieldStyle }}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Remarks
            </Typography>
            <TextField
              sx={{ ...textFieldStyle }}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>
        </Grid>
        <Box sx={{display: "flex", justifyContent: "flex-end"}}>
          <Button
            variant="contained"
            sx={{
              background: "#E8C30B",
              textTransform: "none",
              borderRadius: "10px",
              color: "white",
              mt: 2,
              width: { md: "149px", xs: "100%" },
              height: { md: "48px", xs: "38px" },
              fontSize: "16px",
              ":hover": {background: "#bca220"}
            }}
          >
            Genrate
          </Button>
        </Box>
      </Paper>
      <Paper
        sx={{
          width: "100%",
          p: 2,
          borderRadius: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          px: 3,
          mt: 2,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Interest Details
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Search By GenCode *
            </Typography>
            <TextField
              select
              sx={{ ...textFieldStyle }}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Button
                variant="contained"
                sx={{
                  background: "#F37D01",
                  textTransform: "none",
                  borderRadius: "10px",
                  color: "white",
                  mt: { md: 2.3, xs: -1 },
                  width: { md: "149px", xs: "100%" },
                  height: { md: "48px", xs: "38px" },
                  fontSize: "16px",
                  ":hover": {background: "#c0701c"}
                }}
              >
                Search Data
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Paper
        sx={{
          width: "100%",
          p: 2,
          borderRadius: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          px: 3,
          mt: 2,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Interest Data List
        </Typography>

        <TableContainer
          component={Paper}
          sx={{ borderRadius: "20px 20px 0px 0px" }}
        >
          <Table size="small">
            <TableHead sx={{ background: "#413AF1", color: "white" }}>
              <TableRow>
                <TableCell sx={{ color: "#fff" }}>
                  <Checkbox sx={{ color: "white" }} />
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>SLNo.</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Branch</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Account No</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Applicant Name</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Member Code</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Calculated Interest</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>FROMDATE</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>TODATE</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>PayStatus</strong>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

export default SBInterestTransfer;
