import React from 'react';
import { Box, Typography, Paper, Grid, TextField, Button } from '@mui/material';

const PLStatement = () => {
  const textFieldStyle = {
    width: { xs: '100%', md: '100%' },
    height: '48px',
    borderRadius: '10px',
    borderColor: '#D9D9D9CC',
    backgroundColor: '#FFFFFF',
    '& .MuiInputBase-root': {
      height: '48px',
    },
  };

  const fontStyle = {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '14px',
    opacity: 0.6,
  };

  return (
    <Box>
      <Typography
        sx={{
          fontSize: { xs: '20px', md: '24px' },
          fontWeight: 600,
          lineHeight: '36px',
          fontFamily: 'Poppins',
          mb: 1,
        }}
      >
        PL Statement
      </Typography>

      <Paper
        sx={{
          width: '100%',
          p: 2,
          borderRadius: '20px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '20px',
            lineHeight: '36px',
            fontWeight: 500,
            mb: 1,
          }}
        >
          Search information
        </Typography>

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ width: '100%', fontSize: { xs: '16px', md: '18px' }, marginBottom: '8px', ...fontStyle }}>
              Select AC No
            </Typography>
            <TextField
              select
              fullWidth
              variant="outlined"
              sx={textFieldStyle}
              InputProps={{ style: { borderRadius: '10px' } }}
            >
              {/* Options for account number */}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ width: '100%', fontSize: { xs: '16px', md: '18px' }, marginBottom: '8px', ...fontStyle }}>
              Select FYear
            </Typography>
            <TextField
              select
              fullWidth
              variant="outlined"
              sx={textFieldStyle}
              InputProps={{ style: { borderRadius: '10px' } }}
            >
              {/* Options for financial year */}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ width: '100%', fontSize: { xs: '16px', md: '18px' }, marginBottom: '8px', ...fontStyle }}>
              From Date:
            </Typography>
            <TextField fullWidth variant="outlined" type="date" sx={textFieldStyle} InputProps={{ style: { borderRadius: '10px' } }} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ width: '100%', fontSize: { xs: '16px', md: '18px' }, marginBottom: '8px', ...fontStyle }}>
              To Date:
            </Typography>
            <TextField fullWidth variant="outlined" type="date" sx={textFieldStyle} InputProps={{ style: { borderRadius: '10px' } }} />
          </Grid>

          {/* Align the buttons to the right */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '16px',
                marginTop: '20px',
              }}
            >
              <Button
                variant="contained"
                // onClick={handleSearchClick}
                sx={{
                  textTransform: 'none',
                  fontFamily: 'Poppins',
                  background: '#DFBF41',
                  width: { xs: '50%', md: '157px' },
                  height: '48px',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                  '&:hover': { backgroundColor: '#B89B3E' },
                  color: 'white',
                }}
              >
                Search
              </Button>
              {/* Add other buttons here if needed */}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default PLStatement;
