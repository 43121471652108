import React, { useCallback, useEffect, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  MenuItem,
  TextField,
  Button,
  Box,
  Switch,
} from "@mui/material";
import axios from "axios";

const styles = {
  paper: {
    width: "100%",
    p: 2,
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    px: 3,
  },
  header: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  sectionHeader: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "36px",
    mb: 1,
  },
  label: {
    fontFamily: "Poppins, sans-serif",
    fontSize: { xs: "12px", md: "14px" },
  },
  textField: {
    width: "100%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  },
  button: {
    borderRadius: "10px",
    width: { xs: "100%", md: "247px" },
  },
  newButton: {
    backgroundColor: "#FFD700",
    color: "#000",
  },
  saveButton: {
    backgroundColor: "#3F51B5",
    color: "#fff",
  },
};

// Setting up Axios instance
const axiosInstance = axios.create({
  baseURL: "https://api.vlnidhi.com/api",
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

const NewInvestment = () => {
  const [branches, setBranches] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");
  const [nomineeRelation, setNomineeRelation] = useState([]);

  const [schemes, setSchemes] = useState("");
  const [schemeTypes, setSchemeTypes] = useState([]);
  const [selectedSchemeName, setSelectedSchemeName] = useState("");
  const [schemeTerm, setSchemeTerm] = useState("");
  const [schemeMode, setSchemeMode] = useState("");
  const [selectedSchemeType, setSelectedSchemeType] = useState("");
  const [filteredSchemes, setFilteredSchemes] = useState([]);
  const [misModeEnabled, setMisModeEnabled] = useState(false);
  const [misMode, setMisMode] = useState("N/A");

  const [depositAmount, setDepositAmount] = useState("");
  const [maturityAmount, setMaturityAmount] = useState("");
  const [policyAmount, setPolicyAmount] = useState("");

  const [interestRate, setInterestRate] = useState("");
  const [compoundInterval, setCompoundInterval] = useState("");
  const [interestType, setInterestType] = useState("");

  // Fetch branches from API
  const fetchBranches = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/getallbid");
      setBranches(response.data);
    } catch (error) {
      console.error("Error fetching branches:", error.message);
    }
  }, []);

  // Fetch members from API
  const fetchMembers = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/approvedmembers");
      setMembers(response.data);
    } catch (error) {
      console.error("Error fetching members:", error.message);
    }
  }, []);

  // Fetch members from API
  const fetchNomineeRelation = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/nominee-relations");
      setNomineeRelation(response.data);
    } catch (error) {
      console.error("Error fetching nominee relation:", error.message);
    }
  }, []);

  // Fetch Schemes from API
  const fetchSchemes = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/allcombined");
      setSchemes(response.data);

      // Extract unique planCode (Scheme Types) from the schemes data
      const uniqueSchemeTypes = [
        ...new Set(response.data.map((scheme) => scheme.planCode)),
      ];
      setSchemeTypes(uniqueSchemeTypes);
    } catch (error) {
      console.error("Error fetching Schemes:", error.message);
    }
  }, []);

  useEffect(() => {
    fetchBranches();
    fetchMembers();
    fetchNomineeRelation();
    fetchSchemes();
  }, [fetchBranches, fetchMembers, fetchNomineeRelation, fetchSchemes]);

  const handleChange = (event) => {
    setSelectedMember(event.target.value);
  };

  // Handle Scheme Type selection
  const handleSchemeTypeChange = (event) => {
    const selectedType = event.target.value;
    setSelectedSchemeType(selectedType);

    // Filter schemes based on selected planCode
    const filtered = schemes.filter(
      (scheme) => scheme.planCode === selectedType
    );
    setFilteredSchemes(filtered);

    // Reset other fields when scheme type changes
    setMisMode("N/A");

    // Enable or disable MIS Mode based on Scheme Type
    if (selectedType === "MIS") {
      setMisModeEnabled(true);
    } else {
      setMisModeEnabled(false);
    }
  };

  // Handle MIS Mode change
  const handleMisModeChange = (event) => {
    setMisMode(event.target.value);
  };

  // Handle Scheme Name selection
  const handleSchemeNameChange = (event) => {
    const selectedName = event.target.value;
    setSelectedSchemeName(selectedName);

    // Find the selected scheme from the filtered schemes
    const selectedScheme = filteredSchemes.find(
      (scheme) => scheme.planName === selectedName
    );

    // Set the scheme term and mode based on the selected scheme
    if (selectedScheme) {
      setSchemeTerm(selectedScheme.term);
      setSchemeMode(selectedScheme.termMode);
      setInterestRate(selectedScheme.interestRate);
      setCompoundInterval(selectedScheme.compoundInterval);
      setInterestType(selectedScheme.interestType);

      // Recalculate deposit amount when scheme changes
      calculateDepositAmount(
        policyAmount,
        selectedSchemeType,
        selectedScheme.term
      );
      calculateMaturityAmount(
        policyAmount,
        selectedSchemeType,
        selectedScheme.term,
        selectedScheme.interestRate,
        selectedScheme.compoundInterval,
        selectedScheme.interestType
      );
    }
  };

  // -------------------------------------------------------------
  // Calculate Deposit Amount based on Scheme Type
  const calculateDepositAmount = (policyAmount, schemeType, schemeTerm) => {
    let depositAmount = 0;

    switch (schemeType) {
      case "DRD":
        depositAmount = policyAmount * schemeTerm;
        break;
      case "RD":
        depositAmount = policyAmount * schemeTerm;
        break;
      case "FD":
        depositAmount = policyAmount;
        break;
      case "MIS":
        depositAmount = policyAmount;
        break;
      default:
        depositAmount = 0;
        break;
    }

    setDepositAmount(depositAmount);
  };

  // Calculate Deposit Amount based on Scheme Type
  const calculateMaturityAmount = (
    policyAmount,
    schemeType,
    schemeTerm,
    interestRate,
    compoundInterval,
    interestType
  ) => {
    let maturityAmount = 0;
    // Convert the annual interest rate from percentage to decimal
    const r = interestRate / 100;
    // Daily deposit amount
    const D = policyAmount / 365;
    // Number of compounding intervals per year
    // const n = 365;
    // Convert term from days to years
    const t = schemeTerm / 365;

    let n;
    switch (compoundInterval?.toLowerCase()) {
      case "daily":
        n = 365;
        break;
      case "monthly":
        n = 12;
        break;
      case "quarterly":
        n = 4;
        break;
      case "half-yearly":
        n = 2;
        break;
      case "yearly":
        n = 1;
        break;
      default:
        n = 1; // Default to yearly if not provided
      // throw new Error("Invalid compound interval.");
    }

    switch (schemeType) {
      case "DRD":
        // Calculate maturity amount using the formula
        // maturityAmount =
        //   depositAmount +
        //   (D * ((Math.pow(1 + r / n, n * t) - 1) / (r / n))).toFixed(2);
        // maturityAmount = (depositAmount * Math.pow(1 + r / n, n * t)).toFixed(
        //   2
        // );
        // maturityAmount = depositAmount + (policyAmount * (1 + r / n) ** (n * t)).toFixed(2);

        // Assuming DRD uses compound interest formula with daily deposits
        const D = policyAmount / 365; // Daily deposit amount
        maturityAmount = (
          D *
          ((Math.pow(1 + r / n, n * t) - 1) / (r / n))
        ).toFixed(2);

        break;
      case "RD":
        // Formula for RD schemes (compound interest on recurring deposits)
        maturityAmount = (
          policyAmount *
          ((Math.pow(1 + r / n, n * t) - 1) / (r / n))
        ).toFixed(2);
        break;
      case "FD":
        // Formula for FD schemes (simple or compound interest depending on interestType)
        if (interestType.toLowerCase() === "simple") {
          maturityAmount = (depositAmount +(depositAmount * interestRate * schemeTerm) / 100).toFixed(2);
        } else {
          maturityAmount = (policyAmount * Math.pow(1 + r / n, n * t)).toFixed(
            2
          );
        }
        break;
      case "MIS":
        // depositAmount = policyAmount;
        break;
      default:
        maturityAmount = 0;
        break;
    }

    setMaturityAmount(maturityAmount);
  };

  // Handle Policy Amount change
  const handlePolicyAmountChange = (event) => {
    const policyAmount = event.target.value;
    setPolicyAmount(policyAmount);

    // Calculate deposit amount based on the selected scheme type and term
    calculateDepositAmount(policyAmount, selectedSchemeType, schemeTerm);

    calculateMaturityAmount(
      policyAmount,
      selectedSchemeType,
      schemeTerm,
      interestRate,
      compoundInterval,
      interestType
    );
  };

  // -------------------------------------------------------------

  return (
    <Box>
      <Typography sx={styles.header}>Add Investment</Typography>
      <Paper sx={styles.paper}>
        <Typography sx={styles.sectionHeader}>Policy Details</Typography>

        <Grid container spacing={2}>
          {/* Scheme Type Field */}
          <Grid item xs={12} md={6}>
            <Typography sx={{ ...styles.label }}>
              Scheme Type<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              value={selectedSchemeType}
              onChange={handleSchemeTypeChange}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            >
              {schemeTypes.map((schemeType, index) => (
                <MenuItem key={index} value={schemeType}>
                  {schemeType}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Scheme Name<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              value={selectedSchemeName}
              onChange={handleSchemeNameChange}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
              disabled={!selectedSchemeType}
            >
              {filteredSchemes.map((scheme, index) => (
                <MenuItem key={index} value={scheme.planName}>
                  {scheme.planName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>Scheme Term</Typography>
            <TextField
              disabled
              sx={styles.textField}
              value={schemeTerm}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Scheme Mode <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled
              value={schemeMode}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px", background: "#eee" },
              }}
            ></TextField>
          </Grid>

          {/* MIS Mode Field */}
          <Grid item xs={12} md={6}>
            <Typography sx={{ ...styles.label }}>
              MIS Mode<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              value={misMode}
              onChange={handleMisModeChange}
              disabled={!misModeEnabled}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: misModeEnabled ? "white" : "#eee",
                },
              }}
            >
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="quarterly">Quarterly</MenuItem>
              <MenuItem value="half-yearly">Half-Yearly</MenuItem>
              <MenuItem value="yearly">Yearly</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Policy Amount <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              value={policyAmount}
              onChange={handlePolicyAmountChange}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Deposit Amount <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled
              value={depositAmount}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              Maturiy Amount <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled
              value={maturityAmount}
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={styles.label}>
              MIS Interest <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled
              sx={styles.textField}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                },
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default NewInvestment;
