import React, { useState } from 'react';
import vllogo from "../../Assets/logo.png";
import { Box, Typography, Paper, Grid, TextField, Button,TableRow,TableBody,TableCell,Divider,TableContainer,TableHead,Table, } from '@mui/material';

const BranchCashTransfer = () => {
const [showStatement,setShowStatement] = useState(false);

  const textFieldStyle = {
    width: { xs: '100%', md: '100%' },
    height: '48px',
    borderRadius: '10px',
    borderColor: '#D9D9D9CC',
    backgroundColor: '#FFFFFF',
    '& .MuiInputBase-root': {
      height: '48px',
    },
  };

  const fontStyle = {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '14px',
    opacity: 0.6,
  };

  const handleViewStatement = () => {
    setShowStatement(true);
  };

  return (
    <Box>
      <Typography
        sx={{
          fontSize: { xs: '20px', md: '24px' },
          fontWeight: 600,
          lineHeight: '36px',
          fontFamily: 'Poppins',
          mb: 1,
        }}
      >
        Branch Cash Transfer
      </Typography>

      <Paper
        sx={{
          width: '100%',
          p: 2,
          borderRadius: '20px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '20px',
            lineHeight: '36px',
            fontWeight: 500,
            mb: 1,
          }}
        >
          Enter Details
        </Typography>

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ width: '100%', fontSize: { xs: '16px', md: '18px' }, marginBottom: '8px', ...fontStyle }}>
            Transfer Date :
            </Typography>
            <TextField
              type='date'
              fullWidth
              variant="outlined"
              sx={textFieldStyle}
              InputProps={{ style: { borderRadius: '10px' } }}
            >
              {/* Options for account number */}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ width: '100%', fontSize: { xs: '16px', md: '18px' }, marginBottom: '8px', ...fontStyle }}>
            From Branch *
            </Typography>
            <TextField
              select
              fullWidth
              variant="outlined"
              sx={textFieldStyle}
              InputProps={{ style: { borderRadius: '10px' } }}
            >
              {/* Options for financial year */}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ width: '100%', fontSize: { xs: '16px', md: '18px' }, marginBottom: '8px', ...fontStyle }}>
            To Branch *
            </Typography>
            <TextField select fullWidth variant="outlined" type="date" sx={textFieldStyle} InputProps={{ style: { borderRadius: '10px' } }} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ width: '100%', fontSize: { xs: '16px', md: '18px' }, marginBottom: '8px', ...fontStyle }}>
              Amount
            </Typography>
            <TextField fullWidth variant="outlined" sx={textFieldStyle} InputProps={{ style: { borderRadius: '10px' } }} />
          </Grid>

          {/* Align the buttons to the right */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '16px',
                marginTop: '20px',
              }}
            >
              <Button
                variant="contained"
                onClick={handleViewStatement}
                sx={{
                  textTransform: 'none',
                  fontFamily: 'Poppins',
                  background: '#DFBF41',
                  width: { xs: '50%', md: '157px' },
                  height: '48px',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                  '&:hover': { backgroundColor: '#B89B3E' },
                  color: 'white',
                }}
              >
                Search
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {showStatement && (
        <Paper sx={{ mt: 2, p: 2, borderRadius: "20px" }}>
        <Box>
        <TableContainer sx={{ borderRadius: "20px 20px 0px 0px",mt:2 }}>
        <Box sx={{ mt: 3, p: 2 }}>
        <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>Branch Txn List</Typography>
        </Box>
          <Table >
            <TableHead sx={{ background: "#413AF1", }}>
              <TableRow sx={{background:'#413AF1', borderRadius: "20px 20px 0px 0px"}}>
                <TableCell sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}>SlNo</TableCell>
                <TableCell sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}>From Branch</TableCell>
                <TableCell sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}>To Branch</TableCell>
                <TableCell sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}>Txn Date</TableCell>
                <TableCell sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}>Amount</TableCell>
                <TableCell sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}>Trf ID</TableCell>
                <TableCell sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}>Approve Status</TableCell>
                <TableCell sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}>Approve Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1234567890</TableCell>
                <TableCell>2022-05-15</TableCell>
                <TableCell>1000</TableCell>
                <TableCell>Branch A</TableCell>
                <TableCell>John Doe</TableCell>
                <TableCell>1000</TableCell>
                <TableCell>Branch A</TableCell>
                <TableCell>John Doe</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        </Box>
        </Paper>
      )}
    </Box>
  );
};

export default BranchCashTransfer;

