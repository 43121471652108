import React from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

function BankCharges() {
  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Bank Charges
      </Typography>

      <Paper
        sx={{
          padding: 3,
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          "@media (max-width: 600px)": {
            padding: "10px",
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "36px",
              mb: 1,
              mt: 0,
            }}
          >
            Set Charges
          </Typography>
        </Box>
        <TableContainer
          component={Paper}
          sx={{ borderRadius: "20px 20px 0px 0px" }}
        >
          <Table>
            <TableHead sx={{ background: "#413AF1", color: "white" }}>
              <TableRow>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Sl No</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Transaction Type</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Transaction Slab</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Your Charges</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>User charges</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Action</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ background: "#F2F6FA" }}>
              <TableCell>12</TableCell>
              <TableCell>Fund Transfer To Bank A/C (IMPS)</TableCell>
              <TableCell>Upto 1000 Flat Rs. 3 + 18% GST</TableCell>
              <TableCell>3</TableCell>
              <TableCell>
                <TextField
                  sx={{
                    backgroundColor: "#FFFFFF",
                    "& .MuiInputBase-root": {
                      height: "38px",
                    },
                  }}
                  InputProps={{
                    // style: { borderRadius: "10px" },
                  }}
                />
              </TableCell>
              <TableCell>
                <Button variant="contained" sx={{ background: "#E8C30B" }}>
                  Set
                </Button>
              </TableCell>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

export default BankCharges;
