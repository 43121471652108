import { Box, Button, Grid, Paper, TextField, Typography, List, ListItem } from "@mui/material";
import React from "react";
import empidtop from "../../Assets/empidtop.svg";
import empidbottom from "../../Assets/empidbottom.svg";
import idimg from "../../Assets/idimg.svg";
import empqr from "../../Assets/empqr.svg";
import CompanyName from "../../Assets/CompanyName&Logo.svg";

function AdvisorIDCard() {
  const textFieldStyle = {
    width: { xs: "100%", md: "100%" },
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { md: "48px", xs: "40px" },
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
  };
  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Advisor ID Card
      </Typography>

      <Paper
        sx={{
          padding: 3,
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          "@media (max-width: 600px)": {
            padding: "10px",
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Search Box
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Advisor Code :
              </Typography>
              <TextField
                sx={textFieldStyle}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  fontFamily: "Poppins",
                  background: "#DFBF41",
                  width: { xs: "100%", md: "157px" },
                  height: { md: "48px", xs: "40px" },
                  borderRadius: "10px",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "#B89B3E" },
                  color: "white",
                  mt: { md: 2.5, xs: 1 },
                  mb: { md: 0, xs: 2 },
                }}
              >
                Search
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ p: 2, mt: 2, borderRadius: "20px" }}>
        <Box sx={{ mt: 0 }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "36px",
              mb: 2,
            }}
          >
            Search Result
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              borderRadius: "10px",
              justifyContent: "center",
              alignItems: "center",
              gap: 4,
            }}
          >
            {/* ID Front */}
            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                src={empidtop}
                alt="empidtop"
                sx={{ width: "204px" }}
              />

              <Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box
                    component="img"
                    src={idimg}
                    alt="idimg"
                    sx={{
                      width: "70px",
                      height: "70px",
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    fontFamily: "Kameron",
                    color: "#E8C30B",
                    fontSize: "16px",
                    fontWeight: 700,
                    textAlign: "center",
                  }}
                >
                  Ankit Kumar
                </Typography>
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Developer
                </Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  ID NO : 905750250
                </Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  DOB : 30-07-1990
                </Typography>
                <Typography sx={{ fontSize: "10px" }}>Blood : A+</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  Phone : 8574998116
                </Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  E-Mail : email@example.com
                </Typography>
              </Box>
              <Box
                component="img"
                src={empqr}
                alt="empqr"
                sx={{ width: "100px", height: "20px" }}
              />
              <Box
                component="img"
                src={empidbottom}
                alt="empidtop"
                sx={{ width: "204px" }}
              />
            </Paper>

            {/* ID Back */}
            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box
                    component="img"
                    src={CompanyName}
                    alt="CompanyName"
                    sx={{ width: "85px", height: "45px", mt: 4 }}
                  />
                </Box>
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: 600,
                    textAlign: "center",
                    mb: 0,
                  }}
                >
                  TERMS AND CONDITIONS
                </Typography>
                <List
                  sx={{
                    listStyleType: "disc",
                    listStylePosition: "inside",
                    "& li::marker": {
                      color: "#FFD700", // Yellow color for the bullet
                    },
                  }}
                >
                  <ListItem
                    sx={{
                      display: "list-item",
                      fontSize: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetul
                    <br />
                    adipicing elit, sad diam nonummy nibh
                    <br />
                    eulsmod.
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "list-item",
                      fontSize: "8px",
                      fontWeight: "bold",
                      mt: -2,
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetul
                    <br />
                    adipicing elit, sad diam nonummy nibh
                    <br />
                    eulsmod.
                  </ListItem>
                </List>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "8px",
                    fontWeight: "bold",
                    mt: 0,
                  }}
                >
                  Join Date : DD/MM/YYYY
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Expire Date : DD/MM/YYYY
                </Typography>
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: 700,
                    textAlign: "center",
                    mt: 1.5,
                  }}
                >
                  --------------------
                </Typography>
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: 700,
                    textAlign: "center",
                    mb: 2,
                  }}
                >
                  Your Signature
                </Typography>
              </Box>
              <Box
                component="img"
                src={empidbottom}
                alt="empidtop"
                sx={{ width: "204px" }}
              />
            </Paper>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

export default AdvisorIDCard;
