import React from "react";
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const branches = [
  { value: "branch1", label: "Branch 1" },
  { value: "branch2", label: "Branch 2" },
  { value: "branch3", label: "Branch 3" },
];

const textFieldStyle = {
  width: { xs: "100%", md: "100%" },
  backgroundColor: "#FFFFFF",
  "& .MuiInputBase-root": {
    height: "48px",
  },
};

const fontStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "14px",
};

const InvestmentSearch = () => {
  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Investment Search
      </Typography>
      <Paper
        sx={{
          p: { md: 2, xs: 2 },
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          // "@media (max-width: 600px)": {
          //   padding: "20px",
          // },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Search Box
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Branch
              </Typography>
              <TextField
                select
                variant="outlined"
                sx={textFieldStyle}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
                {branches.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              From Date
            </Typography>
            <TextField
              type="date"
              fullWidth
              variant="outlined"
              sx={{
                ...textFieldStyle,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              To Date
            </Typography>
            <TextField
              type="date"
              fullWidth
              variant="outlined"
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Applicant Name
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Policy No.
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Member Code
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Plan Name
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Advisor Code
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
        </Grid>
        {/* Buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
            gap: "16px",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              fontFamily: "Poppins",
              background: "#DFBF41",
              width: { xs: "50%", md: "157px" },
              height: "48px",
              borderRadius: "10px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#B89B3E" },
              color: "white",
            }}
          >
            Search
          </Button>

          {/* Print Button */}

          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              fontFamily: "Poppins",
              background: "#F37D01",
              width: { xs: "50%", md: "157px" },
              height: "48px",
              borderRadius: "10px",
              fontWeight: "bold",
              color: "white",
              ":hover": { background: "#9c5f1e" },
            }}
          >
            Print
          </Button>
        </Box>
      </Paper>

      <Paper sx={{ mt: 2, p: 2, borderRadius: "20px" }}>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 2,
            mt: 0,
          }}
        >
          Search List
        </Typography>
        <Box sx={{ overflowX: "auto" }}>
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: "20px 20px 0px 0px",
              width: { md: "100%", xs: "296px" },
            }}
          >
            <Table aria-label="simple table" size="small">
              <TableHead sx={{ background: "#413AF1" }}>
                <TableRow>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Sl No
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Policy No
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Member Code
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Applicant Name
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Policy Date
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Plan Code
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Amount
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Maturity Amount
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Maturity Date
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Advisor Code
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Branch
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody></TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
};

export default InvestmentSearch;
