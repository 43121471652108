import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import React from "react";

function AdvisorPromotion() {
  const textFieldStyle = {
    width: { xs: "100%", md: "100%" },
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { md: "48px", xs: "40px" },
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
  };

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Advisor Promotion
      </Typography>

      <Paper
        sx={{
          padding: 3,
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          "@media (max-width: 600px)": {
            padding: "10px",
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Advisor Promotion
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Select Code*
              </Typography>
              <TextField
                select
                sx={textFieldStyle}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Immediate Jr.
              </Typography>
              <TextField
                sx={textFieldStyle}
                disabled
                InputProps={{
                  style: { borderRadius: "10px", background: "#eee" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Position *
              </Typography>
              <TextField
                sx={textFieldStyle}
                disabled
                InputProps={{
                  style: { borderRadius: "10px", background: "#eee" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Jr. Position *
              </Typography>
              <TextField
                sx={textFieldStyle}
                disabled
                InputProps={{
                  style: { borderRadius: "10px", background: "#eee" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Branch *
              </Typography>
              <TextField
                sx={textFieldStyle}
                disabled
                InputProps={{
                  style: { borderRadius: "10px", background: "#eee" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Select Branch *
              </Typography>
              <TextField
                select
                sx={textFieldStyle}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Senior Code *
              </Typography>
              <TextField
                sx={textFieldStyle}
                disabled
                InputProps={{
                  style: { borderRadius: "10px", background: "#eee" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                New Position *
              </Typography>
              <TextField
                select
                sx={textFieldStyle}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Position *
              </Typography>
              <TextField
                sx={textFieldStyle}
                disabled
                InputProps={{
                  style: { borderRadius: "10px", background: "#eee" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                New Senior *
              </Typography>
              <TextField
                sx={textFieldStyle}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Immediate Sr. *
              </Typography>
              <TextField
                sx={textFieldStyle}
                disabled
                InputProps={{
                  style: { borderRadius: "10px", background: "#eee" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Senior Name *
              </Typography>
              <TextField
                sx={textFieldStyle}
                disabled
                InputProps={{
                  style: { borderRadius: "10px", background: "#eee" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Sr. Position *
              </Typography>
              <TextField
                sx={textFieldStyle}
                disabled
                InputProps={{
                  style: { borderRadius: "10px", background: "#eee" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Senior Position *
              </Typography>
              <TextField
                sx={textFieldStyle}
                disabled
                InputProps={{
                  style: { borderRadius: "10px", background: "#eee" },
                }}
              ></TextField>
            </Box>
          </Grid>
        </Grid>

        {/* Button */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              fontFamily: "Poppins",
              background: "#DFBF41",
              width: { xs: "100%", md: "157px" },
              height: { md: "48px", xs: "40px" },
              borderRadius: "10px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#B89B3E" },
              color: "white",
              mt: { md: 2.5, xs: 2 },
              mb: { md: 0, xs: 2 },
            }}
          >
            Update
          </Button>

          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              fontFamily: "Poppins",
              background: "#3029D9",
              width: { xs: "100%", md: "157px" },
              height: { md: "48px", xs: "40px" },
              borderRadius: "10px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#B89B3E" },
              color: "white",
              mt: { md: 2.5, xs: 2 },
              mb: { md: 0, xs: 2 },
            }}
          >
            New
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default AdvisorPromotion;
