import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItemText,
  Collapse,
  Toolbar,
  Box,
  CssBaseline,
  AppBar,
  Button,
  IconButton,
  ListItemButton,
  Typography,
  Avatar,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  Mail,
  Notifications,
  AccountCircle,
  Menu as MenuIcon,
} from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTheme, useMediaQuery } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { toast } from "react-toastify";

import vllogo from "../Assets/logo.png";
import userlogo from "../Assets/User.png";

const Sidebar = () => {
  const [openMemberSection, setOpenMemberSection] = useState(false);
  const [openSummary, setOpenSummary] = useState(false);
  const [openMemberShareSection, setOpenMemberShareSection] = useState(false);
  const [openApprovalSection, setOpenApprovalSection] = useState(false);
  const [openInvestmentSection, setOpenInvestmentSection] = useState(false);
  const [openSavingSection, setOpenSavingSection] = useState(false);
  const [openAccountSection, setOpenAccountSection] = useState(false);
  const [openEmployeeSection, setOpenEmployeeSection] = useState(false);
  const [openAdvisorSection, setOpenAdvisorSection] = useState(false);
  const [openRealBanking, setOpenRealBanking] = useState(false);
  const [openMobileAppControlSection, setMobileAppControlSection] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
  // const [selectedComponent, setSelectedComponent] = useState(<Dashboard />);

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const userType = localStorage.getItem("usertype");
  const username = localStorage.getItem("username");

  const handleMemberClick = () => {
    setOpenMemberSection(!openMemberSection);
  };

  const handleSummaryClick = () => {
    setOpenSummary(!openSummary);
  };

  const handleMemberShareClick = () => {
    setOpenMemberShareSection(!openMemberShareSection);
  };

  const handleSavingClick = () => {
    setOpenSavingSection(!openSavingSection);
  };

  const handleApprovalClick = () => {
    setOpenApprovalSection(!openApprovalSection);
  };

  const handleInvestmentClick = () => {
    setOpenInvestmentSection(!openInvestmentSection);
  };

  const handleAccountClick = () => {
    setOpenAccountSection(!openAccountSection);
  };

  const handleEmployeeClick = () => {
    setOpenEmployeeSection(!openEmployeeSection);
  };

  const handleRealBankingClick = () => {
    setOpenRealBanking(!openRealBanking);
  };

  const handleAdvisorClick = () => {
    setOpenAdvisorSection(!openAdvisorSection);
  };

  const handleMobileControlClick = () => {
    setMobileAppControlSection(!openMobileAppControlSection);
  };

  const handleMenuOpen = (event, setAnchor) => {
    setAnchor(event.currentTarget);
  };

  const handleMenuClose = (setAnchor) => {
    setAnchor(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleRightDrawerToggle = () => {
    setRightDrawerOpen(!rightDrawerOpen);
  };

  const drawerWidth = 240;

  const handleNavigation = (path, component) => {
    navigate(path);
  };

  const sidebarSections = {
    ROLE_ADMIN: [
      { text: "Dashboard", path: "/dashboard" },
      {
        text: "Summary",
        isExpandable: true,
        open: openSummary,
        handleClick: handleSummaryClick,
        items: [
          { text: "Home", path: "/home-summary" },
        ],
      },
      {
        text: "Member Section",
        isExpandable: true,
        open: openMemberSection,
        handleClick: handleMemberClick,
        items: [
          { text: "Add Member", path: "/add-member" },
          { text: "Add Member KYC", path: "/add-member-kyc" },
          { text: "Member Summary", path: "/member-summary" },
          { text: "Search Report", path: "/search-member" },
        ],
      },
      {
        text: "Member Share",
        isExpandable: true,
        open: openMemberShareSection,
        handleClick: handleMemberShareClick,
        items: [
          { text: "Share Transfer", path: "/share-transfer" },
          { text: "Unalloted Shares", path: "/unalloted-shares" },
          { text: "Share Certificate", path: "/share-certificate" },
          { text: "Share Report", path: "/share-report" },
          { text: "DNO Generate", path: "/dno-generate" },
        ],
      },
      {
        text: "Saving Account",
        isExpandable: true,
        open: openSavingSection,
        handleClick: handleSavingClick,
        items: [
          { text: "Savings Plan Master", path: "/saving-plan-master" },
          { text: "Savings Opening", path: "/savings-opening" },
          { text: "Savings Transaction", path: "/saving-deposite-withdraw" },
          { text: "SB Intra Transfer", path: "/sb-intra-transfer" },
          { text: "SB Interest Transfer", path: "/sb-interest-transfer" },
          { text: "Savings Passbook", path: "/account-passbook" },
          { text: "Savings Statement", path: "/saving-statement" },
          { text: "Account Closer", path: "/saving-account-close" },
          { text: "Search Savings AC", path: "/search-saving" },
          { text: "SMS Charges Deduction", path: "/sms-search-saving" },
          {
            text: "Service Charges Deduction",
            path: "/service-charge-deduction",
          },
        ],
      },
      {
        text: "Approval Section",
        isExpandable: true,
        open: openApprovalSection,
        handleClick: handleApprovalClick,
        items: [
          { text: "Member Approval", path: "/member-approval" },
          {
            text: "Saving Transaction Approval",
            path: "/saving-transaction-approval",
          },
          { text: "Advisor Approval", path: "/advisor-approval" },
        ],
      },
      {
        text: "Investment Section",
        isExpandable: true,
        open: openInvestmentSection,
        handleClick: handleInvestmentClick,
        items: [
          { text: "Plan Master", path: "/plan-master" },
          { text: "New Investment", path: "/new-investment" },
          { text: "Renewal Payment", path: "/renewal-payment" },
          { text: "Daily Renewal Payment", path: "/daily-renewal-payment" },
          { text: "Flexi Renewal Payment", path: "/flexi-renewal-payment" },
          { text: "Daily / RD Passbook", path: "/daily-rd-passbook" },
          { text: "Investment Receipt", path: "/investment-receipt" },
          { text: "Recurring Receipt", path: "/recurring-receipt" },
          { text: "Certificate Issue", path: "/certificate-issue" },
          { text: "Certificate Re-Issue", path: "/certificate-re-issue" },
          { text: "Interest Details", path: "/interest-details" },
          { text: "Interest Payable", path: "/interest-payable" },
          { text: "Investment Search", path: "/investment-search" },
        ],
      },
      {
        text: "Account Section",
        isExpandable: true,
        open: openAccountSection,
        handleClick: handleAccountClick,
        items: [
          // { text: "Ledger Master", path: "/ledger-master" },
          // { text: "Payment Entry", path: "/payment-entry" },
          // { text: "Receipt Entry", path: "/receipt-entry" },
          // { text: "Countra Entry", path: "/countra-entry" },
          // { text: "Journal Entry", path: "/journal-entry" },
          // { text: "Incentive Payment", path: "/incentive-payment" },
          // { text: "MIS Payment", path: "/mis-payment" },
          // { text: "Check Clearing", path: "/check-clearing" },
          // { text: "Mandate Deposit Bank", path: "/mandate-deposit-bank" },
          // { text: "Bank Statement", path: "/bank-statement" },
          { text: "Payment Entry", path: "/payment-entry" },
          { text: "Receipt Entry", path: "/receipt-entry" },
          { text: "Ledger Master", path: "/ledger-master" },
          { text: "Contra Entry", path: "/countra-entry" },
          { text: "Journal Entry", path: "/journal-entry" },
          { text: "Incentive Payment", path: "/insentive-Payment" },
          { text: "MIS Payment", path: "/mis-payment" },
          { text: "Check Clearing", path: "/check-clearing" },
          { text: "Mandate Deposit Bank", path: "/mandate-deposit-bank" },
          { text: "Bank Statement", path: "/bank-statement" },
          { text: "Cash Book", path: "/cash-book" },
          { text: "Transfer Book", path: "/transfer-book" },
          { text: "Day Book", path: "/day-book" },
          { text: "Ledger Report", path: "/ledger-report" },
          { text: "Journal Report", path: "/journal-report" },
          { text: "Trial Balance", path: "/trial-balance" },
          { text: "PL Statement", path: "/pl-statement" },
          { text: "Balance Sheet", path: "/balance-sheet" },
          { text: "Profit Loss", path: "/profit-loss" },
          { text: "Branch Cash Transfer", path: "/branch-cash-transfer" },
        ],
      },
      {
        text: "Collector / Advisor",
        isExpandable: true,
        open: openAdvisorSection,
        handleClick: handleAdvisorClick,
        items: [
          { text: "Add Collector/Advisor", path: "/add-collector-advisor" },
          { text: "Advisor Tree", path: "/advisor-tree" },
          { text: "Advisor Downline", path: "/advisor-downline" },
          { text: "Advisor Promotion", path: "/advisor-promotion" },
          { text: "Advisor ID Card", path: "/advisor-id-card" },
          { text: "Search Advisor", path: "/search-advisor" },
        ],
      },
      {
        text: "Employee Section",
        isExpandable: true,
        open: openEmployeeSection,
        handleClick: handleEmployeeClick,
        items: [
          { text: "Add Designation", path: "/add-designation" },
          { text: "Add Department", path: "/add-department" },
          { text: "Add Employee", path: "/add-employee" },
          { text: "Employee ID Card", path: "/employee-id-card" },
          { text: "Search Employee", path: "/search-employee" },
        ],
      },
      {
        text: "Mobile App Control",
        isExpandable: true,
        open: openMobileAppControlSection,
        handleClick: handleMobileControlClick,
        items: [
          { text: "App User Creation", path: "/app-user-creation" },
          {
            text: "Mobile Member Approval",
            path: "/mo-member-approval",
          },
          {
            text: "Advisor Approval",
            path: "/mo-advisor-approval",
          },
          {
            text: "Investment Approval",
            path: "/mo-policy-approval",
          },
          {
            text: "Recurring Approval",
            path: "/mo-recurring-approval",
          },
          {
            text: "Rgular EMI Approval",
            path: "/mo-regular-emi-approval",
          },
          {
            text: "Irregular EMI Approval",
            path: "/mo-irregular-emi-approval",
          },
          {
            text: "Saving Transaction Approval",
            path: "/mo-saving-transaction-approval",
          },
        ],
      },
      {
        text: "Real Banking",
        isExpandable: true,
        open: openRealBanking,
        handleClick: handleRealBankingClick,
        items: [
          { text: "Bank Charges", path: "/bank-charges" },
          { text: "Account Details", path: "/account-details" },
          { text: "Create Virtual Account", path: "/create-virtual-account" },
          { text: "Add New Beneficiary", path: "/add-new-beneficiary" },
          { text: "Fund Transfer", path: "/fund-transfer" },
          { text: "Receive Payments List", path: "/receive-payments-list" },
          { text: "Transfer Payments List", path: "/transfer-payments-list" },
        ],
      },
    ],
    ROLE_MEMBER: [
      { text: "Dashboard", path: "/dashboard" },
      {
        text: "Member Section",
        isExpandable: true,
        open: openMemberSection,
        handleClick: handleMemberClick,
        items: [
          // { text: "Add Member", path: "/add-member" },
          { text: "Add Member KYC", path: "/add-member-kyc" },
          { text: "Member Summary", path: "/member-summary" },
          { text: "Search Report", path: "/search-member" },
        ],
      },
      {
        text: "Saving Account",
        isExpandable: true,
        open: openSavingSection,
        handleClick: handleSavingClick,
        items: [
          // { text: "Savings Plan Master", path: "/saving-plan-master" },
          { text: "Savings Opening", path: "/savings-opening" },
          { text: "Savings Transaction", path: "/saving-deposite-withdraw" },
          { text: "SB Intra Transfer", path: "/sb-intra-transfer" },
          { text: "SB Interest Transfer", path: "/sb-interest-transfer" },
          { text: "Savings Passbook", path: "/account-passbook" },
          { text: "Savings Statement", path: "/saving-statement" },
          { text: "Account Closer", path: "/saving-account-close" },
          { text: "Search Savings AC", path: "/search-saving" },
          { text: "SMS Charges Deduction", path: "/sms-search-saving" },
          {
            text: "Service Charges Deduction",
            path: "/service-charge-deduction",
          },
        ],
      },
    ],
    ROLE_ADVISOR: [
      { text: "Dashboard", path: "/dashboard" },
      {
        text: "Member Section",
        isExpandable: true,
        open: openMemberSection,
        handleClick: handleMemberClick,
        items: [
          { text: "Add Member", path: "/add-member" },
          { text: "Add Member KYC", path: "/add-member-kyc" },
          { text: "Member Summary", path: "/member-summary" },
          { text: "Search Report", path: "/search-member" },
        ],
      },
      {
        text: "Saving Account",
        isExpandable: true,
        open: openSavingSection,
        handleClick: handleSavingClick,
        items: [
          { text: "Savings Plan Master", path: "/saving-plan-master" },
          { text: "Savings Opening", path: "/savings-opening" },
          { text: "Savings Transaction", path: "/saving-deposite-withdraw" },
          { text: "SB Intra Transfer", path: "/sb-intra-transfer" },
          { text: "SB Interest Transfer", path: "/sb-interest-transfer" },
          { text: "Savings Passbook", path: "/account-passbook" },
          { text: "Savings Statement", path: "/saving-statement" },
          { text: "Account Closer", path: "/saving-account-close" },
          { text: "Search Savings AC", path: "/search-saving" },
          { text: "SMS Charges Deduction", path: "/sms-search-saving" },
          {
            text: "Service Charges Deduction",
            path: "/service-charge-deduction",
          },
        ],
      },
      {
        text: "Collector / Advisor",
        isExpandable: true,
        open: openAdvisorSection,
        handleClick: handleAdvisorClick,
        items: [
          { text: "Add Collector/Advisor", path: "/add-collector-advisor" },
          { text: "Advisor Tree", path: "/advisor-tree" },
          { text: "Advisor Downline", path: "/advisor-downline" },
          { text: "Advisor Promotion", path: "/advisor-promotion" },
          { text: "Advisor ID Card", path: "/advisor-id-card" },
          { text: "Search Advisor", path: "/search-advisor" },
        ],
      },
    ],
  };

  const renderSections = () => {
    return sidebarSections[userType].map((section, index) =>
      section.isExpandable ? (
        <React.Fragment key={section.text}>
          <ListItemButton
            sx={{ ":hover": { background: "#E8C30B" } }}
            onClick={section.handleClick}
          >
            <ListItemText primary={section.text} />
            {section.isExpandable &&
              (section.open ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
          {section.isExpandable && (
            <Collapse in={section.open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {section.items.map((item) => (
                  <ListItemButton
                    key={item.text}
                    onClick={() => handleNavigation(item.path)}
                    sx={{
                      pl: 4,
                      height: "43px",
                      ":hover": { background: "#E8C30B" },
                    }}
                  >
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      ) : (
        <ListItemButton
          key={index}
          sx={{ ":hover": { background: "#E8C30B" } }}
          onClick={() => {
            handleNavigation(section.path);
            setMobileOpen(false);
          }}
        >
          <ListItemText primary={section.text} />
        </ListItemButton>
      )
    );
  };

  // Optimized Sidebar Drawer desktop view
  const drawer = (
    <Box
      sx={{
        backgroundColor: "#3029D9",
        height: "100%",
        color: "white",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          component="img"
          src={vllogo}
          alt="VL_Logo"
          sx={{ width: "50px", height: "50px", mb: -1 }}
        />
      </Toolbar>
      {/* <List sx={{ background: "#3029D9" }}>
        {[
          { text: "Dashboard", path: "/dashboard" },
          {
            text: "Member Section",
            isExpandable: true,
            open: openMemberSection,
            handleClick: handleMemberClick,
            items: [
              { text: "Add Member", path: "/add-member" },
              { text: "Add Member KYC", path: "/add-member-kyc" },
              { text: "Member Summary", path: "/member-summary" },
              { text: "Search Report", path: "/search-member" },
            ],
          },
          {
            text: "Member Share",
            isExpandable: true,
            open: openMemberShareSection,
            handleClick: handleMemberShareClick,
            items: [
              { text: "Share Transfer", path: "/share-transfer" },
              { text: "Unalloted Shares", path: "/unalloted-shares" },
              { text: "Share Certificate", path: "/share-certificate" },
              { text: "Share Report", path: "/share-report" },
              { text: "DNO Generate", path: "/dno-generate" },
            ],
          },
          {
            text: "Saving Account",
            isExpandable: true,
            open: openSavingSection,
            handleClick: handleSavingClick,
            items: [
              { text: "Savings Plan Master", path: "/saving-plan-master" },
              { text: "Savings Opening", path: "/savings-opening" },
              {
                text: "Savings Transaction",
                path: "/saving-deposite-withdraw",
              },

              { text: "SB Intra Transfer", path: "/sb-intra-transfer" },
              { text: "SB Interest Transfer", path: "/sb-interest-transfer" },

              { text: "Savings Passbook", path: "/account-passbook" },
              { text: "Savings Statement", path: "/saving-statement" },
              { text: "Account Closer", path: "/saving-account-close" },
              { text: "Search Savings AC", path: "/search-saving" },
              { text: "SMS Charges Deduction", path: "/sms-search-saving" },
              {
                text: "Service Charges Deduction",
                path: "/service-charge-deduction",
              },
            ],
          },
          {
            text: "Approval Section",
            isExpandable: true,
            open: openApprovalSection,
            handleClick: handleApprovalClick,
            items: [
              { text: "Member Approval", path: "/member-approval" },
              {
                text: "Saving Transaction Approval",
                path: "/saving-transaction-approval",
              },
              { text: "Advisor Approval", path: "/advisor-approval" },
            ],
          },
          {
            text: "Investment Section",
            isExpandable: true,
            open: openInvestmentSection,
            handleClick: handleInvestmentClick,
            items: [
              { text: "Plan Master", path: "/plan-master" },
              { text: "New Investment", path: "/new-investment" },
              { text: "Renewal Payment", path: "/renewal-payment" },
              { text: "Daily Renewal Payment", path: "/daily-renewal-payment" },
              { text: "Flexi Renewal Payment", path: "/flexi-renewal-payment" },
              { text: "Daily / RD Passbook", path: "/daily-rd-passbook" },
              { text: "Investment Receipt", path: "/investment-receipt" },
              { text: "Recurring Receipt", path: "/recurring-receipt" },
              { text: "Certificate Issue", path: "/certificate-issue" },
              { text: "Certificate Re-Issue", path: "/certificate-re-issue" },
              { text: "Interest Details", path: "/interest-details" },
              { text: "Interest Payable", path: "/interest-payable" },
              { text: "Investment Search", path: "/investment-search" },
            ],
          },
          {
            text: "Account Section",
            isExpandable: true,
            open: openAccountSection,
            handleClick: handleAccountClick,
            items: [
              { text: "Ledger Master", path: "/ledger-master" },
              { text: "Payment Entry", path: "/payment-entry" },
              { text: "Receipt Entry", path: "/receipt-entry" },
              { text: "Countra Entry", path: "/countra-entry" },
              { text: "Journal Entry", path: "/journal-entry" },
              { text: "Insentive Payment", path: "/insentive-payment" },
              { text: "MIS Payment", path: "/mis-payment" },
              { text: "Check Clearing", path: "/check-clearing" },
              { text: "Mandate Deposit Bank", path: "/mandate-deposit-bank" },
              { text: "Bank Statement", path: "/bank-statement" },
            ],
          },
          {
            text: "Collector / Advisor",
            isExpandable: true,
            open: openAdvisorSection,
            handleClick: handleAdvisorClick,
            items: [
              { text: "Add Collector/Advisor", path: "/add-collector-advisor" },
              { text: "Advisor Tree", path: "/advisor-tree" },
              { text: "Advisor Downline", path: "/advisor-downline" },
              { text: "Advisor Promotion", path: "/advisor-promotion" },
              { text: "Advisor ID Card", path: "/advisor-id-card" },
              { text: "Search Advisor", path: "/search-advisor" },
            ],
          },
          {
            text: "Employee Section",
            isExpandable: true,
            open: openEmployeeSection,
            handleClick: handleEmployeeClick,
            items: [
              { text: "Add Designation", path: "/add-designation" },
              { text: "Add Department", path: "/add-department" },
              { text: "Add Employee", path: "/add-employee" },
              { text: "Employee ID Card", path: "/employee-id-card" },
              { text: "Search Employee", path: "/search-employee" },
            ],
          },
          {
            text: "Real Banking",
            isExpandable: true,
            open: openRealBanking,
            handleClick: handleRealBankingClick,
            items: [
              { text: "Bank Charges", path: "/bank-charges" },
              { text: "Account Details", path: "/account-details" },
              { text: "Create Virtual Account", path: "/create-virtual-account" },
              { text: "Add New Beneficiary", path: "/add-new-beneficiary" },
              { text: "Fund Transfer", path: "/fund-transfer" },
              { text: "Receive Payments List", path: "/receive-payments-list" },
              { text: "Transfer Payments List", path: "/transfer-payments-list" },
            ],
          },
        ].map((section, index) =>
          section.isExpandable ? (
            <React.Fragment key={index}>
              <ListItemButton
                sx={{ ":hover": { background: "#E8C30B" } }}
                onClick={section.handleClick}
              >
                <ListItemText primary={section.text} />
                {section.open ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={section.open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {section.items.map((item, i) => (
                    <ListItemButton
                      key={i}
                      onClick={() => {
                        handleNavigation(item.path);
                        setMobileOpen(false);
                      }}
                      sx={{
                        pl: 4,
                        height: "43px",
                        ":hover": { background: "#E8C30B" },
                      }}
                    >
                      <ListItemText primary={item.text} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ) : (
            <ListItemButton
              key={index}
              sx={{ ":hover": { background: "#E8C30B" } }}
              onClick={() => {
                handleNavigation(section.path);
                setMobileOpen(false);
              }}
            >
              <ListItemText primary={section.text} />
            </ListItemButton>
          )
        )}
      </List> */}
      <List sx={{ background: "#3029D9" }}>{renderSections()}</List>
    </Box>
  );

  // AppBar Drawer For mobile view
  const rightDrawer = (
    <Box
      sx={{
        width: drawerWidth,
        backgroundColor: "#3029D9",
        height: "100%",
        color: "white",
      }}
    >
      <List>
        <ListItemButton onClick={handleRightDrawerToggle}>
          <ListItemText primary="Home" />
        </ListItemButton>
        <ListItemButton onClick={handleRightDrawerToggle}>
          <ListItemText primary="Configuration" />
        </ListItemButton>
        <ListItemButton onClick={handleRightDrawerToggle}>
          <ListItemText primary="NDH Report" />
        </ListItemButton>
        <ListItemButton onClick={handleRightDrawerToggle}>
          <ListItemText primary="Collection Sheet" />
        </ListItemButton>
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#3029D9",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          {isMobile && (
            <>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
              <Avatar
                src={vllogo}
                alt="VL_Logo"
                sx={{ width: "40px", height: "40px" }}
              />
            </>
          )}

          {!isMobile && (
            <>
              <Button sx={{ color: "white", textTransform: "none" }}>
                Home
              </Button>
              <Button
                sx={{ color: "white", textTransform: "none" }}
                aria-controls="configuration-menu"
                aria-haspopup="true"
                onClick={(e) => handleMenuOpen(e, setAnchorEl)}
                endIcon={<ArrowDropDownIcon />}
              >
                Configuration
              </Button>
              <Button
                sx={{ color: "white", textTransform: "none" }}
                aria-controls="ndh-report-menu"
                aria-haspopup="true"
                onClick={(e) => handleMenuOpen(e, setAnchorEl2)}
                endIcon={<ArrowDropDownIcon />}
              >
                NDH Report
              </Button>
              <Button
                sx={{ color: "white", textTransform: "none" }}
                aria-controls="collection-sheet-menu"
                aria-haspopup="true"
                onClick={(e) => handleMenuOpen(e, setAnchorEl3)}
                endIcon={<ArrowDropDownIcon />}
              >
                Collection Sheet
              </Button>
            </>
          )}

          {/* AppBar Right-side Icons */}
          <Box sx={{ flexGrow: 1 }} />
          <Typography sx={{ fontWeight: "bold", mr: 2, color: "yellow" }}>
            Welcome {username.toUpperCase()}
          </Typography>
          <IconButton color="inherit">
            <Mail />
          </IconButton>
          <IconButton color="inherit">
            <Notifications />
          </IconButton>
          <IconButton color="inherit">
            {/* <AccountCircle /> */}
            <Box
              component="img"
              src={userlogo}
              alt="userlogo"
              sx={{ width: "30px", height: "30px", borderRadius: "50px" }}
            />
          </IconButton>

          {/* Logout button */}
          <IconButton
            onClick={() => {
              localStorage.clear();
              navigate("/");
              toast.success("Successfully logged out!");
            }}
            color="inherit"
          >
            <LogoutIcon />
          </IconButton>

          {/* Mobile Menu Icon for AppBar Items */}
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open right drawer"
              edge="end"
              onClick={handleRightDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      {/* Sidebar for larger screens */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            "&::-webkit-scrollbar": {
              width: "6px", // Adjust scrollbar width
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888", // Adjust scrollbar color
              borderRadius: "3px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#555", // Adjust hover color
            },
            // For Firefox
            scrollbarWidth: "thin", // Adjust scrollbar width for Firefox
            scrollbarColor: "#888 #e0e0e0", // Adjust scrollbar thumb and track color for Firefox
          },
        }}
        open
      >
        {drawer}
      </Drawer>

      {/* Sidebar for mobile */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        {drawer}
      </Drawer>

      {/* Right Drawer for mobile view */}
      <Drawer
        anchor="right"
        open={rightDrawerOpen}
        onClose={handleRightDrawerToggle}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        {rightDrawer}
      </Drawer>

      {/* Main Area */}
      <Box component="main" sx={{ flexGrow: 1, p: 2, py: 9, height: "100%" }}>
        {/* {selectedComponent} */}
        <Outlet />
      </Box>
    </Box>
  );
};

export default Sidebar;
