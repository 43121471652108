
import React, { useState } from 'react';
import {
  Box,
  IconButton,
  TextField,
  Typography,
  Paper,
  Avatar,
  Fab,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import botAvatar from '../../Assets/Chatbot/botAvatar.png';


const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([
    { text: 'Hello! How can I assist you today?', isBot: true },
  ]);
  const [input, setInput] = useState('');

  const toggleChatbot = () => {
    setIsOpen(!isOpen);
  };

  const handleSend = () => {
    if (input.trim() === '') return;

    const userMessage = { text: input, isBot: false };
    setMessages((prev) => [...prev, userMessage]);
    setInput('');

    // Simulate bot response
    setTimeout(() => {
      const botResponse = generateBotResponse(input);
      setMessages((prev) => [...prev, botResponse]);
    }, 500);
  };

  const generateBotResponse = (message) => {
    const lowerCaseMessage = message.toLowerCase();
    let response = "I'm sorry, I didn't understand that. Can you please rephrase?";

    if (lowerCaseMessage.includes('hello') || lowerCaseMessage.includes('hi')) {
      response = 'Hello! How can I help you today?';
    } else if (lowerCaseMessage.includes('help')) {
      response = 'Sure, I am here to help you. What do you need assistance with?';
    } else if (lowerCaseMessage.includes('forgot password')) {
      response = 'You can reset your password by clicking on the "Forgot password?" link below the login form.';
    } else if (lowerCaseMessage.includes('contact')) {
      response = 'You can contact us at support@vlnidhi.com or call us at (123) 456-7890.';
    } else if (lowerCaseMessage.includes('open account')) {
      response = 'You can open an account by visiting our website and clicking on the "Open an Account" button.';
    } else if (lowerCaseMessage.includes('loan')) {
      response = 'We offer various types of loans. Please specify the type of loan you are interested in, like home loan, car loan, or personal loan.';
    } else if (lowerCaseMessage.includes('home loan')) {
      response = 'For home loans, we offer competitive interest rates. You can apply for a home loan directly through our website.';
    } else if (lowerCaseMessage.includes('car loan')) {
      response = 'Our car loan offers flexible repayment options. Visit our loan section on the website for more details.';
    } else if (lowerCaseMessage.includes('personal loan')) {
      response = 'Personal loans are available with quick approval and minimal documentation. Check our loan section for more info.';
    } else if (lowerCaseMessage.includes('hours')) {
      response = 'Our customer service is available 24/7. You can reach us anytime!';
    } else if (lowerCaseMessage.includes('location')) {
      response = 'Our main branch is located at CORPORATE OFFICE-Edsom Fintech Private Limited, Second Floor, Golden Empire, Baner, PUNE 411045.';
    } else if (lowerCaseMessage.includes('services')) {
      response = 'We offer a wide range of services including savings accounts, loans, investment options, and more.';
    } else if (lowerCaseMessage.includes('investment options')) {
      response = 'We provide various investment options such as mutual funds, fixed deposits, and retirement plans.';
    }

    return { text: response, isBot: true };
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };

  return (
    <div>
      {isOpen && (
        <Paper
          elevation={8}
          sx={{
            position: 'fixed',
            bottom: 80,
            right: 20,
            width: 350,
            height: 450,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '12px',
            overflow: 'hidden',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            zIndex: 1000,
            backgroundColor: '#fff',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#0078d4',
              color: '#fff',
              p: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px solid rgba(0,0,0,0.2)',
            }}
          >
            <Typography variant="h6">Smart Assistant</Typography>
            <IconButton onClick={toggleChatbot} sx={{ color: '#fff' }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              flex: 1,
              p: 2,
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {messages.map((msg, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  mb: 1,
                  alignItems: 'flex-end',
                  alignSelf: msg.isBot ? 'flex-start' : 'flex-end',
                }}
              >
                {msg.isBot && (
                  <Avatar
                    src={botAvatar}
                    sx={{ width: 30, height: 30, mr: 1 }}
                  />
                )}
                <Box
                  sx={{
                    backgroundColor: msg.isBot ? '#f0f0f0' : '#0078d4',
                    color: msg.isBot ? '#000' : '#fff',
                    p: 1.5,
                    borderRadius: 2,
                    maxWidth: '80%',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  }}
                >
                  {msg.text}
                </Box>
              </Box>
            ))}
          </Box>
          <Box sx={{ p: 2, display: 'flex', alignItems: 'center', borderTop: '1px solid rgba(0,0,0,0.2)' }}>
            <TextField
              variant="outlined"
              placeholder="Type a message..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={handleKeyPress}
              fullWidth
              size="small"
              sx={{ borderRadius: '20px', backgroundColor: '#f0f0f0' }}
            />
            <IconButton
              color="primary"
              onClick={handleSend}
              sx={{ ml: 1, backgroundColor: '#0078d4', '&:hover': { backgroundColor: '#005a9e' } }}
            >
              <SendIcon />
            </IconButton>
          </Box>
        </Paper>
      )}
      <Fab
        color="primary"
        aria-label="chat"
        onClick={toggleChatbot}
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          zIndex: 1000,
          backgroundColor: '#0078d4',
          '&:hover': { backgroundColor: '#005a9e' },
        }}
      >
        <ChatIcon />
      </Fab>
    </div>
  );
};

export default Chatbot;
