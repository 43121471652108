import React, { useState } from 'react';
import {
    Container,
    Paper,
    Grid,
    Typography,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    TextField,
    Button,
    Table,
    Box,
    Switch,
    styled,
  } from "@mui/material";
  import PersonIcon from "@mui/icons-material/Person";

   const textFieldStyle = {
    width: { xs: "100%", md: "100%" },
    height: "48px",
    borderRadius: "80%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: "48px",
    },
  };
  
  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    opacity: 0.6,
    width: "100%",
    fontSize: { xs: "12px", md: "14px" },
    mb: 1,
  };
  
  const ImageUploadWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid #E0E0E0",
    borderRadius: "8px",
    width: "200px",
    height: "200px",
    backgroundColor: "#F9F9F9",
    alignSelf: "flex-start",
  }));
  
  const UploadButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
    marginTop: theme.spacing(2),
    backgroundColor: "#E0E0E0",
    alignSelf: "flex-start",
  }));

  const Caption = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(1),
    fontSize: "12px",
    color: "#BDBDBD",
    alignSelf: "flex-start",
  }));

const DailyRenewalPayment = () => {

    const [showform,setShowform] =useState(false);

    const handleSearchClick = () => {
        setShowform(true);
      };

return (
<Box>
  <Typography
    sx={{
      fontSize: { xs: "20px", md: "24px" },
      fontWeight: 600,
      lineHeight: "36px",
      textAlign: "start",
    }}
  >
    Daily Renewal
  </Typography>
  <Paper
    elevation={3}
    sx={{
      borderRadius: 4,
      p: 3,
    }}
  >
    <Typography
      variant="h6"
      sx={{
        fontSize: { xs: "20px", md: "24px" },
        fontWeight: 600,
        mb: 2,
        textAlign: "left",
      }}
    >
      Search Box
    </Typography>

    <Grid container spacing={1}>
      {/* Policy No. Dropdown */}
      <Grid item xs={12} md={3}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Policy No.*</InputLabel>
          <Select
            label="Policy No.*"
            defaultValue=""
            sx={{ borderRadius: 2 }}
          >
            <MenuItem value={10}>Policy 1</MenuItem>
            <MenuItem value={20}>Policy 2</MenuItem>
            <MenuItem value={30}>Policy 3</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {/* Renewal Date */}
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          variant="outlined"
          label="Renewal Date*"
          type="date"
          InputLabelProps={{ shrink: true }}
          sx={{ borderRadius: 2 }}
        />
      </Grid>

      {/* Branch Name Dropdown */}
      <Grid item xs={12} md={3}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Branch Name*</InputLabel>
          <Select
            label="Branch Name*"
            defaultValue=""
            sx={{ borderRadius: 2 }}
          >
            <MenuItem value={10}>Branch 1</MenuItem>
            <MenuItem value={20}>Branch 2</MenuItem>
            <MenuItem value={30}>Branch 3</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {/* Search Button */}
      <Grid
        item
        xs={12}
        md={3}
        sx={{
          display: "flex",
          alignItems: "center", // Vertically centers the button
          justifyContent: "center", // Horizontally centers the button
        }}
      >
        <Button
          variant="contained"
          fullWidth
          onClick={handleSearchClick}
          sx={{
            backgroundColor: "#E8C30B",
            color: "#fff",
            height: "48px", // Increased height for better appearance
            borderRadius: 2,
            textTransform: "none",
            fontWeight: 600,
            fontSize: "16px",
          }}
        >
          Search
        </Button>
      </Grid>
    </Grid>
  </Paper>

  {showform && (
        <Box sx={{ mt: 4 }}>
          <Paper elevation={3} sx={{ borderRadius: 4, p: 3 }}>
            <Typography
            variant="h6"
            sx={{
                fontSize: { xs: "20px", md: "22px" },
                fontWeight: 400,
                mb: 2,
                textAlign: "left",
            }}
            >
            Share Details
            </Typography>
            <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
              Policy Date *
            </Typography>
            <TextField fullWidth 
             type='date'
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
             Last Inst. Paid *
            </Typography>
            <TextField fullWidth 
             type='text'
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
       </Grid>
       <Grid container spacing={2} mt={1}>
       <Grid item xs={12} md={6}>
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
         Maturity Date *
            </Typography>
            <TextField fullWidth 
             type='date'
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
            Due Date *
            </Typography>
            <TextField fullWidth 
             type='date'
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
       </Grid>
       <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
            Member Code*
            </Typography>
            <TextField fullWidth 
            sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
              Late Fine *
            </Typography>
            <TextField fullWidth 
            sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
       </Grid>
       {/* 4th */}
       <Grid container spacing={2} mt={1}>
       <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
           Applicant Name*
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
            No. Installment Paid *
            </Typography>
            <TextField fullWidth 
            type='text'
            sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
       </Grid>
       {/* 5th */}
       <Grid container spacing={2} mt={1}>
         <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
            Mobile No*
            </Typography>
            <TextField fullWidth 
            type='number'
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
             Payment By *
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="sms-interval-label">Select </InputLabel>
              <Select
                labelId="sms-interval-label"
             
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "10px",
                  height: { xs: "40px", md: "48px" },
                 "& .MuiOutlinedInput-input": {
                  padding: "10px", 
                   height: "100%",
                   },
                }}
              >
               <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Cash">Cash</MenuItem>
              <MenuItem value="Credit Card">Credit Card</MenuItem>
              <MenuItem value="Debit Card">Debit Card</MenuItem>
              <MenuItem value="Net Banking">Net Banking</MenuItem>
              </Select>
            </FormControl>
          </Grid>
       </Grid>
      {/* 6th */}
      <Grid container spacing={2} mt={1}>
         <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
           Policy Amount*
            </Typography>
            <TextField fullWidth 
            sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
            Advisor/Collector*
            </Typography>
            <TextField fullWidth 
            sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
       </Grid>
      {/* 7th */}
      <Grid container spacing={2} mt={1}>
         <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
            Scheme Name*
            </Typography>
            <TextField fullWidth 
            sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
             Name *
            </Typography>
            <TextField fullWidth 
            sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
       </Grid>
            {/* 8th */}
            <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={3}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
           Scheme Term*
            </Typography>
            <TextField fullWidth 
            type='text'
            sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={3}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
             Mode
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
              Remark
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
       </Grid>
             {/* 9th */}
      <Grid container spacing={2} mt={1}>
         <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
           Maturity Amt.*
            </Typography>
            <TextField fullWidth 
            type='number'
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
             Net Deposited*
            </Typography>
            <TextField fullWidth 
            type='number'
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
       </Grid>
         {/* 10th */}
        <Grid container spacing={2} mt={1}>
         <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
         Amount Due*
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
             SMS Status*
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
         </Grid>
        {/* 11th */}
        <Grid container spacing={2} mt={1}>
         <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
         Advisor Code*
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>

         </Grid>
         {/* 12th */}

               {/* 15th */}

         <Grid container spacing={2} mt={1}>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            container
            direction="column"
            alignItems="center"
          >
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
              Applicant Photo
            </Typography>
            <ImageUploadWrapper>
              <PersonIcon style={{ fontSize: 100, color: "#BDBDBD" }} />
            </ImageUploadWrapper>
            <UploadButton variant="contained" component="label">
              Choose File
              <input type="file" hidden />
            </UploadButton>
            <Caption>Photo Size: Maximum 10MB</Caption>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            container
            direction="column"
            alignItems="c"
          >
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
              Signature
            </Typography>
            <ImageUploadWrapper>
              {/* Removed PersonIcon for Signature */}
            </ImageUploadWrapper>
            <UploadButton variant="contained" component="label">
              Choose File
              <input type="file" hidden />
            </UploadButton>
            <Caption>Photo Size: Maximum 10MB</Caption>
          </Grid>
        </Grid>
        
        {/* button */}
        <Grid container spacing={2} mt={2} 
         sx={{
             display: "flex",
             justifyContent: "space-between",
             alignItems: "center",
             gap: "10px",
             marginBottom: "20px",
         }}
        >
         <Button 
          sx={{
              backgroundColor: '#F37D01',
               color: '#000',
              borderRadius: '10px',
              marginRight: { xs: '0', md: '10px' },
              width: { xs: '100%', md: '247px' },
              marginBottom: { xs: '10px', sm: '0' },
            }}
         >
           View Installment
         </Button>
         <Grid>
          <Button
           sx={{
              backgroundColor: '#FFD700',
               color: '#000',
              borderRadius: '10px',
              marginRight: { xs: '0', md: '10px' },
              width: { xs: '100%', md: '247px' },
              marginBottom: { xs: '10px', sm: '0' },
            }}
          >Save</Button>
          <Button
           sx={{
              backgroundColor: '#05ACD6',
               color: '#000',
              borderRadius: '10px',
              marginRight: { xs: '0', md: '10px' },
              width: { xs: '100%', md: '247px' },
              marginBottom: { xs: '10px', sm: '0' },
            }}
          >Print</Button>
         </Grid>

        </Grid>
        </Paper>
        </Box>
      )}
 </Box>

  )
}

export default DailyRenewalPayment


