import React, { useRef } from "react";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";

function DNOGenerate() {
  const rows = [
    {
      slno: 1,
      memberCode: "VL000011",
      memberName: "ANITA RAM RAMDHANI",
      shareDate: "12/01/2024",
      dnoFrom: 1,
      dnoTo: 1,
      noOfShare: 1,
      shareAmt: 10,
      txnId: "T102984",
    },
    {
      slno: 2,
      memberCode: "VL00014",
      memberName: "ABHIJEET DNYANESHWAR BOCHARE",
      shareDate: "15/01/2024",
      dnoFrom: 2,
      dnoTo: 11,
      noOfShare: 10,
      shareAmt: 100,
      txnId: "T102997",
    },
    {
      slno: 3,
      memberCode: "VL00015",
      memberName: "MOHANLAL HIRARAM CHOUDHARY",
      shareDate: "27/01/2024",
      dnoFrom: 12,
      dnoTo: 21,
      noOfShare: 10,
      shareAmt: 100,
      txnId: "T103000",
    },
  ];

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        DNO Generate
      </Typography>
      <Paper
        sx={{
          width: "100%",
          p: 2,
          borderRadius: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
          }}
        >
          Search Details
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "flex", flexDirection: "column", mr: 2 }}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                mb: 1,
                mt: 2,
                fontFamily: "Poppins, sans-serif",
                fontSize: "14px",
              }}
            >
              Select Director/Promoter :
            </Typography>
            <TextField
              select
              sx={{
                width: { xs: "100%", md: "495px" },
                height: "48px",
                borderRadius: "10px",
                borderColor: "#D9D9D9CC",
                backgroundColor: "#FFFFFF",
                "& .MuiInputBase-root": {
                  height: "48px",
                },
              }}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Box>

          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              fontFamily: "Poppins",
              background: "#DFBF41",
              width: { xs: "50%", md: "157px" },
              height: "48px",
              borderRadius: "10px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#B89B3E" },
              mt: 5,
            }}
          >
            Search
          </Button>
        </Box>
      </Paper>

      <Paper
        sx={{
          width: "100%",
          p: 2,
          borderRadius: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          mt: 3,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
          }}
        >
          Share Data List
        </Typography>

        <TableContainer component={Paper} sx={{borderRadius: "20px 20px 0px 0px", mt: 2}}>
          <Table>
            <TableHead sx={{background: "#413AF1"}}>
              <TableRow>
                <TableCell sx={{color: "#fff"}}>SlNo</TableCell>
                <TableCell sx={{color: "#fff"}}>M.CODE</TableCell>
                <TableCell sx={{color: "#fff"}}>M.NAME</TableCell>
                <TableCell sx={{color: "#fff"}}>Share Date</TableCell>
                <TableCell sx={{color: "#fff"}}>DNO From</TableCell>
                <TableCell sx={{color: "#fff"}}>DNO To</TableCell>
                <TableCell sx={{color: "#fff"}}>NoOfShare</TableCell>
                <TableCell sx={{color: "#fff"}}>Share Amt</TableCell>
                <TableCell sx={{color: "#fff"}}>TxnID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.slno}>
                  <TableCell>{row.slno}</TableCell>
                  <TableCell>{row.memberCode}</TableCell>
                  <TableCell>{row.memberName}</TableCell>
                  <TableCell>{row.shareDate}</TableCell>
                  <TableCell>{row.dnoFrom}</TableCell>
                  <TableCell>{row.dnoTo}</TableCell>
                  <TableCell>{row.noOfShare}</TableCell>
                  <TableCell>{row.shareAmt}</TableCell>
                  <TableCell>{row.txnId}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

export default DNOGenerate;
