import { Box, Typography,Paper,Button,MenuItem,Grid,TextField } from '@mui/material'
import React from 'react'

const MoRegularEMIApproval = () => {
  const textFieldStyle = {
    width: { xs: "100%", md: "100%" },
    height: "48px",
    borderRadius: "10px",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: "48px",
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
    opacity: 0.6,
  };
  return (
    <Box>
     <Typography 
      sx={{
         fontSize: { xs: "20px", md: "24px" },
          fontWeight: 600,
          lineHeight: "36px",
          fontFamily: "Poppins",
          mb: 1,
      }}
     >
     Mobile Renewal Approval
     </Typography>
     
      <Paper sx={{ padding: "2%", marginTop: "1%", borderRadius: "20px" }}>
        {/* Search Box */}
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
          }}
        >
          Search Box
        </Typography>

        <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              User Type
            </Typography>
            <TextField select sx={textFieldStyle}>
              <MenuItem value='policy1'>Policy 1</MenuItem>
              <MenuItem value='policy1'>Policy 2</MenuItem>
              <MenuItem value='policy1'>Policy 3</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              Search By Name
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              sx={textFieldStyle}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              User Type
            </Typography>
            <TextField select sx={textFieldStyle}>
              <MenuItem value='policy1'>Policy 1</MenuItem>
              <MenuItem value='policy1'>Policy 2</MenuItem>
              <MenuItem value='policy1'>Policy 3</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              Search By Name
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              sx={textFieldStyle}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              textAlign: { xs: "center", sm: "right" },
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'flex-end',
              flexWrap: 'wrap',
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#FFD700',
                color: '#FFFFFF',
                borderRadius: '10px',
                marginRight: { xs: '0', md: '10px' },
                width: { xs: '100%', md: '150px' },
                marginBottom: { xs: '10px', sm: '0' },
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Paper>

    </Box>
  )
}

export default MoRegularEMIApproval

