import React, { useState } from 'react';
import vllogo from "../../Assets/logo.png";
import { Box, Typography, Paper,Divider, Grid, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const TrialBalance = () => {
  const [showStatement, setShowStatement] = useState(false); 

  const textFieldStyle = {
    width: { xs: '100%', md: '100%' },
    height: '48px',
    borderRadius: '10px',
    borderColor: '#D9D9D9CC',
    backgroundColor: '#FFFFFF',
    '& .MuiInputBase-root': {
      height: '48px',
    },
  };

  const fontStyle = {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '14px',
    opacity: 0.6,
  };

  const handleViewStatement = () => {
    setShowStatement(true);
  };

  return (
    <Box>
      <Typography
        sx={{
          fontSize: { xs: '20px', md: '24px' },
          fontWeight: 600,
          lineHeight: '36px',
          fontFamily: 'Poppins',
          mb: 1,
        }}
      >
       Trial Balance
      </Typography>

      {/* Search Information */}
      <Paper
        sx={{
          width: '100%',
          p: 2,
          borderRadius: '20px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '20px',
            lineHeight: '36px',
            fontWeight: 500,
            mb: 1,
          }}
        >
          Search information
        </Typography>

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ width: '100%', fontSize: { xs: '16px', md: '18px' }, marginBottom: '8px', ...fontStyle }}>
              Select AC No
            </Typography>
            <TextField
              select
              fullWidth
              variant="outlined"
              sx={textFieldStyle}
              InputProps={{ style: { borderRadius: '10px' } }}
            >
              {/* Options for account number */}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ width: '100%', fontSize: { xs: '16px', md: '18px' }, marginBottom: '8px', ...fontStyle }}>
              From Date:
            </Typography>
            <TextField fullWidth variant="outlined" type="date" sx={textFieldStyle} InputProps={{ style: { borderRadius: '10px' } }} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ width: '100%', fontSize: { xs: '16px', md: '18px' }, marginBottom: '8px', ...fontStyle }}>
              To Date:
            </Typography>
            <TextField fullWidth variant="outlined" type="date" sx={textFieldStyle} InputProps={{ style: { borderRadius: '10px' } }} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: 'flex', justifyContent: 'center', height: '48px', mt: 3 }}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  textTransform: 'none',
                  fontFamily: 'Poppins',
                  background: '#DFBF41',
                  width: '166px',
                  height: '48px',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                  '&:hover': { backgroundColor: '#B89B3E' },
                }}
                onClick={handleViewStatement}
              >
                View Statement
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* Bank Statement Table */}
      {showStatement && (
        <Paper sx={{ px: 6, py: 3, mt: 2, borderRadius: "20px" }}>
          {/* Header Section */}
          <Box sx={{ marginBottom: 1 }}>
            <Grid container alignItems="center">
              <Grid
                item
                xs={2}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Box sx={{ mr: 2 }}>
                  <img
                    src={vllogo}
                    alt="vllogo"
                    style={{ height: "80px", width: "80px" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: "16px", md: "20px" },
                    mb: 1,
                    fontFamily: "Poppins",
                  }}
                >
                  VENKETLAXMI URBAN PUNE NIDHI LTD
                </Typography>
                <Typography sx={{ fontFamily: "Poppins" }}>
                  Reg. Office: SECOND FLOOR, GOLDEN EMPIRE, BESIDE CROMA, BANER
                  ROAD, BANER - MAHARASHTRA - 411045
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider sx={{ mt: 3, mb: 3, borderBottomWidth: 2 }} />
          
          <Box sx={{ textAlign: 'center' }}>
          <Typography 
            sx={{ 
              fontFamily: 'Poppins', 
              fontSize: '20px', 
              lineHeight: '36px', 
              fontWeight: 500, 
              mb: 2 
            }}
          >
            Trail Balance
          </Typography>
          <Divider 
            sx={{ 
              width: 'fit-content', 
              mx: 'auto', 
              borderBottomWidth: 2, 
              background: "black" 
            }} 
          />
          </Box>
          <TableContainer>
            <Table>
              <TableHead sx={{backgroundColor:'#D1D1D1',borderRadius: '20px',}}>
                <TableRow sx={{backgroundColor:'#D1D1D1',}}>
                  <TableCell sx={{ fontWeight: 'bold' }}>Ledger Name</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Opening</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Debit</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Credit</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Closing</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Asset</TableCell>
                  <TableCell>0</TableCell>
                  <TableCell>1020.00</TableCell>
                  <TableCell>0</TableCell>
                  <TableCell>1020</TableCell>
                </TableRow>
                {/* More rows can be added here */}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </Box>
  );
};

export default TrialBalance;
