import React from 'react'
import {
  Box, Grid, Paper, Typography, Button, TextField, 
  Card, FormControl, MenuItem, InputLabel, Select, 
  TableContainer, Table, TableHead, TableRow, TableCell, 
  TableBody, IconButton,Container
} from "@mui/material";

const textFieldStyle = {
  // width: { xs: "290px", lg: "578px" },
  height: "48px",
  borderRadius: "80%",
  borderColor: "#D9D9D9CC",
  backgroundColor: "#FFFFFF",
  "& .MuiInputBase-root": {
    height: "48px",
  },
};

const fontStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "14px",
  opacity: 0.6,
};

const CountraEntry = () => {
  return (
    <Box>
       <Typography
           sx={{
          fontSize:{sx:'20px',md:'24px'},
          fontWeight: 600,
         lineHeight: "36px",
         textAlign: "start",
          }}
       >
         Contra Voucher
       </Typography> 
       <Paper 
      sx={{
          width: "100%",
          p: 2,
          borderRadius: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
       >
       <Typography
        sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
       >
       Search Box
      </Typography>
     
     <Grid container spacing={2} alignItems="center">
     <Grid item xs={12} sm={6} md={3}>
         <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              Select A/C No.
            </Typography>
            <TextField
           select
              fullWidth
              variant="outlined"
              sx={textFieldStyle}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            >
                <MenuItem>
                <MenuItem value={10}>Policy 1</MenuItem>
                <MenuItem value={20}>Policy 2</MenuItem>
                <MenuItem value={30}>Policy 3</MenuItem>
                </MenuItem>
            </TextField>
         </Grid>
               {/* From Date */}
        <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              From Date
            </Typography>
            <TextField
             select
              fullWidth
              variant="outlined"
              sx={textFieldStyle}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
        </Grid>

          {/* To Date */}
         <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              To Date
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              type="date"           
              sx={textFieldStyle}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
        </Grid>
      <Grid  item xs={12} sm={6} md={3}>
      <Box 
      sx={{
        display:'flex',
        justifyContent:'center',
        height:'48px',
        mt:3,
      }}
    >
    <Button
    variant="contained"
    fullWidth
    sx={{
      background:'#DFBF41',
      width:'166px',
      height:'48px',
      borderRadius:'10px',
      fontWeight: "bold",
       "&:hover": { backgroundColor: "#B89B3E" },
    }}
    >
      Search
    </Button>
   </Box>       
</Grid>
</Grid>
      </Paper>

      {/* Second Paper */}
      <Paper sx={{ marginTop: "2%",borderRadius: "16px" }}>
      <Box
      sx={{
        width: '100%',
        maxWidth: '95%', // Maximum width for large screens
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '15px',
        borderRadius: '100px',        
        margin: 'auto',
      }}
    >
      <h2>Entry Details</h2>
      <Grid container spacing={2}>
        <Grid container spacing={2} mt={1}>
         <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
         Select Branch*
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="sms-interval-label">Select </InputLabel>
              <Select
                labelId="sms-interval-label"
             
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "10px",
                  height: { xs: "40px", md: "48px" },
                 "& .MuiOutlinedInput-input": {
                  padding: "10px", 
                   height: "100%",
                   },
                }}
              >
              <MenuItem value="MAIN OFFICE">MAIN OFFICE</MenuItem>
              <MenuItem value="BANER OFFICE">BANER OFFICE</MenuItem>
              <MenuItem value="BHAVDHAN OFFICE">BHAVDHAN OFFICE</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
            select Dr. Ledger*
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="sms-interval-label">Select </InputLabel>
              <Select
                labelId="sms-interval-label"
             
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "10px",
                  height: { xs: "40px", md: "48px" },
                 "& .MuiOutlinedInput-input": {
                  padding: "10px", 
                   height: "100%",
                   },
                }}
              >
              <MenuItem value="MAIN OFFICE">MAIN OFFICE</MenuItem>
              <MenuItem value="BANER OFFICE">BANER OFFICE</MenuItem>
              <MenuItem value="BHAVDHAN OFFICE">BHAVDHAN OFFICE</MenuItem>
              </Select>
            </FormControl>
          </Grid>
         </Grid>
         <Grid container spacing={2} mt={1}>
         <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
          Receipt No.(Auto)*
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
             Amount*
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
            Txn Date*
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
           Remaks (if any)
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
         </Grid>   
         <Grid container spacing={2} mt={1}>
         <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
            Select Cr. Ledger*
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="sms-interval-label">Select </InputLabel>
              <Select
                labelId="sms-interval-label"
             
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "10px",
                  height: { xs: "40px", md: "48px" },
                 "& .MuiOutlinedInput-input": {
                  padding: "10px", 
                   height: "100%",
                   },
                }}
              >
              <MenuItem value="MAIN OFFICE">MAIN OFFICE</MenuItem>
              <MenuItem value="BANER OFFICE">BANER OFFICE</MenuItem>
              <MenuItem value="BHAVDHAN OFFICE">BHAVDHAN OFFICE</MenuItem>
              </Select>
            </FormControl>
          </Grid>

         </Grid>     
        <Grid
          item
          xs={12}
          sx={{textAlign: { xs: "center", sm: "right" },  
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'flex-end',  
                flexWrap: 'wrap',}}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#FFD700',
               color: '#FFFFFF',
              borderRadius: '10px',
              marginRight: { xs: '0', md: '10px' },
              width: { xs: '100%', md: '150px' },
              marginBottom: { xs: '10px', sm: '0' },
             
            }}
          >
            Save
          </Button>
        </Grid>
       </Grid>
       </Box>
      </Paper>
   </Box>
  )
}

export default CountraEntry
