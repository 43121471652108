import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import PersonIcon from "@mui/icons-material/Person";

function MemberSummary() {
  const [showStepper, setShowStepper] = useState(false);
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token") || "");

  useEffect(() => {
    // Fetch members data from the API
    axios
      // .get("http://localhost:9191/api/admin/getmembers", {
      .get("https://api.vlnidhi.com/api/admin/getmembers", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setMembers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching members data:", error);
      });
  }, [token]);

  const handleMemberChange = (event) => {
    const memberCode = event.target.value;
    const member = members.find((m) => m.memberCode === memberCode);
    setSelectedMember(member);
  };

  const handleSearch = () => {
    setShowStepper(true);
  };

  const textFieldStyle = {
    width: { xs: "290px", lg: "578px" },
    height: "48px",
    borderRadius: "80%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: "48px",
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
    // opacity: 0.6,
  };

  const ImageUploadWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid #E0E0E0",
    borderRadius: "8px",
    width: "200px",
    height: "200px",
    backgroundColor: "#F9F9F9",
    alignSelf: "flex-start",
  }));

  const UploadButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
    marginTop: theme.spacing(2),
    backgroundColor: "#E0E0E0",
    alignSelf: "flex-start",
  }));

  const Caption = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(1),
    fontSize: "12px",
    color: "#BDBDBD",
    alignSelf: "flex-start",
  }));

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Member Summary
      </Typography>
      <Paper
        sx={{
          width: "100%",
          p: 4,
          borderRadius: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "36px",
              mb: 0,
              mt: 0,
            }}
          >
            Search Details
          </Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={10} sm={12}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  marginBottom: "8px",
                  ...fontStyle,
                }}
              >
                Select By Code<span style={{color: "red"}}>*</span>
              </Typography>
              <TextField
                select
                name="selectByCode"
                value={selectedMember?.memberCode || ""}
                onChange={handleMemberChange}
                sx={{
                  ...textFieldStyle,
                  width: "100%",
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              >
                {members.map((member) => (
                  <MenuItem key={member.id} value={member.memberCode}>
                    {member.memberName} ({member.memberCode})
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 3,
              gap: "2%",
            }}
          >
            <Button
              variant="contained"
              onClick={handleSearch}
              sx={{
                textTransform: "none",
                fontFamily: "Poppins",
                background: "#3029D9",
                width: "157px",
                height: "48px",
                borderRadius: "10px",
                fontWeight: "bold",
              }}
            >
              Search
            </Button>
          </Box>
        </Box>
      </Paper>

      {showStepper && selectedMember && (
        <Paper
          sx={{
            width: "100%",
            p: 4,
            mt: 4,
            borderRadius: "20px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "36px",
                mb: 1,
                mt: 2,
              }}
            >
              Member Details
            </Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    marginBottom: "8px",
                    ...fontStyle,
                  }}
                >
                  Member Code
                </Typography>
                <TextField
                  fullWidth
                  disabled
                  name="memberCode"
                  value={selectedMember.memberCode}
                  sx={{
                    ...textFieldStyle,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    marginBottom: "8px",
                    ...fontStyle,
                  }}
                >
                  Member Name
                </Typography>
                <TextField
                  fullWidth
                  disabled
                  name="memberName"
                  value={selectedMember.memberName}
                  sx={{
                    ...textFieldStyle,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    marginBottom: "8px",
                    ...fontStyle,
                  }}
                >
                  Mobile Number
                </Typography>
                <TextField
                  fullWidth
                  disabled
                  name="mobileNo"
                  value={selectedMember.mobileNo}
                  sx={{
                    ...textFieldStyle,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    marginBottom: "8px",
                    ...fontStyle,
                  }}
                >
                  Registration Date
                </Typography>
                <TextField
                  fullWidth
                  disabled
                  name="registrationDate"
                  value={selectedMember.registrationDate}
                  type="date"
                  sx={{
                    ...textFieldStyle,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    marginBottom: "8px",
                    ...fontStyle,
                  }}
                >
                  Sponsor Name
                </Typography>
                <TextField
                  fullWidth
                  disabled
                  name="sponsorName"
                  value={selectedMember.sponsorName}
                  sx={{
                    ...textFieldStyle,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    marginBottom: "8px",
                    ...fontStyle,
                  }}
                >
                  Address
                </Typography>
                <TextField
                  fullWidth
                  disabled
                  name="address"
                  value={selectedMember.address}
                  sx={{
                    ...textFieldStyle,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    marginBottom: "8px",
                    ...fontStyle,
                  }}
                >
                  Pincode
                </Typography>
                <TextField
                  fullWidth
                  disabled
                  name="pincode"
                  value={selectedMember.pincode}
                  sx={{
                    ...textFieldStyle,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    marginBottom: "8px",
                    ...fontStyle,
                  }}
                >
                  State
                </Typography>
                <TextField
                  fullWidth
                  disabled
                  name="state"
                  value={selectedMember.state}
                  sx={{
                    ...textFieldStyle,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    marginBottom: "8px",
                    ...fontStyle,
                  }}
                >
                  Aadhar Number
                </Typography>
                <TextField
                  fullWidth
                  disabled
                  name="aadharNo"
                  value={selectedMember.aadharNo}
                  sx={{
                    ...textFieldStyle,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    marginBottom: "8px",
                    ...fontStyle,
                  }}
                >
                  PAN Number
                </Typography>
                <TextField
                  fullWidth
                  disabled
                  name="panNo"
                  value={selectedMember.panNo}
                  sx={{
                    ...textFieldStyle,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    marginBottom: "8px",
                    ...fontStyle,
                  }}
                >
                  Nominee Name
                </Typography>
                <TextField
                  fullWidth
                  disabled
                  name="nomineeName"
                  value={selectedMember.nomineeName}
                  sx={{
                    ...textFieldStyle,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    marginBottom: "8px",
                    ...fontStyle,
                  }}
                >
                  Email ID
                </Typography>
                <TextField
                  fullWidth
                  disabled
                  name="emailId"
                  value={selectedMember.emailId}
                  sx={{
                    ...textFieldStyle,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    marginBottom: "8px",
                    ...fontStyle,
                  }}
                >
                  DOB
                </Typography>
                <TextField
                  fullWidth
                  disabled
                  name="dob"
                  value={selectedMember.dob}
                  type="date"
                  sx={{
                    ...textFieldStyle,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    marginBottom: "8px",
                    ...fontStyle,
                  }}
                >
                  Age
                </Typography>
                <TextField
                  fullWidth
                  disabled
                  name="age"
                  value={selectedMember.age}
                  sx={{
                    ...textFieldStyle,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    marginBottom: "8px",
                    ...fontStyle,
                  }}
                >
                  Branch Code
                </Typography>
                <TextField
                  fullWidth
                  disabled
                  name="branchCode"
                  value={selectedMember.branch.branchCode}
                  sx={{
                    ...textFieldStyle,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    marginBottom: "8px",
                    ...fontStyle,
                  }}
                >
                  Gender
                </Typography>
                <TextField
                  fullWidth
                  disabled
                  name="gender"
                  value={selectedMember.gender}
                  sx={{
                    ...textFieldStyle,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                  <Grid direction="column">
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Applicant Photo
                    </Typography>

                    <ImageUploadWrapper>
                      <PersonIcon style={{ fontSize: 100, color: "#BDBDBD" }} />
                    </ImageUploadWrapper>

                    <UploadButton variant="contained" component="label">
                      Choose File
                      <input type="file" hidden />
                    </UploadButton>
                    <Caption>Photo Size: Maximum 10MB</Caption>
                  </Grid>
                  <Grid direction="column">
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Signature
                    </Typography>

                    <ImageUploadWrapper>
                      {/* <PersonIcon
                          style={{ fontSize: 100, color: "#BDBDBD" }}
                        /> */}
                    </ImageUploadWrapper>

                    <UploadButton variant="contained" component="label">
                      Choose File
                      <input type="file" hidden />
                    </UploadButton>
                    <Caption>Photo Size: Maximum 10MB</Caption>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      )}
    </Box>
  );
}

export default MemberSummary;
