import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Box,
  Button,
  MenuItem,
  Grid,
  Select,
  FormControl,
  InputLabel,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  TableHead,
  Checkbox,
  TextField,
  TableContainer,
  Divider,
  IconButton,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import vllogo from "../../Assets/logo.png";
import { useReactToPrint } from "react-to-print";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const InterestPayable = () => {
  const [branch, setBranch] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [frontPageData, setFrontPageData] = useState(null);
  const [showFrontPage, setShowFrontPage] = useState(false);
  const [showTransactionPage, setShowTransactionPage] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const token = localStorage.getItem("token");
  const contentRef = useRef(null); // Ref for the content to be downloaded

  useEffect(() => {
    // Fetch all accounts
    axios
      .get("https://api.vlnidhi.com/api/admin/saving-accounts/getAll", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAccounts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching account data:", error);
      });
  }, [token]);

  const handleBranchChange = (event) => {
    setBranch(event.target.value);
    setShowTable(false);
    setShowFrontPage(false);
    setShowTransactionPage(false);
  };

  const handleFrontPageClick = () => {
    if (branch) {
      fetchFrontPageData(branch);
      setShowFrontPage(true);
      setShowTransactionPage(false);
      setShowTable(false);
    }
  };

  const fetchFrontPageData = (accountNo) => {
    axios
      .get(
        `https://api.vlnidhi.com/api/admin/saving-accounts/getpassbookfront/${accountNo}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setFrontPageData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching front page data:", error);
      });
  };

  const handleDownload = async () => {
    const content = contentRef.current;
    const canvas = await html2canvas(content);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    const imgWidth = 210; // A4 width in mm
    const pageHeight = 295; // A4 height in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    pdf.save("policy-interest-payable.pdf");
  };

  return (
    <Box>
      <Typography
        sx={{
          fontSize: { xs: "20px", md: "24px" },
          fontWeight: 600,
          lineHeight: "36px",
          fontFamily: "Poppins",
          mb: 1,
        }}
      >
        Interest Payable
      </Typography>

      {/* Form Paper */}
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          "@media (max-width: 600px)": {
            padding: "10px",
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Search Box
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            gap: { xs: 1, sm: 0.5 },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", width: "50%" }}>
            <Box>
              <Typography
                sx={{
                  // width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "14px",
                  // opacity: 0.6,
                  mb: 1,
                }}
              >
                Select by Policy No.<span style={{ color: "red" }}>*</span>
              </Typography>
            </Box>
            <Box>
              <FormControl
                sx={{
                  width: { xs: "100%", sm: "100%", md: "100%" },
                  flex: 1,
                }}
              >
                <TextField
                  select
                  value={branch}
                  onChange={handleBranchChange}
                  sx={{
                    width: { xs: "125px", md: "100%" },
                    height: "48px",
                    borderRadius: "10px",
                    borderColor: "#D9D9D9CC",
                    backgroundColor: "#FFFFFF",
                    "& .MuiInputBase-root": {
                      height: "48px",
                    },
                  }}
                  InputProps={{
                    style: { borderRadius: "10px" },
                  }}
                >
                  {accounts.map((account) => (
                    <MenuItem
                      key={account.savingAccountNumber}
                      value={account.savingAccountNumber}
                    >
                      {`${account.member.memberName} (${account.savingAccountNumber})`}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Box>
          </Box>

          {/* Buttons */}
          <Box
            sx={{
              display: "flex",
              marginLeft: { xs: 0, sm: "4%" },
              flexDirection: { xs: "column", sm: "row" },
              gap: 2,
              flex: 1,
              mt: { xs: 1, md: 3 },
              mb: { xs: 2, md: 0 },
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#DFBF41",
                ":hover": { background: "#9d893a" },
                color: "white",
                borderRadius: "10px",
                width: { md: "106px", xs: "100%" },
                textTransform: "none",
              }}
              onClick={handleFrontPageClick}
            >
              Search
            </Button>
          </Box>
        </Box>
      </Paper>

      {/* Front Page Paper */}
      {showFrontPage && frontPageData && (
        <Box sx={{ mt: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mb: 2,
            }}
          >
            <Button
              variant="contained"
              endIcon={<DownloadIcon />}
              sx={{
                width: "200px",
                borderRadius: "20px",
                textTransform: "none",
                background: "#D1D1D1",
                color: "black",
                ":hover": { background: "#c7c0c0" },
              }}
              onClick={handleDownload}
            >
              Download
            </Button>
          </Box>
          <Paper
            ref={contentRef}
            sx={{ px: 6, py: 3, mt: 0, borderRadius: "20px" }}
          >
            {/* Header Section */}
            <Box sx={{ marginBottom: 4 }}>
              <Grid container alignItems="center">
                <Grid
                  item
                  xs={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Box sx={{ mr: 2 }}>
                    <img
                      src={vllogo}
                      alt="vllogo"
                      style={{ height: "80px", width: "80px" }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: "16px", md: "20px" },
                      mb: 1,
                      fontFamily: "Poppins",
                    }}
                  >
                    VENKETLAXMI URBAN PUNE NIDHI LTD
                  </Typography>
                  <Typography sx={{ fontFamily: "Poppins" }}>
                    Reg. Office: SECOND FLOOR, GOLDEN EMPIRE, BESIDE CROMA,
                    BANER ROAD, BANER - MAHARASHTRA - 411045
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Divider sx={{ mt: 2, mb: 2 }} />

            {/* Policy Statement Section */}

            <Box>
              <Typography
                sx={{
                  textAlign: "center",
                  textDecoration: "underline",
                  fontWeight: "bold",
                  fontSize: { xs: "16px", md: "20px" },
                  mb: 2,
                  fontFamily: "Poppins",
                }}
              >
                POLICY INTEREST PAYABLE
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid container sx={{ mb: 1 }}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          opacity: 0.6,
                        }}
                      >
                        Policy No :
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ fontFamily: "Poppins", fontSize: "14px" }}
                      >
                        001VLRDN224777000001
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container sx={{ mb: 1 }}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          opacity: 0.6,
                        }}
                      >
                        Applicant Name :
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ fontFamily: "Poppins", fontSize: "14px" }}
                      >
                        SHEKHAR GANESH SAWARKAR
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Grid container sx={{ mb: 1 }}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          opacity: 0.6,
                        }}
                      >
                        Plan :
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ fontFamily: "Poppins", fontSize: "14px" }}
                      >
                        VENKET LAXMI BUND
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container sx={{ mb: 1 }}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          opacity: 0.6,
                        }}
                      >
                        ROI :
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ fontFamily: "Poppins", fontSize: "14px" }}
                      >
                        9
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            {/* Policy Interest Statement Section */}
            <Box sx={{ marginTop: 4 }}>
              <TableContainer
                component={Paper}
                sx={{ borderRadius: "20px 20px 0px 0px" }}
              >
                <Table>
                  <TableHead sx={{ background: "#D1D1D1" }}>
                    <TableRow>
                      <TableCell>
                        <strong>Entry Date</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Date Till</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Duration</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Amount</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Intrest</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Product</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ background: "#F2F6FA" }}>
                    <TableRow>
                      <TableCell>14/05/2024</TableCell>
                      <TableCell>19/09/2024</TableCell>
                      <TableCell>128</TableCell>
                      <TableCell>10,000.00</TableCell>
                      <TableCell>315.62</TableCell>
                      <TableCell>10,315.62</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>18/06/2024</TableCell>
                      <TableCell>19/09/2024</TableCell>
                      <TableCell>93</TableCell>
                      <TableCell>10,000.00</TableCell>
                      <TableCell>229.32</TableCell>
                      <TableCell>10,229.32</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Paper>
        </Box>
      )}
    </Box>
  );
};

export default InterestPayable;
