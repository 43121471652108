import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Switch,
  Paper,
  styled,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import PersonIcon from "@mui/icons-material/Person";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";

dayjs.extend(advancedFormat);

const steps = ["Step 1", "Step 2", "Step 3"];

const stepIconStyle = {
  "&.Mui-completed": {
    color: "green",
  },
  "&.Mui-active": {
    color: "#3029D9",
  },
  "&.Mui-disabled": {
    color: "gray",
  },
};

const textFieldStyle = {
  width: { xs: "100%", md: "100%" },
  height: "48px",
  borderRadius: "80%",
  borderColor: "#D9D9D9CC",
  backgroundColor: "#FFFFFF",
  "& .MuiInputBase-root": {
    height: "48px",
  },
};

const fontStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "14px",
  // opacity: 0.6,
};

// Validation schema
const validationSchema = Yup.object({
  verifyWith: Yup.string().required("Select verification method"),
  registrationDate: Yup.date()
    .required("Registration date is required")
    .test("is-today", "Registration date must be today", (value) =>
      dayjs(value).isSame(dayjs(), "day")
    ),
  memberNameTitle: Yup.string().required("Select title"),
  memberName: Yup.string()
    .required("Name is required")
    .transform((value) => (value ? value.toUpperCase() : "")),
  // .matches(/^[A-Z ]+$/, "Name must be in uppercase letters only"),
  sponsorName: Yup.string()
    .required("Sponsor name is required")
    .transform((value) => (value ? value.toUpperCase() : "")),
  // .matches(/^[A-Z ]+$/, "Name must be in uppercase letters only"),
  gender: Yup.string().required("Select gender"),
  dob: Yup.date().required("Date of Birth is required"),
  martialStatus: Yup.string().required("Select Martial Status"),
  address: Yup.string().required("Address is required"),
  district: Yup.string().required("District is required"),
  state: Yup.string().required("Select state"),
  pincode: Yup.number()
    .positive("Pin Code cannot be negative")
    // .matches(/^\d+$/, "Pin Code can only contain digits")
    .required("Pin Code is required"),
  branchName: Yup.string().required("Select Branch"),
  aadharNo: Yup.string()
    .matches(/^\d{12}$/, "Aadhar number must contain exactly 12 digits")
    .required("Aadhar Number is required"),
  panNo: Yup.string()
    .matches(
      /^[A-Z]{5}[0-9]{4}[A-Z]$/,
      "Use this format: 5 capital letters, 4 digits, and 1 capital letter (e.g., ABQMP1233P)"
    )
    .required("PAN Number is required"),
  voterNo: Yup.string()
    .matches(
      /^[A-Z]{3}[0-9]{7}$/,
      "Voter number must start with 3 capital letters followed by 7 digits (e.g., ABC1234567)"
    )
    .required("Voter Number is required"),
  mobileNo: Yup.string()
    .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits long")
    .required("Mobile Number is required"),
  emailId: Yup.string().matches(
    /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
    "Email ID must be lowercase letters and numbers only, and must have a valid domain"
  ),
  introMemCode: Yup.string().required("Intro. Member Code is required"),
  nomineeName: Yup.string()
    .required("Nominee Name is required")
    .transform((value) => (value ? value.toUpperCase() : "")),
  // .matches(/^[A-Z\s]+$/, "Nominee Name must contain only capital letters"),
  nomineeRelation: Yup.string().required("Select Nominee Relation"),

  memberfees: Yup.number()
    // .required("Member Fees is required")
    .min(0, "Member Fees cannot be negative"),
  // shareAllotedForm: Yup.string().required("Required"),
  promotorCode: Yup.string().required("Select Promotor"),
  noOfShare: Yup.number()
    .required("No. Of Share is required")
    .positive("No. Of Share must be a positive number")
    .integer("No. Of Share must be an integer"),
  shareAmount: Yup.number().required("Share Amount is required"),
  paymentBy: Yup.string().required("Select payment method"),
  memberStatus: Yup.boolean(), // Assuming it's a boolean for switch
});

const AddMember = () => {
  const [activeStep, setActiveStep] = useState(0);
  // const [shareAllotedOptions, setShareAllotedOptions] = useState([]);
  const [promotorOptions, setPromotorOptions] = useState([]); // Updated state name
  const [branchNameOptions, setBranchNameOptions] = useState([]);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const [formValues, setFormValues] = useState({
    verifyWith: "",
    registrationDate: "",
    memberNameTitle: "",
    memberName: "",
    sponsorName: "",
    gender: "",
    dob: "",
    age: "",
    martialStatus: "",
    address: "",
    district: "",
    state: "",
    pincode: "",
    branchName: "",
    aadharNo: "",
    panNo: "",
    voterNo: "",
    mobileNo: "",
    emailId: "",
    occupation: "",
    education: "",
    introMemCode: "",
    nomineeAge: "",
    photo: null,
    nomineeName: "",
    nomineeRelation: "",
    nomineemobileNo: "",
    noOfShare: "",
    shareAmount: "",
    remarks: "",
    memberfees: "0",
    memberStatus: false,
    promotor: false,
    paymentBy: "",
    // shareAllotedForm: "",
    promotorCode: "",
  });

  // formik
  const formik = useFormik({
    initialValues: {
      verifyWith: "",
      registrationDate: dayjs().format("YYYY-MM-DD"), // Default to current date
      memberNameTitle: "",
      memberName: "",
      sponsorName: "",
      gender: "",
      dob: "",
      age: "",
      martialStatus: "",
      address: "",
      district: "",
      state: "",
      pincode: "",
      branchName: "",
      aadharNo: "",
      panNo: "",
      voterNo: "",
      mobileNo: "",
      emailId: "",
      occupation: "",
      education: "",
      introMemCode: "",
      nomineeAge: "",
      photo: null,
      nomineeName: "",
      nomineeRelation: "",
      nomineemobileNo: "",
      noOfShare: "",
      shareAmount: "",
      remarks: "",
      memberfees: "0",
      memberStatus: false,
      promotor: false,
      paymentBy: "",
      // shareAllotedForm: "",
      promotorCode: "",
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      // Check for validation errors before saving
      formik.validateForm().then((errors) => {
        if (Object.keys(errors).length > 0) {
          // If there are errors, show the alert
          alert("Please fill in all required fields.");
        } else {
          // If no errors, proceed with form submission
          handleSubmit();
        }
      });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleDateChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  // Fetch branch names and  promotors for share allotted options
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");

      try {
        // Fetch branch names
        const branchResponse = await axios.get(
          "https://api.vlnidhi.com/api/admin/getallbid",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const formattedBranchNames = branchResponse.data.map((branch) => ({
          id: branch.id,
          value: branch.branchName,
          label: `${branch.branchName} - ${branch.branchCode}`,
        }));

        setBranchNameOptions(formattedBranchNames);

        // Fetch promotors for share allotted options
        const promotorsResponse = await axios.get(
          "https://api.vlnidhi.com/api/admin/promotors",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const promotors = promotorsResponse.data.filter(
          (option) => option.promotor === true
        );

        const formattedPromotors = promotors.map((option) => ({
          id: option.id,
          value: option.memberCode,
          label: option.memberName,
        }));
        // setShareAllotedOptions(formattedPromotors);
        setPromotorOptions(formattedPromotors);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error fetching data");
      }
    };

    fetchData();
  }, []);

  // Fetch pincode data and auto-fill district, state, country
  useEffect(() => {
    const fetchData = async () => {
      const { pincode } = formik.values;
      if (pincode.length === 6) {
        try {
          const response = await fetch(
            `https://api.postalpincode.in/pincode/${pincode}`
          );
          const result = await response.json();
          setData(result);
        } catch (error) {
          console.error("Error fetching pin data:", error);
          setError("Failed to fetch data. Please try again later.");
        }
      }
    };
    fetchData();
  }, [formik.values.pincode]);

  // Auto-fill district, state, country fields when data is available
  useEffect(() => {
    if (data && data[0]?.Status === "Success") {
      const postOffice = data[0].PostOffice[0];
      formik.setFieldValue("district", postOffice.District || "");
      formik.setFieldValue("state", postOffice.State || "");
      // formik.setFieldValue("country", postOffice.Country || "");
    }
  }, [data]);

  const handleSubmit = (values) => {
    const token = localStorage.getItem("token");

    // // Mapping shareAllotedForm to promotorCode
    // const submissionValues = {
    //   ...values,
    //   promotorCode: values.shareAllotedForm || "", // Map shareAllotedForm to promotorCode
    // };

    axios
      .post("https://api.vlnidhi.com/api/admin/addmember", values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const { memberCode } = response.data;

        Swal.fire({
          title: "Success!",
          text: `Member added successfully with Member Code: ${memberCode}`,
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          formik.resetForm();
        });
      })
      .catch((error) => {
        console.error("Error adding member:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          confirmButtonText: "OK",
        });
      });
  };

  const ImageUploadWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid #E0E0E0",
    borderRadius: "8px",
    width: "200px",
    height: "200px",
    backgroundColor: "#F9F9F9",
    alignSelf: "flex-start",
  }));

  const UploadButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
    marginTop: theme.spacing(2),
    backgroundColor: "#E0E0E0",
    alignSelf: "flex-start",
  }));

  const Caption = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(1),
    fontSize: "12px",
    color: "#BDBDBD",
    alignSelf: "flex-start",
  }));
  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
          }}
        >
          Add Member
        </Typography>

        <Paper
          sx={{
            width: "100%",
            p: 4,
            borderRadius: "20px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel
                  StepIconProps={{
                    sx: stepIconStyle,
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          <form onSubmit={formik.handleSubmit}>
            {/* ----------------------------------------------------------------------------------------------------------------------- */}
            {/* Step 1 details */}
            {activeStep === 0 && (
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "36px",
                    mb: 1,
                    mt: 2,
                  }}
                >
                  Member Details
                </Typography>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Verify With<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      select
                      name="verifyWith"
                      value={formik.values.verifyWith}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      sx={{
                        ...textFieldStyle,
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      error={
                        formik.touched.verifyWith &&
                        Boolean(formik.errors.verifyWith)
                      }
                      helperText={
                        formik.touched.verifyWith && formik.errors.verifyWith
                      }
                    >
                      <MenuItem value="WITHOUT_AADHAR">Without Aadhar</MenuItem>
                      <MenuItem value="WITH_AADHAR">With Aadhar</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Registration Date<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      // name="registrationDate"
                      // value={formValues.registrationDate}
                      // onChange={(event) =>
                      //   handleDateChange("registrationDate", event.target.value)
                      // }
                      // type="date"
                      name="registrationDate"
                      type="date"
                      value={formik.values.registrationDate}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                      error={
                        formik.touched.registrationDate &&
                        Boolean(formik.errors.registrationDate)
                      }
                      helperText={
                        formik.touched.registrationDate &&
                        formik.errors.registrationDate
                      }
                      sx={{
                        ...textFieldStyle,
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Member Name<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <TextField
                        select
                        name="memberNameTitle"
                        value={formik.values.memberNameTitle}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        sx={{
                          ...textFieldStyle,
                          // width: "101px",
                          width: { xs: "20%", md: "20%" },
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                        error={
                          formik.touched.memberNameTitle &&
                          Boolean(formik.errors.memberNameTitle)
                        }
                        helperText={
                          formik.touched.memberNameTitle &&
                          formik.errors.memberNameTitle
                        }
                      >
                        <MenuItem value="MR">Mr.</MenuItem>
                        <MenuItem value="MS">MS</MenuItem>
                        <MenuItem value="MRS">MRS</MenuItem>
                        <MenuItem value="Smt">Smt</MenuItem>
                        <MenuItem value="MD">MD</MenuItem>
                      </TextField>
                      <TextField
                        fullWidth
                        name="memberName"
                        value={formik.values.memberName.toUpperCase()}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "memberName",
                            e.target.value.toUpperCase()
                          )
                        }
                        onBlur={formik.handleBlur}
                        sx={{
                          ...textFieldStyle,
                          width: { xs: "80%", md: "80%" },
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                        error={
                          formik.touched.memberName &&
                          Boolean(formik.errors.memberName)
                        }
                        helperText={
                          formik.touched.memberName && formik.errors.memberName
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Sponsor Name<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      name="sponsorName"
                      value={formik.values.sponsorName.toUpperCase()}
                      onChange={(e) =>
                        formik.setFieldValue(
                          "sponsorName",
                          e.target.value.toUpperCase()
                        )
                      }
                      onBlur={formik.handleBlur}
                      sx={{
                        ...textFieldStyle,
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      error={
                        formik.touched.sponsorName &&
                        Boolean(formik.errors.sponsorName)
                      }
                      helperText={
                        formik.touched.sponsorName && formik.errors.sponsorName
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Gender<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      select
                      name="gender"
                      value={formik.values.gender}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      sx={{
                        ...textFieldStyle,
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      error={
                        formik.touched.gender && Boolean(formik.errors.gender)
                      }
                      helperText={formik.touched.gender && formik.errors.gender}
                    >
                      <MenuItem value="MALE">Male</MenuItem>
                      <MenuItem value="FEMALE">Female</MenuItem>
                      <MenuItem value="OTHER">Other</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        flexDirection: { xs: "column", md: "row" },
                      }}
                    >
                      <Box sx={{ width: { xs: "100%", md: "100%" } }}>
                        <Typography
                          sx={{
                            width: "100%",
                            fontSize: { xs: "16px", md: "18px" },
                            marginBottom: "8px",
                            ...fontStyle,
                          }}
                        >
                          DOB<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextField
                          fullWidth
                          type="date"
                          name="dob"
                          value={formik.values.dob || ""}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            formik.handleChange(e);
                            const today = new Date();
                            const birthDate = new Date(value);
                            let age =
                              today.getFullYear() - birthDate.getFullYear();
                            const monthDifference =
                              today.getMonth() - birthDate.getMonth();
                            if (
                              monthDifference < 0 ||
                              (monthDifference === 0 &&
                                today.getDate() < birthDate.getDate())
                            ) {
                              age--;
                            }
                            // Update the formValues with the calculated age
                            // setFormValues((prevState) => ({
                            //   ...prevState,
                            //   age: age,
                            // }));
                            formik.setFieldValue("age", age);
                          }}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.dob && Boolean(formik.errors.dob)
                          }
                          helperText={formik.touched.dob && formik.errors.dob}
                          sx={{
                            ...textFieldStyle,
                            width: "100%",
                          }}
                          InputProps={{
                            style: {
                              borderRadius: "10px",
                            },
                          }}
                        />
                      </Box>
                      <Box sx={{ width: { xs: "100%", md: "100%" } }}>
                        <Typography
                          sx={{
                            width: "100%",
                            fontSize: { xs: "16px", md: "18px" },
                            marginBottom: "8px",
                            ...fontStyle,
                          }}
                        >
                          Age (in years)
                        </Typography>
                        <TextField
                          fullWidth
                          name="age"
                          value={formik.values.age || ""}
                          disabled
                          sx={{
                            ...textFieldStyle,
                            width: "100%",
                          }}
                          InputProps={{
                            style: {
                              borderRadius: "10px",
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Marital Status<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      select
                      name="martialStatus"
                      value={formik.values.martialStatus}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.martialStatus &&
                        Boolean(formik.errors.martialStatus)
                      }
                      helperText={
                        formik.touched.martialStatus &&
                        formik.errors.martialStatus
                      }
                      sx={{
                        ...textFieldStyle,
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <MenuItem value="SINGLE">Single</MenuItem>
                      <MenuItem value="MARRIED">Married</MenuItem>
                      <MenuItem value="UNMARRIED">Unmarried</MenuItem>
                      <MenuItem value="DIVORCED">Divorced</MenuItem>
                      <MenuItem value="WIDOWED">Widowed</MenuItem>
                      <MenuItem value="SEPARATED">SEPARATED</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Address<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      name="address"
                      value={formik.values.address.toUpperCase()}
                      onChange={(e) =>
                        formik.setFieldValue(
                          "address",
                          e.target.value.toUpperCase()
                        )
                      }
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.address && Boolean(formik.errors.address)
                      }
                      helperText={
                        formik.touched.address && formik.errors.address
                      }
                      sx={{
                        ...textFieldStyle,
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Pin Code<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      name="pincode"
                      value={formik.values.pincode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.pincode && Boolean(formik.errors.pincode)
                      }
                      helperText={
                        formik.touched.pincode && formik.errors.pincode
                      }
                      sx={textFieldStyle}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      District<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      disabled
                      name="district"
                      value={formik.values.district}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.district &&
                        Boolean(formik.errors.district)
                      }
                      helperText={
                        formik.touched.district && formik.errors.district
                      }
                      sx={{
                        ...textFieldStyle,
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                          background: "#eee",
                          fontWeight: 700,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      State<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      disabled
                      name="state"
                      value={formik.values.state}
                      // onChange={formik.handleChange}
                      onChange={(e) =>
                        formik.setFieldValue(
                          "state",
                          e.target.value.toUpperCase()
                        )
                      }
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.state && Boolean(formik.errors.state)
                      }
                      helperText={formik.touched.state && formik.errors.state}
                      sx={{
                        ...textFieldStyle,
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                          background: "#eee",
                          fontWeight: 700,
                        },
                      }}
                    ></TextField>
                  </Grid>
                  {/* ------------------------------------------------------------------------------ */}

                  {/* ---------------------------------------------------------------------------------- */}
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Branch Name<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      select
                      name="branchName"
                      value={formik.values.branchName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.branchName &&
                        Boolean(formik.errors.branchName)
                      }
                      helperText={
                        formik.touched.branchName && formik.errors.branchName
                      }
                      sx={{
                        ...textFieldStyle,
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    >
                      {branchNameOptions.map((option) => (
                        <MenuItem key={option.id} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Aadhar Number<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <TextField
                        fullWidth
                        name="aadharNo"
                        value={formik.values.aadharNo}
                        onChange={(e) => {
                          const { value } = e.target;
                          // Allow only digits and limit the input to 12 characters
                          if (/^\d{0,12}$/.test(value)) {
                            // handleChange(e); // Update the formValues state
                            formik.setFieldValue("aadharNo", value);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.aadharNo &&
                          Boolean(formik.errors.aadharNo)
                        }
                        helperText={
                          formik.touched.aadharNo && formik.errors.aadharNo
                        }
                        sx={{
                          ...textFieldStyle,
                          // width: "402px",
                          width: { xs: "75%", md: "75%" },
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                          inputProps: {
                            maxLength: 12, // Limit input to 10 digits
                          },
                        }}
                      />
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          fontFamily: "Poppins",
                          background: "#3029D9",
                          // width: "104px",
                          width: { xs: "25%", md: "25%" },
                          height: "48px",
                          borderRadius: "10px",
                        }}
                      >
                        Verify
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Pan Number<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <TextField
                        fullWidth
                        name="panNo"
                        value={formik.values.panNo}
                        onChange={(e) => {
                          const { value } = e.target;
                          // Allow only alphanumeric characters and limit the input to 10 characters
                          if (/^[A-Z0-9]{0,10}$/.test(value)) {
                            // handleChange(e); // Update the formValues state
                            formik.setFieldValue("panNo", value);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.panNo && Boolean(formik.errors.panNo)
                        }
                        helperText={formik.touched.panNo && formik.errors.panNo}
                        sx={{
                          ...textFieldStyle,
                          // width: "402px",
                          width: { xs: "75%", md: "75%" },
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                          inputProps: {
                            maxLength: 10, // Limit input to 10 characters
                          },
                        }}
                      />
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          fontFamily: "Poppins",
                          background: "#3029D9",
                          // width: "104px",
                          width: { xs: "25%", md: "25%" },
                          height: "48px",
                          borderRadius: "10px",
                        }}
                      >
                        Verify
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Voter Number<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      // label="Voter Number"
                      name="voterNo"
                      value={formik.values.voterNo}
                      onChange={(e) => {
                        const { value } = e.target;
                        // Allow only alphanumeric characters and limit the input to 10 characters
                        if (/^[A-Z0-9]{0,10}$/.test(value)) {
                          // handleChange(e); // Update the formValues state
                          formik.setFieldValue("voterNo", value);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.voterNo && Boolean(formik.errors.voterNo)
                      }
                      helperText={
                        formik.touched.voterNo && formik.errors.voterNo
                      }
                      sx={{
                        ...textFieldStyle,
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                        inputProps: {
                          maxLength: 10, // Limit input to 10 characters
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Mobile Number<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      name="mobileNo"
                      value={formik.values.mobileNo}
                      onChange={(e) => {
                        const { value } = e.target;
                        // Allow only digits and limit the input to 10 characters
                        if (/^\d{0,10}$/.test(value)) {
                          // handleChange(e); // Update the formValues state
                          formik.setFieldValue("mobileNo", value);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.mobileNo &&
                        Boolean(formik.errors.mobileNo)
                      }
                      helperText={
                        formik.touched.mobileNo && formik.errors.mobileNo
                      }
                      sx={{
                        ...textFieldStyle,
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                        inputProps: {
                          maxLength: 10, // Limit input to 10 digits
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Email ID
                    </Typography>
                    <TextField
                      fullWidth
                      name="emailId"
                      value={formik.values.emailId}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.emailId && Boolean(formik.errors.emailId)
                      }
                      helperText={
                        formik.touched.emailId && formik.errors.emailId
                      }
                      sx={{
                        ...textFieldStyle,
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Occupation
                    </Typography>
                    <TextField
                      fullWidth
                      name="occupation"
                      value={formik.values.occupation}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      sx={{
                        ...textFieldStyle,
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Education
                    </Typography>
                    <TextField
                      fullWidth
                      name="education"
                      value={formik.values.education}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      sx={{
                        ...textFieldStyle,
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Intro. Member Code<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      name="introMemCode"
                      value={formik.values.introMemCode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.introMemCode &&
                        Boolean(formik.errors.introMemCode)
                      }
                      helperText={
                        formik.touched.introMemCode &&
                        formik.errors.introMemCode
                      }
                      sx={{
                        ...textFieldStyle,
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      container
                      direction="column"
                      // alignItems="center"
                    >
                      <Typography
                        sx={{
                          width: "100%",
                          fontSize: { xs: "16px", md: "18px" },
                          marginBottom: "8px",
                          ...fontStyle,
                        }}
                      >
                        Photo(With Aadhar)
                      </Typography>

                      <ImageUploadWrapper>
                        <PersonIcon
                          style={{ fontSize: 100, color: "#BDBDBD" }}
                        />
                      </ImageUploadWrapper>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
            {/* ----------------------------------------------------------------------------------------------------------------------- */}
            {/* Step 2 details */}
            {activeStep === 1 && (
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "36px",
                    mb: 1,
                    mt: 2,
                  }}
                >
                  Nominee Details
                </Typography>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Nominee Name<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      name="nomineeName"
                      value={formik.values.nomineeName.toUpperCase()}
                      onChange={(e) =>
                        formik.setFieldValue(
                          "nomineeName",
                          e.target.value.toUpperCase()
                        )
                      }
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.nomineeName &&
                        Boolean(formik.errors.nomineeName)
                      }
                      helperText={
                        formik.touched.nomineeName && formik.errors.nomineeName
                      }
                      sx={{
                        ...textFieldStyle,
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Nominee Relation<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      select
                      name="nomineeRelation"
                      value={formik.values.nomineeRelation}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.nomineeRelation &&
                        Boolean(formik.errors.nomineeRelation)
                      }
                      helperText={
                        formik.touched.nomineeRelation &&
                        formik.errors.nomineeRelation
                      }
                      sx={{
                        ...textFieldStyle,
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <MenuItem value="FATHER">FATHER</MenuItem>
                      <MenuItem value="MOTHER">MOTHER</MenuItem>
                      <MenuItem value="BROTHER">BROTHER</MenuItem>
                      <MenuItem value="SISTER">SISTER</MenuItem>
                      <MenuItem value="HUSBAND">HUSBAND</MenuItem>
                      <MenuItem value="WIFE">WIFE</MenuItem>
                      <MenuItem value="SON">SON</MenuItem>
                      <MenuItem value="DAUGHTER">DAUGHTER</MenuItem>
                      <MenuItem value="DAUGHTER_IN_LAW">
                        DAUGHTER_IN_LAW
                      </MenuItem>
                      <MenuItem value="BROTHER_IN_LAW">BROTHER_IN_LAW</MenuItem>
                      <MenuItem value="GRAND_DAUGHTER">GRAND_DAUGHTER</MenuItem>
                      <MenuItem value="GRANDSON">GRANDSON</MenuItem>
                      <MenuItem value="OTHER">OTHER</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Nominee Mobile Number
                    </Typography>
                    <TextField
                      fullWidth
                      name="nomineemobileNo"
                      value={formik.values.nomineemobileNo}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      sx={{
                        ...textFieldStyle,
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Nominee Age
                    </Typography>
                    <TextField
                      fullWidth
                      name="nomineeAge"
                      value={formik.values.nomineeAge}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      sx={{
                        ...textFieldStyle,
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}

            {/* ----------------------------------------------------------------------------------------------------------------------- */}
            {/* Step 2 details */}
            {activeStep === 2 && (
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "36px",
                    mb: 1,
                    mt: 2,
                  }}
                >
                  Payment Details
                </Typography>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Member Fees(If Any)
                    </Typography>
                    <TextField
                      fullWidth
                      name="memberfees"
                      type="number"
                      value={formik.values.memberfees}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      sx={{ ...textFieldStyle }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      error={
                        formik.touched.memberfees &&
                        Boolean(formik.errors.memberfees)
                      }
                      helperText={
                        formik.touched.memberfees && formik.errors.memberfees
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Share Alloted From<span style={{ color: "red" }}>*</span>
                    </Typography>

                    <TextField
                      fullWidth
                      select
                      // name="shareAllotedForm"
                      // value={formik.values.shareAllotedForm || ""}
                      name="promotorCode" // Changed name to promotorCode
                      value={formik.values.promotorCode || ""} // Linked to formik values
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      sx={{ ...textFieldStyle }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      // error={
                      //   formik.touched.shareAllotedForm &&
                      //   Boolean(formik.errors.shareAllotedForm)
                      // }
                      // helperText={
                      //   formik.touched.shareAllotedForm &&
                      //   formik.errors.shareAllotedForm
                      // }
                      error={
                        formik.touched.promotorCode &&
                        Boolean(formik.errors.promotorCode)
                      }
                      helperText={
                        formik.touched.promotorCode &&
                        formik.errors.promotorCode
                      }
                    >
                      {/* {shareAllotedOptions.map((option) => (
                        <MenuItem key={option.id} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))} */}
                      {promotorOptions.map((option) => (
                        <MenuItem key={option.id} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      No. Of Share<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      name="noOfShare"
                      type="number"
                      value={formik.values.noOfShare}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        const intValue = Math.max(0, Math.floor(value)); // Ensure non-negative integer value
                        formik.setFieldValue(name, intValue); // Update Formik value
                        formik.setFieldValue("shareAmount", intValue * 10); // Update shareAmount based on noOfShare
                      }}
                      onBlur={formik.handleBlur}
                      sx={{ ...textFieldStyle }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      error={
                        formik.touched.noOfShare &&
                        Boolean(formik.errors.noOfShare)
                      }
                      helperText={
                        formik.touched.noOfShare && formik.errors.noOfShare
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Share Amount
                    </Typography>
                    <TextField
                      fullWidth
                      name="shareAmount"
                      value={formik.values.shareAmount}
                      disabled
                      sx={{
                        ...textFieldStyle,
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Payment By<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      select
                      name="paymentBy"
                      value={formik.values.paymentBy}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      sx={{ ...textFieldStyle }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      error={
                        formik.touched.paymentBy &&
                        Boolean(formik.errors.paymentBy)
                      }
                      helperText={
                        formik.touched.paymentBy && formik.errors.paymentBy
                      }
                    >
                      <MenuItem value="CASH">CASH</MenuItem>
                      <MenuItem value="CHEQUE">CHEQUE</MenuItem>
                      <MenuItem value="ONLINE">ONLINE</MenuItem>
                      <MenuItem value="NEFT">NEFT</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Remarks
                    </Typography>
                    <TextField
                      fullWidth
                      name="remarks"
                      value={formik.values.remarks}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      sx={{
                        ...textFieldStyle,
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Member Status<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Switch
                      checked={formik.values.memberStatus} // Use Formik value
                      onChange={(e) =>
                        formik.setFieldValue("memberStatus", e.target.checked)
                      }
                      sx={{ transform: "scale(1.5)" }}
                    />
                  </Grid> */}
                  {/* <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Promoter<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Switch
                      checked={formik.values.promotor} // Use Formik value
                      onChange={(e) =>
                        formik.setFieldValue("promotor", e.target.checked)
                      }
                      sx={{ transform: "scale(1.5)" }}
                    />
                  </Grid> */}
                </Grid>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 3,
                gap: "2%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: 3,
                  gap: "2%",
                }}
              >
                {activeStep === steps.length - 1 ? (
                  <>
                    {/* Previous Button */}
                    <Button
                      variant="outlined"
                      disabled={activeStep === 0}
                      onClick={() => setActiveStep((prev) => prev - 1)}
                      sx={{
                        textTransform: "none",
                        fontFamily: "Poppins",
                        width: { xs: "80px", md: "250px" },
                        height: "48px",
                        borderRadius: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      Previous
                    </Button>

                    {/* Print Button */}
                    <Button
                      variant="contained"
                      onClick={() => window.print()}
                      sx={{
                        textTransform: "none",
                        fontFamily: "Poppins",
                        width: { xs: "80px", md: "250px" },
                        height: "48px",
                        borderRadius: "10px",
                        fontWeight: "bold",
                        background: "#E0C042",
                      }}
                    >
                      Print
                    </Button>

                    {/* Save Button */}
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={handleNext}
                      sx={{
                        textTransform: "none",
                        fontFamily: "Poppins",
                        background: "#3029D9",
                        width: { xs: "80px", md: "250px" },
                        height: "48px",
                        borderRadius: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      Save
                    </Button>
                  </>
                ) : (
                  <>
                    {/* Previous Button */}
                    <Button
                      variant="outlined"
                      disabled={activeStep === 0}
                      onClick={() => setActiveStep((prev) => prev - 1)}
                      sx={{
                        textTransform: "none",
                        fontFamily: "Poppins",
                        width: { xs: "80px", md: "157px" },
                        height: "48px",
                        borderRadius: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      Previous
                    </Button>

                    {/* Next Button */}
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{
                        textTransform: "none",
                        fontFamily: "Poppins",
                        background: "#3029D9",
                        width: { xs: "80px", md: "157px" },
                        height: "48px",
                        borderRadius: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </form>
        </Paper>
      </LocalizationProvider>
    </Box>
  );
};

export default AddMember;
