import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  CardMedia,
  Typography,
  Box,
  TextField,
  Button,
  useMediaQuery,
} from "@mui/material";
import loginImage from "../Assets/Login/Login_image.svg";
import vllogo from "../Assets/Login/image 1.svg";
import indiaflag from "../Assets/Login/indiaflag.svg";
import lion from "../Assets/Login/lion.svg";
import username from "../Assets/Login/Username.svg";
import password from "../Assets/Login/Password.svg";
import "@fontsource/poppins";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loginUser } from "../redux/actions/authActions";
import Chatbot from "./GenericComponent/Chatboat";

const SignIn = () => {
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:600px)");
  const auth = useSelector((state) => state.auth);
  const [captcha, setCaptcha] = useState("");
  const [captchaInput, setCaptchaInput] = useState("");

  // Function to generate a random CAPTCHA
  const generateCaptcha = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let captchaCode = "";
    for (let i = 0; i < 6; i++) {
      captchaCode += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return captchaCode;
  };
  // Generate CAPTCHA when the component mounts
  useEffect(() => {
    setCaptcha(generateCaptcha());
  }, []);

  const handleCaptchaChange = (e) => {
    setCaptchaInput(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);
    // Check if CAPTCHA is entered
    if (!captchaInput) {
      setError("Please enter the CAPTCHA");
      toast.error("Please enter the CAPTCHA");
      return;
    }
    // Check if CAPTCHA matches
    if (captchaInput !== captcha) {
      setError("CAPTCHA does not match");
      toast.error("CAPTCHA does not match");
      return;
    }
    dispatch(loginUser(formData));
  };

  useEffect(() => {
    if (auth.error) {
      setError(auth.error.message || "Invalid username or password");
      toast.error(auth.error.message || "Invalid username or password");
    }
    if (auth.token) {
      navigate("/dashboard");
    }
  }, [auth, navigate]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        height: "100vh",
        backgroundColor: "#F4F4F4",
      }}
    >
      {!isMobile && (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid container>
            <Grid item xs={12} sx={{ marginTop: "12%" }}>
              <img
                src={loginImage}
                alt="Login"
                style={{ width: "100%", height: "100%" }}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#FFFFFF",
          width: isMobile ? "100%" : "auto",
          p: 4,
          ml: isMobile ? 0 : 30,
          position: "relative",
        }}
      >
        {/* India Flag */}
        <Box
          sx={{
            position: "absolute",
            top: 20,
            right: 20,
          }}
        >
          <CardMedia
            component="img"
            image={indiaflag}
            alt="India Flag"
            sx={{
              width: isMobile ? "40px" : "60px",
              height: isMobile ? "30px" : "44px",
            }}
          />
        </Box>

        {/* VL Logo */}
        <CardMedia
          component="img"
          image={vllogo}
          alt="VL Logo"
          sx={{
            width: "100px",
            height: "100px",
            marginTop: isMobile ? "5px" : "20px",
          }}
        />
        <Grid
          item
          sx={{
            gap: 0,
            opacity: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            mt: 2,
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "24px",
              fontWeight: 600,
              lineHeight: "36px",
            }}
          >
            VENKETLAXMI URBAN
            <br />
            PUNE NIDHI LTD
          </Typography>
        </Grid>
        <form onSubmit={handleSubmit}>
          <Grid
            item
            sx={{
              width: isMobile ? "100%" : "430px",
              mt: 1,
              position: "relative",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "16.16px",
                fontWeight: 400,
                lineHeight: "24.23px",
                textAlign: "left",
                mb: 0,
              }}
            >
              User ID
            </Typography>
            <Grid
              item
              sx={{
                width: "100%",
                height: "50px",
                borderRadius: "8px",
                backgroundColor: "#EEEEEE",
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                placeholder="Enter User ID"
                name="username"
                value={formData.username}
                onChange={handleChange}
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                    height: "50px",
                    backgroundColor: "#EEEEEE",
                  },
                }}
              />
              <Box
                sx={{
                  width: "50.59px",
                  height: "50px",
                  top: 0,
                  right: 0,
                  borderRadius: "8px",
                  backgroundColor: "#1E2772",
                  position: "absolute",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={username}
                  alt="Icon"
                  style={{ width: "50px", height: "50px" }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid
            item
            sx={{
              width: isMobile ? "100%" : "430px",
              mt: 1,
              position: "relative",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "16.16px",
                fontWeight: 400,
                lineHeight: "24.23px",
                textAlign: "left",
                mb: 0,
              }}
            >
              Password
            </Typography>
            <Grid
              item
              sx={{
                width: "100%",
                height: "50px",
                borderRadius: "8px",
                backgroundColor: "#EEEEEE",
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <TextField
                variant="outlined"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Enter Your Password"
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                    height: "50px",
                    backgroundColor: "#EEEEEE",
                  },
                }}
              />
              <Box
                sx={{
                  width: "50.59px",
                  height: "50px",
                  top: 0,
                  right: 0,
                  borderRadius: "8px",
                  backgroundColor: "#1E2772",
                  position: "absolute",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={password}
                  alt="Icon"
                  style={{ width: "50px", height: "50px" }}
                />
              </Box>
            </Grid>
          </Grid>
          {error && (
            <Typography
              sx={{
                color: "red",
                mt: 1,
                textAlign: "center",
              }}
            >
              {error}
            </Typography>
          )}
          <Grid
            item
            sx={{
              width: "100%",
              mt: 0,
              textAlign: isMobile ? "center" : "left",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "21px",
                color: "#1E2772",
                cursor: "pointer",
              }}
            >
              Forgot password?
            </Typography>
          </Grid>

          {/* CAPTCHA input field */}
          <Grid
            item
            sx={{
              width: isMobile ? "100%" : "430px",
              mt: 1,
              position: "relative",
            }}
          >
            {/* <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "16.16px",
                fontWeight: 400,
                lineHeight: "24.23px",
                textAlign: "left",
                mb: 1,
              }}
            >
              CAPTCHA
            </Typography> */}
            <TextField
              variant="outlined"
              name="captchaInput"
              value={captchaInput}
              onChange={handleCaptchaChange}
              placeholder="Enter CAPTCHA"
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: "8px",
                  },
                  height: "50px",
                },
              }}
            />
            <Typography
              sx={{
                marginTop: "10px",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: 600,
                textAlign: "center",
                color: "#1E2772",
              }}
            >
              {captcha}
            </Typography>
          </Grid>

          <Button
            type="submit"
            variant="contained"
            sx={{
              width: isMobile ? "100%" : "430px",
              height: "48px",
              backgroundColor: "#1E2772",
              mt: 1,
              borderRadius: "8px",
              textTransform: "none",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "24px",
              color: "#FFFFFF",
            }}
          >
            Login Now
          </Button>
        </form>

        {/* Lion Logo */}
        <Box
          sx={{
            position: "absolute",
            bottom: 20,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <CardMedia
            component="img"
            image={lion}
            alt="Lion Logo"
            sx={{
              width: isMobile ? "60px" : "80px",
            }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          mt: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Chatbot />
      </Box>
    </Box>
  );
};

export default SignIn;
