import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import axios from "axios";
import vllogo from "../../Assets/logo.png";

function ShareCertificate() {
  const [members, setMembers] = useState([]);
  const [selectedCode, setSelectedCode] = useState("");
  const [selectedMemberDetails, setSelectedMemberDetails] = useState(null);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://api.vlnidhi.com/api/admin/approvedmembers",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setMembers(response.data);
      } catch (error) {
        console.error("Error fetching members", error);
      }
    };

    fetchMembers();
  }, []);

  const handleSearch = () => {
    const foundMember = members.find(
      (member) => member.memberCode === selectedCode
    );
    setSelectedMemberDetails(foundMember || null);
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Share Certificate
      </Typography>
      <Paper
        sx={{
          width: "100%",
          p: 2,
          borderRadius: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
          }}
        >
          Search Details
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "flex", flexDirection: "column", mr: 2 }}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                mb: 1,
                mt: 2,
                fontFamily: "Poppins, sans-serif",
                fontSize: "14px",
              }}
            >
              Select by Code<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              value={selectedCode}
              onChange={(e) => setSelectedCode(e.target.value)}
              sx={{
                width: { xs: "100%", md: "495px" },
                height: "48px",
                borderRadius: "10px",
                borderColor: "#D9D9D9CC",
                backgroundColor: "#FFFFFF",
                "& .MuiInputBase-root": {
                  height: "48px",
                },
              }}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            >
              {members.length > 0 ? (
                members.map((member) => (
                  <MenuItem
                    key={member.id}
                    value={member.memberCode}
                  >{`${member.memberName} - ${member.memberCode}`}</MenuItem>
                ))
              ) : (
                <MenuItem value="" disabled>
                  No Members Available
                </MenuItem>
              )}
            </TextField>
          </Box>

          <Button
            variant="contained"
            onClick={handleSearch}
            sx={{
              textTransform: "none",
              fontFamily: "Poppins",
              background: "#DFBF41",
              width: { xs: "50%", md: "157px" },
              height: "48px",
              borderRadius: "10px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#B89B3E" },
              mt: 5,
            }}
          >
            Search
          </Button>
        </Box>
      </Paper>

      {/* Share Data List Table */}
      {selectedMemberDetails && (
        <Paper
          sx={{
            width: "100%",
            p: 2,
            borderRadius: "20px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            mt: 3,
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "36px",
            }}
          >
            Share Data List
          </Typography>

          <TableContainer component={Paper} sx={{ borderRadius: "12px" }}>
            <Table size="small">
              <TableHead
                sx={{
                  backgroundColor: "#413AF1",
                  color: "#fff",
                  height: "50px",
                }}
              >
                <TableRow>
                  <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                    INO
                  </TableCell>
                  <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                    M.CODE
                  </TableCell>
                  <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                    M.NAME
                  </TableCell>
                  <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                    SHARE AMT
                  </TableCell>
                  <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                    NO OF SHARE
                  </TableCell>
                  <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                    FOLIO NO
                  </TableCell>
                  <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                    DNO FRM
                  </TableCell>
                  <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                    DNO TO
                  </TableCell>
                  <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                    STATUS
                  </TableCell>
                  {/* <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                    Print
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ backgroundColor: "#F2F6FA" }}>
                  {/* <TableCell>{selectedMemberDetails?.id || "N/A"}</TableCell> */}
                  <TableCell>{1}</TableCell> {/* Index starts from 1 */}
                  <TableCell>
                    {selectedMemberDetails?.memberCode || "N/A"}
                  </TableCell>
                  <TableCell>
                    {selectedMemberDetails?.memberName || "N/A"}
                  </TableCell>
                  <TableCell>
                    {selectedMemberDetails?.shareAmount || "N/A"}
                  </TableCell>
                  <TableCell>
                    {selectedMemberDetails?.noOfShare || "N/A"}
                  </TableCell>
                  <TableCell>
                    {selectedMemberDetails?.folioNo || "N/A"}
                  </TableCell>
                  <TableCell>
                    {selectedMemberDetails?.dnoFrom || "N/A"}
                  </TableCell>
                  <TableCell>{selectedMemberDetails?.dnoTo || "N/A"}</TableCell>
                  <TableCell>
                    {selectedMemberDetails?.status || "N/A"}
                  </TableCell>
                  {/* <TableCell>
                    <IconButton>
                      <PrintIcon sx={{ fontSize: "16px" }} />
                    </IconButton>
                  </TableCell> */}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}

      {/* Print Button */}
      {selectedMemberDetails && (
        <Box sx={{ textAlign: "right", mt: 2 }}>
          <Button
            onClick={handlePrint}
            sx={{
              textTransform: "none",
              fontFamily: "Poppins",
              background: "#DFBF41",
              width: { xs: "50%", md: "157px" },
              height: "48px",
              borderRadius: "10px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#B89B3E" },
              color: "white"
            }}
          >
            Print Certificate
          </Button>
        </Box>
      )}

      {/* {!selectedMemberDetails && selectedCode && (
        <Typography sx={{ mt: 3, color: "red" }}>
          No details found for the selected member.
        </Typography>
      )} */}

      {/* Certificate */}
      {selectedMemberDetails && (
        <Paper
          className="certificate-paper"
          sx={{ px: 6, py: 3, mt: 2, borderRadius: "20px" }}
        >
          {/* Header Section */}
          <Box sx={{ marginBottom: 1 }}>
            <Grid container alignItems="center">
              <Grid
                item
                xs={2}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Box sx={{ mr: 2 }}>
                  <img
                    src={vllogo}
                    alt="vllogo"
                    style={{ height: "80px", width: "80px" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: "16px", md: "20px" },
                    mb: 1,
                    fontFamily: "Poppins",
                  }}
                >
                  VENKETLAXMI URBAN PUNE NIDHI LTD
                </Typography>
                <Typography sx={{ fontFamily: "Poppins" }}>
                  Reg. Office: SECOND FLOOR, GOLDEN EMPIRE, BESIDE CROMA, BANER
                  ROAD, BANER - MAHARASHTRA - 411045
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Divider sx={{ mt: 0, mb: 3, borderBottomWidth: 2 }} />
          <Box
            sx={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 600,
                fontFamily: "Poppins",
                opacity: "0.7",
                textDecoration: "underline",
              }}
            >
              Share Certificate
            </Typography>
            <Typography sx={{ fontSize: "12px", mt: 2 }}>
              [Pursuant to sub-section (3) of section 46 of the Companies Act,
              2013 and rule 5(2) of the Companies (Share Capital and Debentures)
              Rules 2014]
            </Typography>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 600,
                fontFamily: "Poppins",
                mt: 2,
                fontSize: "16px",
              }}
            >
              VENKETLAXMI URBAN PUNE NIDHI LTD
            </Typography>
            <Typography sx={{ fontSize: "12px", mt: 2 }}>
              Incorporated under the Companies Act, 2013
            </Typography>

            <Typography sx={{ fontSize: "12px", mt: 2 }}>
              This is to certify that the person(s) named in this Certificate
              is/are the Registered Holder(s) of the within-mentioned Share(s)
              bearing the distinctive number(s) herein specified in the above
              named Company subject to the Memorandum and Articles of
              Association of the Company and the amount endorsed herein has been
              paid up on each such share.
            </Typography>
            <Box border={1} sx={{ mt: 2, p: 2 }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "left",
                  fontWeight: 500,
                }}
              >
                EQUITY SHARES EACH OF RUPEES : Rs 10.00/- (TEN RUPEES ONLY)
              </Typography>

              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "left",
                  fontWeight: 500,
                  mt: 2,
                }}
              >
                AMOUNT PAID-UP PER SHARE RUPEES : Rs.{" "}
                {selectedMemberDetails?.shareAmount || "N/A"} /-
              </Typography>
            </Box>
            <Box border={1} sx={{ mt: 0, p: 2 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    textAlign: "left",
                    fontWeight: 500,
                  }}
                >
                  Register Folio No.: {selectedMemberDetails?.folioNo || "N/A"}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    textAlign: "left",
                    fontWeight: 500,
                  }}
                >
                  Certificate No.:{" "}
                  {selectedMemberDetails?.certificateNo || "N/A"}
                </Typography>
              </Box>

              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "left",
                  fontWeight: 500,
                  mt: 2,
                }}
              >
                Name(s) of the Holder(s) :{" "}
                {selectedMemberDetails?.memberName || "N/A"}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "left",
                  fontWeight: 500,
                  mt: 2,
                }}
              >
                No. of shares held : {selectedMemberDetails?.noOfShare || "N/A"}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "left",
                  fontWeight: 500,
                  mt: 2,
                }}
              >
                Distinctive No.(s) : From 114001 To 116000 (Both inclusive)
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: "14px",
                textAlign: "left",
                fontWeight: 500,
                mt: 2,
              }}
            >
              Given under the common seal of the Company this 2023 day of 12/Oct
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Box>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 300,
                  opacity: 0.6,
                }}
              >
                Director
              </Typography>
              <Divider sx={{ borderBottomWidth: 2, background: "black" }} />
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                Secretary/any other authorized person
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "10px",
                fontWeight: 500,
              }}
            >
              [Note : No transfer of the share(s) comprised in the Certificate
              can be registered unless accompanied by this Certificate]
            </Typography>
          </Box>
        </Paper>
      )}

      {/* Print Styles */}
      <style jsx="true">{`
        @media print {
          @page {
            size: A4;
            margin: 20mm;
          }

          body * {
            visibility: hidden;
          }

          .certificate-paper,
          .certificate-paper * {
            visibility: visible;
          }

          .certificate-paper {
            position: absolute;
            left: 0;
            top: 0;
          }

          .MuiButton-root {
            display: none; /* Hides the print button */
          }
        }
      `}</style>
    </Box>
  );
}

export default ShareCertificate;
