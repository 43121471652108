import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";

function AddDesignation() {
  const [departments, setDepartments] = useState([]);
  const [selectedDepartmentCode, setSelectedDepartmentCode] = useState("");
  const [designationName, setDesignationName] = useState("");
  const [designations, setDesignations] = useState([]);
  const [showTable, setShowTable] = useState(false);

  const token = localStorage.getItem("token");

  const textFieldStyle = {
    width: { xs: "100%", md: "100%" },
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { md: "48px", xs: "40px" },
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
  };

  // Fetch all departments on component mount
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(
          "https://api.vlnidhi.com/api/admin/departments/all",
          {
            headers: {
              Authorization: `Bearer ${token}`, // If required by your API
            },
          }
        );
        setDepartments(response.data);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchDepartments();
  }, []);

  // Fetch all designations
  const fetchDesignations = async () => {
    try {
      const response = await axios.get(
        "https://api.vlnidhi.com/api/admin/designations/all",
        {
          headers: {
            Authorization: `Bearer ${token}`, // If required by your API
          },
        }
      );
      setDesignations(response.data);
    } catch (error) {
      console.error("Error fetching designations:", error);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("selectedDepartmentCode", selectedDepartmentCode);
      await axios.post(
        `https://api.vlnidhi.com/api/admin/designations/create/${selectedDepartmentCode}`,
        {
          designationName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // If required by your API
          },
        }
      );
      // Fetch updated designations after successful submission
      fetchDesignations();
      // Reset form fields
      setDesignationName("");
      setSelectedDepartmentCode("");
    } catch (error) {
      console.error("Error creating designation:", error);
    }
  };

  useEffect(() => {
    fetchDesignations(); // Initial fetch to populate designations on mount
  }, []);

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Add Designation
      </Typography>

      <Paper
        sx={{
          width: "100%",
          p: 2,
          borderRadius: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
          }}
        >
          Add Designation Master
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    ...fontStyle,
                  }}
                >
                  Select Department <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  select
                  value={selectedDepartmentCode}
                  onChange={(e) => setSelectedDepartmentCode(e.target.value)}
                  sx={textFieldStyle}
                  InputProps={{
                    style: { borderRadius: "10px" },
                  }}
                >
                  {departments.map((department) => (
                    <MenuItem
                      key={department.id}
                      value={department.departmentCode}
                    >
                      {department.departmentName}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    ...fontStyle,
                  }}
                >
                  Designation Name <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  value={designationName}
                  onChange={(e) => setDesignationName(e.target.value)}
                  sx={textFieldStyle}
                  InputProps={{
                    style: { borderRadius: "10px" },
                  }}
                ></TextField>
              </Box>
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              flexDirection: { md: "row", xs: "column" },
            }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{
                textTransform: "none",
                fontFamily: "Poppins",
                background: "#DFBF41",
                width: { xs: "50%", md: "157px" },
                height: "48px",
                borderRadius: "10px",
                fontWeight: "bold",
                "&:hover": { backgroundColor: "#B89B3E" },
                mt: 2,
              }}
            >
              Save
            </Button>
          </Box>
        </form>
      </Paper>

      {/* {showTable && ( */}
      <Paper sx={{ p: 2, mt: 2, borderRadius: "20px", minHeight: "350px" }}>
        <Box sx={{ mt: 0 }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "36px",
              mb: 2,
            }}
          >
            Designation List
          </Typography>
          <TableContainer component={Paper} sx={{ borderRadius: "12px" }}>
            <Table size="small">
              <TableHead sx={{ backgroundColor: "#413AF1", color: "#fff" }}>
                <TableRow>
                  <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                    Sl No
                  </TableCell>
                  <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                    Designation Code
                  </TableCell>
                  <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                    Designation Name
                  </TableCell>
                  <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                    Department Name
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {designations.map((designation, index) => (
                  <TableRow key={designation.id}>
                    <TableCell sx={{ textAlign: "center" }}>
                      {index + 1}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {designation.designationCode}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {designation.designationName}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {designation.department.departmentName}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
      {/* )} */}
    </Box>
  );
}

export default AddDesignation;
