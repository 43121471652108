import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

function AddEmployee() {
  const [branches, setBranches] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);

  // State variables for form inputs
  const [employeeName, setEmployeeName] = useState("");
  const [dob, setDob] = useState("");
  const [joiningDate, setJoiningDate] = useState("");
  const [relativeName, setRelativeName] = useState("");
  const [relativeRelation, setRelativeRelation] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [relativeAge, setRelativeAge] = useState(0);
  const [address, setAddress] = useState("");
  const [pan, setPan] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [experienceDetails, setExperienceDetails] = useState("");
  const [qualification, setQualification] = useState("");
  const [salary, setSalary] = useState(0);
  const [sbAccountNo, setSbAccountNo] = useState("");
  const [fees, setFees] = useState(0);
  const [paymentBy, setPaymentBy] = useState("");
  const [remarks, setRemarks] = useState("");
  const [empStatus, setEmpStatus] = useState(true);

  const [selectedBranchCode, setSelectedBranchCode] = useState("");
  const [selectedDesignationCode, setSelectedDesignationCode] = useState("");
  const [selectedDepartmentCode, setSelectedDepartmentCode] = useState("");

  const styles = {
    paper: {
      width: "100%",
      p: 2,
      borderRadius: "20px",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      px: 3,
    },
    header: {
      fontFamily: "Poppins",
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "36px",
      mb: 1,
    },
    sectionHeader: {
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "36px",
      mb: 1,
    },
    label: {
      fontFamily: "Poppins, sans-serif",
      fontSize: { xs: "12px", md: "14px" },
    },
    textField: {
      width: "100%",
      borderColor: "#D9D9D9CC",
      backgroundColor: "#FFFFFF",
      "& .MuiInputBase-root": {
        height: { xs: "40px", md: "48px" },
      },
    },
    button: {
      borderRadius: "10px",
      width: { xs: "100%", md: "247px" },
    },
    newButton: {
      backgroundColor: "#FFD700",
      color: "#000",
    },
    saveButton: {
      backgroundColor: "#3F51B5",
      color: "#fff",
    },
  };

  // Setting up Axios instance
  const axiosInstance = axios.create({
    baseURL: "https://api.vlnidhi.com/api",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  const handleSave = async () => {
    const url = `/admin/employees/create/${selectedDepartmentCode}/${selectedDesignationCode}/${selectedBranchCode}`;
    const payload = {
      employeeName,
      dob,
      joiningDate,
      relativeName,
      relativeRelation,
      mobileNo,
      relativeAge,
      address,
      pan,
      bankAccount,
      ifscCode,
      experienceDetails,
      qualification,
      salary,
      sbAccountNo,
      fees,
      paymentBy,
      remarks,
      empStatus,
    };

    try {
      const response = await axiosInstance.post(url, payload);
      console.log("Employee added successfully:", response.data);
    } catch (error) {
      console.error("Error adding employee:", error.message);
    }
  };

  // Fetch branches from API
  const fetchBranches = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/getallbid");
      setBranches(response.data);
    } catch (error) {
      console.error("Error fetching branches:", error.message);
    }
  }, []);

  // Fetch designations from API
  const fetchDesignations = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/designations/all");
      setDesignations(response.data); // Set the fetched designations
    } catch (error) {
      console.error("Error fetching designations:", error.message);
    }
  }, []);

  // Fetch departments from API
  const fetchDepartments = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/departments/all");
      setDepartments(response.data); // Set the fetched departments
    } catch (error) {
      console.error("Error fetching departments:", error.message);
    }
  }, []);

  // Fetch payment methods from API
  const fetchPaymentMethods = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/payment-by");
      setPaymentMethods(response.data); // Set the fetched payment methods
    } catch (error) {
      console.error("Error fetching payment methods:", error.message);
    }
  }, []);

  useEffect(() => {
    fetchBranches(); // Fetch branches
    fetchDesignations(); // Fetch designations
    fetchDepartments(); // Fetch departments
    fetchPaymentMethods(); // Fetch payment methods
  }, [fetchBranches, fetchDesignations, fetchDepartments, fetchPaymentMethods]);

  return (
    <Box>
      <Typography sx={styles.header}>Add Employee</Typography>

      <Paper sx={styles.paper}>
        <Typography sx={styles.sectionHeader}>Employee Details</Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Joining Date <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                type="date"
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                value={joiningDate}
                onChange={(e) => setJoiningDate(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Branch Name <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                select
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                value={selectedBranchCode}
                onChange={(e) => setSelectedBranchCode(e.target.value)}
              >
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.branchCode}>
                    {branch.branchName} - {branch.branchCode}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Employee Name <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                value={employeeName}
                onChange={(e) => setEmployeeName(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Address <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography sx={{ ...styles.label }}>
                    DOB <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    type="date"
                    sx={styles.textField}
                    InputProps={{
                      style: { borderRadius: "10px" },
                    }}
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                  ></TextField>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography sx={{ ...styles.label }}>Age</Typography>
                  <TextField
                    disabled
                    sx={styles.textField}
                    InputProps={{
                      style: { borderRadius: "10px", background: "#eee" },
                    }}
                  ></TextField>
                </Box>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                PAN <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                value={pan}
                onChange={(e) => setPan(e.target.value)}
              ></TextField>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>Bank A/C</Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                value={bankAccount}
                onChange={(e) => setBankAccount(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>IFSC Code</Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                value={ifscCode}
                onChange={(e) => setIfscCode(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Mobile No <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                value={mobileNo}
                onChange={(e) => setMobileNo(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>Exp. Details</Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                value={experienceDetails}
                onChange={(e) => setExperienceDetails(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>Qualification</Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                value={qualification}
                onChange={(e) => setQualification(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>Relative Name</Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                value={relativeName}
                onChange={(e) => setRelativeName(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>Relative Age</Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                value={relativeAge}
                onChange={(e) => setRelativeAge(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Relative Relation
              </Typography>
              <TextField
                select
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                value={relativeRelation}
                onChange={(e) => setRelativeRelation(e.target.value)}
              >
                <MenuItem value="BROTHER">BROTHER</MenuItem>
                <MenuItem value="DAUGHTER">DAUGHTER</MenuItem>
                <MenuItem value="FATHER">FATHER</MenuItem>
                <MenuItem value="HUSBAND">HUSBAND</MenuItem>
                <MenuItem value="MOTHER">MOTHER</MenuItem>
                <MenuItem value="SISTER">SISTER</MenuItem>
                <MenuItem value="SON">SON</MenuItem>
                <MenuItem value="WIFE">WIFE</MenuItem>
                <MenuItem value="DAUGHTER_IN_LAW">DAUGHTER IN LAW</MenuItem>
                <MenuItem value="BROTHER_IN_LAW">BROTHER IN LAW</MenuItem>
                <MenuItem value="GRAND_DAUGHTER">GRAND DAUGHTER</MenuItem>
                <MenuItem value="GRANDSON">GRAND SON</MenuItem>
                <MenuItem value="OTHER">OTHER</MenuItem>
              </TextField>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ ...styles.paper, mt: 2 }}>
        <Typography sx={styles.sectionHeader}>Designation Details</Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Department <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                select
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                value={selectedDepartmentCode}
                onChange={(e) => setSelectedDepartmentCode(e.target.value)}
              >
                {departments.map((department) => (
                  <MenuItem
                    key={department.id}
                    value={department.departmentCode}
                  >
                    {department.departmentName}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Salary <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                value={salary}
                onChange={(e) => setSalary(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Designation <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                select
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                value={selectedDesignationCode}
                onChange={(e) => setSelectedDesignationCode(e.target.value)}
              >
                {designations.map((designation) => (
                  <MenuItem
                    key={designation.id}
                    value={designation.designationCode}
                  >
                    {designation.designationName} -{" "}
                    {designation.department.departmentName}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>SB Account No.</Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                value={sbAccountNo}
                onChange={(e) => setSbAccountNo(e.target.value)}
              ></TextField>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ ...styles.paper, mt: 2 }}>
        <Typography sx={styles.sectionHeader}>Payment Details</Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Fees (if any) <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                value={fees}
                onChange={(e) => setFees(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Payment By <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                select
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                value={paymentBy}
                onChange={(e) => setPaymentBy(e.target.value)}
              >
                {paymentMethods.map((method, index) => (
                  <MenuItem key={index} value={method}>
                    {method}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>Remarks</Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Emp. Status <span style={{ color: "red" }}>*</span>
              </Typography>
              <Switch
                sx={{ transform: "scale(1.5)" }}
                checked={empStatus}
                onChange={(e) => setEmpStatus(e.target.checked)}
              />
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button
            onClick={handleSave}
            variant="contained"
            sx={{
              textTransform: "none",
              fontFamily: "Poppins",
              background: "#DFBF41",
              width: { xs: "100%", md: "157px" },
              height: { md: "48px", xs: "30px" },
              borderRadius: "10px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#B89B3E" },
              color: "white",
              mt: { md: 2.5, xs: 1 },
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              fontFamily: "Poppins",
              background: "#3029D9",
              width: { xs: "100%", md: "157px" },
              height: { md: "48px", xs: "30px" },
              borderRadius: "10px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#B89B3E" },
              color: "white",
              mt: { md: 2.5, xs: 1 },
            }}
          >
            New
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default AddEmployee;
