import React, { useEffect, useState } from "react";
import {
  TextField,
  Switch,
  Button,
  MenuItem,
  Box,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";

const MISDeposit = () => {
  const token = localStorage.getItem("token");
  const [plans, setPlans] = useState([]);

  const [maturityAmount, setMaturityAmount] = useState(0);
  const [misInterest, setMisInterest] = useState(0);

  const validationSchema = Yup.object({
    planName: Yup.string().required("Enter Plan Name"),
    minimumAmount: Yup.number()
      .required("Enter Minimum Amount")
      .min(0, "Minimum Amount cannot be negative"),
    maturityROI: Yup.number()
      .required("Enter Interest Rate")
      .min(0, "Interest Rate cannot be negative"),
    term: Yup.number().required("Enter Term").min(0, "Term cannot be negative"),
    misIntROI: Yup.number()
      .required("Enter Interest Rate")
      .min(0, "Interest Rate cannot be negative"),
    commNew: Yup.number()
      .required("Enter Commission New")
      .min(0, "Commission New cannot be negative"),
  });

  const formik = useFormik({
    initialValues: {
      planName: "",
      minimumAmount: "",
      maturityROI: "",
      termMode: "Monthly",
      term: "",
      interestType: "Simple_Interest",
      misIntROI: "",
      misInterval: "Monthly",
      commNew: "",
      planStatus: true,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await axios.post(
          "https://api.vlnidhi.com/api/admin/mis/createmis",
          {
            planName: values.planName,
            minimumAmount: values.minimumAmount,
            maturityROI: values.maturityROI,
            termMode: values.termMode,
            term: values.term,
            interestType: values.interestType,
            misIntROI: values.misIntROI,
            misInterval: values.misInterval,
            commNew: values.commNew,
            planStatus: values.planStatus,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log("Response:", response.data);
        Swal.fire("Success", "Plan created successfully!", "success");
        formik.resetForm();
        setMisInterest(0);
        setMaturityAmount(0);
        fetchPlans();
      } catch (error) {
        console.error("Error submitting the form:", error);
        Swal.fire("Error", "Failed to create plan", "error");
      }
    },
  });

  const { values } = formik;

  // Calculate Maturity Amount when input values change
  useEffect(() => {
    const { minimumAmount, maturityROI, term, termMode, interestType } =
      formik.values;

    if (minimumAmount && maturityROI && term) {
      const compoundingFrequency =
        termMode === "Monthly"
          ? 12
          : termMode === "Quarterly"
          ? 4
          : termMode === "Half-Yearly"
          ? 2
          : 1;

      let amount;
      if (interestType === "Simple_Interest") {
        amount = calculateSimpleInterest(minimumAmount, maturityROI, term);
      } else {
        amount = calculateCompoundInterest(
          minimumAmount,
          maturityROI,
          term,
          compoundingFrequency
        );
      }

      setMaturityAmount(amount.toFixed(2)); // Update maturity amount
    }
  }, [
    formik.values.minimumAmount,
    formik.values.maturityROI,
    formik.values.term,
    formik.values.termMode,
    formik.values.interestType,
  ]);

  // Calculate MIS Interest when MIS ROI and Interval change
  useEffect(() => {
    const { minimumAmount, misIntROI, misInterval } = formik.values;

    if (minimumAmount && misIntROI && misInterval) {
      const interest = calculateMISInterest(
        minimumAmount,
        misIntROI,
        misInterval
      );
      setMisInterest(interest.toFixed(2)); // Update MIS interest
    }
  }, [
    formik.values.minimumAmount,
    formik.values.misIntROI,
    formik.values.misInterval,
  ]);

  // Calculation functions
  const calculateSimpleInterest = (amount, roi, term) => {
    const rate = roi / 100;
    const timeInYears = term / 12;
    const simpleInterest = amount * rate * timeInYears;
    return parseFloat(amount) + simpleInterest;
  };

  const calculateCompoundInterest = (
    amount,
    roi,
    term,
    compoundingFrequency
  ) => {
    const rate = roi / 100;
    const t = term / 12; // Term in years
    return (
      amount *
      Math.pow(1 + rate / compoundingFrequency, compoundingFrequency * t)
    );
  };

  const calculateMISInterest = (amount, roi, interval) => {
    const intervalsPerYear =
      interval === "Monthly"
        ? 12
        : interval === "Quarterly"
        ? 4
        : interval === "Half-Yearly"
        ? 2
        : 1;
    return (amount * roi) / (intervalsPerYear * 100);
  };

  // Fetch data from the API
  const fetchPlans = async () => {
    const token = localStorage.getItem("token"); // Fetch the token from localStorage
    try {
      const response = await axios.get(
        "https://api.vlnidhi.com/api/admin/mis/getallmis",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPlans(response.data); // Store the fetched data in the state
    } catch (error) {
      console.error("Error fetching the data:", error);
    }
  };

  useEffect(() => {
    fetchPlans(); // Fetch the plans on component mount
  }, []);

  const textFieldStyle = {
    width: { xs: "100%", lg: "100%" },
    height: "48px",
    borderRadius: "80%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: "48px",
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    // opacity: 0.6,
    width: "100%",
    fontSize: { xs: "12px", md: "14px" },
    // mb: 1,
  };

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "36px",
              mb: 1,
              mt: 0,
            }}
          >
            MIS Deposit
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Plan Name<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                name="planName" // Use Formik's name prop for the field
                value={formik.values.planName} // Access value from Formik
                onChange={formik.handleChange} // Use Formik's handleChange
                onBlur={formik.handleBlur} // Use Formik's handleBlur
                error={Boolean(
                  formik.errors.planName && formik.touched.planName
                )} // Show error if touched and has errors
                helperText={formik.touched.planName && formik.errors.planName} // Display error message
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                MIS Int. ROI(%)<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                name="misIntROI" // Use Formik's name prop for the field
                value={formik.values.misIntROI} // Access value from Formik
                onChange={formik.handleChange} // Use Formik's handleChange
                onBlur={formik.handleBlur} // Use Formik's handleBlur
                error={Boolean(
                  formik.errors.misIntROI && formik.touched.misIntROI
                )} // Show error if touched and has errors
                helperText={formik.touched.misIntROI && formik.errors.misIntROI} // Display error message
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Minimum Amount<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                name="minimumAmount" // Use Formik's name prop for the field
                value={formik.values.minimumAmount} // Access value from Formik
                onChange={formik.handleChange} // Use Formik's handleChange
                onBlur={formik.handleBlur} // Use Formik's handleBlur
                error={Boolean(
                  formik.errors.minimumAmount && formik.touched.minimumAmount
                )} // Show error if touched and has errors
                helperText={
                  formik.touched.minimumAmount && formik.errors.minimumAmount
                } // Display error message
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                MIS Interval<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                select
                name="misInterval" // Use Formik's name prop for the field
                value={formik.values.misInterval} // Access value from Formik
                onChange={formik.handleChange} // Use Formik's handleChange
                onBlur={formik.handleBlur} // Use Formik's handleBlur
                error={Boolean(
                  formik.errors.misInterval && formik.touched.misInterval
                )} // Show error if touched and has errors
                helperText={
                  formik.touched.misInterval && formik.errors.misInterval
                } // Display error message
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              >
                <MenuItem value="Monthly">Monthly</MenuItem>
                <MenuItem value="Quarterly">Quarterly</MenuItem>{" "}
                {/* Fixed spelling */}
                <MenuItem value="Half-Yearly">Half-Yearly</MenuItem>
                <MenuItem value="Yearly">Yearly</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Maturity ROI (%)<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                name="maturityROI" // Use Formik's name prop for the field
                value={formik.values.maturityROI} // Access value from Formik
                onChange={formik.handleChange} // Use Formik's handleChange
                onBlur={formik.handleBlur} // Use Formik's handleBlur
                error={Boolean(
                  formik.errors.maturityROI && formik.touched.maturityROI
                )} // Show error if touched and has errors
                helperText={
                  formik.touched.maturityROI && formik.errors.maturityROI
                } // Display error message
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                MIS Interest<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                disabled
                name="misInterest" // Name is optional here since it's disabled and won't affect form submission
                // value={formik.values.misInterest} // You can keep it as a display value if needed
                value={misInterest}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    background: "#eee",
                    fontWeight: 1000,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Term Mode<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                select
                name="termMode" // Optional, for Formik tracking
                value={formik.values.termMode} // Bind it to Formik state
                onChange={formik.handleChange} // Use Formik's handleChange
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              >
                <MenuItem value="Monthly">Monthly</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Maturity Amount<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                disabled
                name="maturityAmount" // Optional, for Formik tracking
                // value={formik.values.maturityAmount} // Bind it to Formik state if needed
                value={maturityAmount}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    background: "#eee",
                    fontWeight: 1000,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Term(in months)<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                name="term" // Use Formik's name prop for the field
                value={formik.values.term} // Access value from Formik
                onChange={formik.handleChange} // Use Formik's handleChange
                onBlur={formik.handleBlur} // Use Formik's handleBlur
                error={Boolean(formik.errors.term && formik.touched.term)} // Show error if touched and has errors
                helperText={formik.touched.term && formik.errors.term} // Display error message
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Comm. New(%)<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                name="commNew" // Use Formik's name prop for the field
                value={formik.values.commNew} // Access value from Formik
                onChange={formik.handleChange} // Use Formik's handleChange
                onBlur={formik.handleBlur} // Use Formik's handleBlur
                error={Boolean(formik.errors.commNew && formik.touched.commNew)} // Show error if touched and has errors
                helperText={formik.touched.commNew && formik.errors.commNew} // Display error message
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Interest Type<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                select
                name="interestType" // Optional, for Formik tracking
                value={formik.values.interestType} // Bind it to Formik state
                onChange={formik.handleChange} // Use Formik's handleChange
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              >
                <MenuItem value="Simple_Interest">Simple Interest</MenuItem>
                <MenuItem value="Compound_Interest">Compound Interest</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Plan Status<span style={{ color: "red" }}>*</span>
              </Typography>
              <Switch
                name="planStatus" // Optional, for Formik tracking
                checked={formik.values.planStatus} // Bind it to Formik state
                onChange={(e) =>
                  formik.setFieldValue("planStatus", e.target.checked)
                } // Use Formik's setFieldValue
                sx={{ transform: "scale(1.5)" }}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 3,
              gap: "2%",
            }}
          >
            <Button
              variant="contained"
              // onClick={handleSave}
              type="submit"
              sx={{
                textTransform: "none",
                fontFamily: "Poppins",
                background: "#E8C30B",
                width: { xs: "50%", md: "157px" },
                height: "48px",
                borderRadius: "10px",
                fontWeight: "bold",
                "&:hover": { backgroundColor: "#a99220" },
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </form>
      <Box sx={{ mt: 3, borderRadius: "20px" }}>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          MIS Plan
        </Typography>
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "10px 10px 0px 0px",
            width: { xs: "320px", md: "100%", lg: "100%" },
          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow style={{ backgroundColor: "#3F00FF" }}>
                <TableCell style={{ color: "#fff" }}>Plan Code</TableCell>
                <TableCell style={{ color: "#fff" }}>Plan Name</TableCell>
                <TableCell style={{ color: "#fff" }}>
                  Term (in Months)
                </TableCell>
                <TableCell style={{ color: "#fff" }}>Maturity ROI</TableCell>
                <TableCell style={{ color: "#fff" }}>Interest Type</TableCell>
                <TableCell style={{ color: "#fff" }}>MIS ROI</TableCell>
                <TableCell style={{ color: "#fff" }}>Comm(N)</TableCell>
                <TableCell style={{ color: "#fff" }}>MinAmount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {plans.map((plan, index) => (
                <TableRow
                  key={plan.id}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "#F2F6FA" : "#C1E0FB", // Alternate row color
                  }}
                >
                  <TableCell>{plan.planCode}</TableCell>
                  <TableCell>{plan.planName}</TableCell>
                  <TableCell>{plan.term}</TableCell>
                  <TableCell>{plan.maturityROI}%</TableCell>
                  <TableCell>{plan.interestType}</TableCell>
                  <TableCell>
                    {plan.misIntROI ? `${plan.misIntROI}%` : "N/A"}
                  </TableCell>
                  <TableCell>
                    {plan.commNew ? `${plan.commNew}%` : "N/A"}
                  </TableCell>
                  <TableCell>{plan.minimumAmount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default MISDeposit;
