import React from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  Card,
  CardContent,
  MenuItem,
  Select,
  FormControl,
  Divider,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import Chatbot from "../Components/GenericComponent/Chatboat";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Bar,
  BarChart,
  PieChart,
  Pie,
  Cell,
} from "recharts";

import WalletIcon from "@mui/icons-material/Wallet";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

// Sample data for the chart
const data = [
  { year: "2017", value: 2 },
  { year: "2018", value: 2.2 },
  { year: "2019", value: 3 },
  { year: "2020", value: 2 },
  { year: "2021", value: 2.5 },
  { year: "2022", value: 2.1 },
  { year: "2023", value: 4 },
];

// Sample data for the pie chart and stats
const dataPie = [
  { name: "Lorem", value: 39.11, change: 2.98, color: "#8884d8" },
  { name: "Lorem", value: 28.02, change: -3.25, color: "#83a6ed" },
  { name: "Lorem", value: 23.13, change: 0.14, color: "#8dd1e1" },
  { name: "Lorem", value: 5.03, change: -1.11, color: "#82ca9d" },
];

const styles = {
  paper: {
    width: "100%",
    p: 2,
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    px: 3,
  },
  label: {
    fontFamily: "Poppins, sans-serif",
    fontSize: { xs: "12px", md: "14px" },
    mb: 1,
    fontWeight: "bold",
  },
  textField: {
    width: "100%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  },
  button: {
    textTransform: "none",
    fontFamily: "Poppins",
    background: "#DFBF41",
    width: { xs: "100%", md: "100%" },
    height: { xs: "38px", md: "48px" },
    borderRadius: "10px",
    fontWeight: "bold",
    "&:hover": { backgroundColor: "#B89B3E" },
    color: "white",
    mt: { md: 3.4, xs: 1 },
  },
};

const Dashboard = () => {
  const [timePeriod, setTimePeriod] = React.useState("Annual");
  const [year, setYear] = React.useState("2021");

  const handleChange = (event) => {
    setTimePeriod(event.target.value);
  };

  const handleChangePie = (event) => {
    setYear(event.target.value);
  };
  return (
    <Box sx={{ p: 0, mt: 2 }}>
      {/* Search Filters */}
      <Paper sx={{ ...styles.paper, mb: 2, mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>Branch :</Typography>
              <TextField
                select
                name="branch"
                // value={filters.branch}
                // onChange={handleInputChange}
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
                {/* {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.branchCode}>
                    {`${branch.branchName} (${branch.branchCode})`}
                  </MenuItem>
                ))} */}
              </TextField>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>From Date :</Typography>
            <TextField
              type="date"
              name="fromDate"
              // value={filters.fromDate}
              // onChange={handleInputChange}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>To Date :</Typography>
            <TextField
              type="date"
              name="toDate"
              // value={filters.toDate}
              // onChange={handleInputChange}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Button
              // onClick={handleSearch}
              sx={styles.button}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {/* Info Cards */}
      <Grid container spacing={2}>
        {[
          {
            icon: <WalletIcon sx={{ fontSize: "46px", mr: 2 }} />,
            title: "Wallet Balance",
            amount: "Amount",
            type: "NEFT Balance",
            background: ["#00AA58", "#008989"],
          },
          {
            icon: <MonetizationOnIcon sx={{ fontSize: "46px", mr: 2 }} />,
            title: "Total Balance",
            amount: "Balance",
            type: "Total Count",
            background: ["#7f4bb9", "#560dae"],
          },
          {
            icon: <PublishedWithChangesIcon sx={{ fontSize: "46px", mr: 2 }} />,
            title: "Total Transfer",
            amount: "Balance",
            type: "Total Count",
            background: ["#af505b", "#af144d"],
          },
          {
            icon: <CreditCardIcon sx={{ fontSize: "46px", mr: 2 }} />,
            title: "Card Transaction",
            amount: "IMPS Balance",
            type: "Total Count",
            background: ["#a82814", "#d8a624"],
          },
          {
            icon: <AccountBalanceWalletIcon sx={{ fontSize: "46px", mr: 2 }} />,
            title: "Cash Balance",
            amount: "Lorem ipsum",
            type: "Lorem ipsum",
            background: ["#600576", "#d567de"],
          },
          {
            icon: <WalletIcon sx={{ fontSize: "46px", mr: 2 }} />,
            title: "Lorem Ipsum",
            amount: "IMPS Balance",
            type: "Total Count",
            background: ["#664cb1", "#280980"],
          },
        ].map((card, index) => (
          <Grid item xs={12} md={4} lg={4} key={index}>
            <Paper
              sx={{
                padding: 2,
                background: `linear-gradient(90deg, ${card.background[0]}, ${card.background[1]})`,
                color: "#fff",
                borderRadius: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {card.icon}
                <Typography sx={{ fontFamily: "Poppins", fontSize: "20px" }}>
                  {card.title}
                </Typography>
              </Box>
              <Divider
                sx={{ background: "white", height: "0.5px", mt: 1, mb: 1 }}
              />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                    {card.amount}
                  </Typography>
                  <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                    0
                  </Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                    {card.type}
                  </Typography>
                  <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                    0
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Statistics Section */}
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              borderRadius: "16px",
              padding: "16px",
              boxShadow: 3,
              width: "100%",
              // height: "345px",
            }}
          >
            <CardContent>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">Statistics</Typography>
                <FormControl size="small" variant="outlined">
                  <Select
                    value={timePeriod}
                    onChange={handleChange}
                    sx={{ borderRadius: "16px", minWidth: 120 }}
                  >
                    <MenuItem value="Annual">Annual</MenuItem>
                    <MenuItem value="Monthly">Monthly</MenuItem>
                    <MenuItem value="Weekly">Weekly</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <Typography
                variant="h5"
                sx={{ marginTop: "16px", marginBottom: "16px" }}
              >
                Lorem Ipsum
              </Typography>

              <ResponsiveContainer
                width="100%"
                height={200}
                style={{ marginLeft: "-25px" }}
              >
                <LineChart data={data}>
                  <CartesianGrid strokeDasharray="4 4" />
                  <XAxis dataKey="year" />
                  <YAxis />
                  <Tooltip />
                  <BarChart>
                    <Bar
                      dataKey="value"
                      fill="#8884d8"
                      radius={[10, 10, 0, 0]}
                    />
                  </BarChart>
                  <Line
                    type="monotone"
                    dataKey="value"
                    stroke="#FF6B6B"
                    strokeWidth={2}
                    dot={{ r: 4 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card
            sx={{
              borderRadius: "16px",
              padding: "16px",
              boxShadow: 3,
              // height: "345px",
            }}
          >
            <CardContent>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6">Statistics</Typography>
                <FormControl size="small" variant="outlined">
                  <Select
                    value={year}
                    onChange={handleChangePie}
                    sx={{ borderRadius: "16px", minWidth: 100 }}
                  >
                    <MenuItem value="2021">2021</MenuItem>
                    <MenuItem value="2020">2020</MenuItem>
                    <MenuItem value="2019">2019</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Typography variant="h5" sx={{ marginTop: "16px" }}>
                Lorem Ipsum
              </Typography>

              <Box
                sx={{
                  marginTop: "16px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: { md: "row", xs: "column" },
                }}
              >
                {/* Pie Chart */}
                <ResponsiveContainer width="100%" height={200}>
                  <PieChart>
                    <Pie
                      data={dataPie}
                      dataKey="value"
                      innerRadius={0}
                      outerRadius={90}
                      paddingAngle={0}
                      stroke="none"
                    >
                      {dataPie.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>

                {/* Data List */}
                <Box>
                  {dataPie.map((entry, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ marginBottom: "8px" }}
                    >
                      <Box display="flex" alignItems="center">
                        <Box
                          sx={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: entry.color,
                            marginRight: "8px",
                          }}
                        />
                        <Typography sx={{ fontSize: "16px" }}>
                          {entry.name}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Typography sx={{ ml: { xs: 1, md: 0 } }}>
                          {entry.value}%
                        </Typography>
                        <Typography
                          sx={{
                            color: entry.change > 0 ? "green" : "red",
                            marginLeft: "8px",
                          }}
                        >
                          ({entry.change > 0 ? "+" : ""}
                          {entry.change}%)
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box
        sx={{
          mt: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Chatbot />
      </Box>
    </Box>
  );
};

export default Dashboard;
