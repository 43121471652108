import {
  Box,
  Button,
  Grid,
  Paper,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import PersonIcon from "@mui/icons-material/Person";

function SBIntraTransfer() {
  const textFieldStyle = {
    width: { xs: "100%", md: "100%" },
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { md: "48px", xs: "40px" },
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
  };

  const ImageUploadWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid #E0E0E0",
    borderRadius: "8px",
    width: "200px",
    height: "200px",
    backgroundColor: "#F9F9F9",
    alignSelf: "flex-start",
  }));

  const UploadButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
    marginTop: theme.spacing(2),
    backgroundColor: "#E0E0E0",
    alignSelf: "flex-start",
  }));

  const Caption = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(1),
    fontSize: "12px",
    color: "#BDBDBD",
    alignSelf: "flex-start",
  }));

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Intra Account Transfer
      </Typography>

      {/* Before Payment */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          gap: 3,
        }}
      >
        <Paper
          sx={{
            padding: 3,
            borderRadius: "20px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            "@media (max-width: 600px)": {
              padding: "10px",
            },
          }}
        >
          {/* Debit Account Details */}
          <Grid container spacing={1}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "36px",
                mb: 1,
                mt: 0,
              }}
            >
              Debit Account Details
            </Typography>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    ...fontStyle,
                  }}
                >
                  Select Account No. <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  select
                  sx={textFieldStyle}
                  InputProps={{
                    style: { borderRadius: "10px" },
                  }}
                ></TextField>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    ...fontStyle,
                  }}
                >
                  Member Code<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  sx={textFieldStyle}
                  disabled
                  InputProps={{
                    style: { borderRadius: "10px", background: "#eee" },
                  }}
                ></TextField>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    ...fontStyle,
                  }}
                >
                  Account Branch<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  sx={textFieldStyle}
                  disabled
                  InputProps={{
                    style: { borderRadius: "10px", background: "#eee" },
                  }}
                ></TextField>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    ...fontStyle,
                  }}
                >
                  Available Balance<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  sx={textFieldStyle}
                  disabled
                  InputProps={{
                    style: { borderRadius: "10px", background: "#eee" },
                  }}
                ></TextField>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    ...fontStyle,
                  }}
                >
                  Mobile Number<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  sx={textFieldStyle}
                  disabled
                  InputProps={{
                    style: { borderRadius: "10px", background: "#eee" },
                  }}
                ></TextField>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              //   container
              direction="column"
            >
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Dr. A/C Photo
              </Typography>
              <ImageUploadWrapper>
                <PersonIcon style={{ fontSize: 100, color: "#BDBDBD" }} />
              </ImageUploadWrapper>
              <UploadButton variant="contained" component="label">
                Choose File
                <input type="file" hidden />
              </UploadButton>
              <Caption>Photo Size: Maximum 10MB</Caption>
            </Grid>
          </Grid>
        </Paper>

        <Paper
          sx={{
            padding: 3,
            borderRadius: "20px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            "@media (max-width: 600px)": {
              padding: "10px",
            },
          }}
        >
          {/* Debit Account Details */}
          <Grid container spacing={1}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "36px",
                mb: 1,
                mt: 0,
              }}
            >
              Credit Account Details
            </Typography>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    ...fontStyle,
                  }}
                >
                  Select Account No.<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  select
                  sx={textFieldStyle}
                  InputProps={{
                    style: { borderRadius: "10px" },
                  }}
                ></TextField>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    ...fontStyle,
                  }}
                >
                  Member Code<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  sx={textFieldStyle}
                  disabled
                  InputProps={{
                    style: { borderRadius: "10px", background: "#eee" },
                  }}
                ></TextField>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    ...fontStyle,
                  }}
                >
                  Account Branch<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  sx={textFieldStyle}
                  disabled
                  InputProps={{
                    style: { borderRadius: "10px", background: "#eee" },
                  }}
                ></TextField>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    ...fontStyle,
                  }}
                >
                  Available Balance<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  sx={textFieldStyle}
                  disabled
                  InputProps={{
                    style: { borderRadius: "10px", background: "#eee" },
                  }}
                ></TextField>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { xs: "16px", md: "18px" },
                    ...fontStyle,
                  }}
                >
                  Mobile Number<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  sx={textFieldStyle}
                  disabled
                  InputProps={{
                    style: { borderRadius: "10px", background: "#eee" },
                  }}
                ></TextField>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              //   container
              direction="column"
            >
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Cr. A/C Photo
              </Typography>
              <ImageUploadWrapper>
                <PersonIcon style={{ fontSize: 100, color: "#BDBDBD" }} />
              </ImageUploadWrapper>
              <UploadButton variant="contained" component="label">
                Choose File
                <input type="file" hidden />
              </UploadButton>
              <Caption>Photo Size: Maximum 10MB</Caption>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      {/* Payment Details */}
      <Paper
        sx={{
          padding: 3,
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          "@media (max-width: 600px)": {
            padding: "10px",
          },
          mt: 3,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Payment Details
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Trf Date *
              </Typography>
              <TextField
                type="date"
                sx={textFieldStyle}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Remarks
              </Typography>
              <TextField
                sx={textFieldStyle}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Amount*
              </Typography>
              <TextField
                sx={textFieldStyle}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>
        </Grid>
        {/* Button */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              fontFamily: "Poppins",
              background: "#DFBF41",
              width: { xs: "100%", md: "157px" },
              height: { md: "48px", xs: "40px" },
              borderRadius: "10px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#B89B3E" },
              color: "white",
              mt: { md: 2.5, xs: 2 },
              mb: { md: 0, xs: 2 },
            }}
          >
            Save
          </Button>

          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              fontFamily: "Poppins",
              background: "#05ACD6",
              width: { xs: "100%", md: "157px" },
              height: { md: "48px", xs: "40px" },
              borderRadius: "10px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#17809a" },
              color: "white",
              mt: { md: 2.5, xs: 2 },
              mb: { md: 0, xs: 2 },
            }}
          >
            New
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default SBIntraTransfer;
