import {
  Box,
  Typography,
  Paper,
  Grid,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from "@mui/material";
import { useState, React } from "react";

const MoPolicyApproval = () => {
  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(false);

  const textFieldStyle = {
    width: { xs: "100%", md: "100%" },
    height: "48px",
    borderRadius: "10px",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: "48px",
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
    opacity: 0.6,
  };

  const handleSearchClick = () => {
    // Set loading state if needed and show the table
    setLoading(true);

    // Simulate a delay for loading (e.g., API call)
    setTimeout(() => {
      setLoading(false);
      setShowTable(true);
    }, 1000); // Simulating loading for 1 second
  };

  return (
    <Box>
      <Typography
        sx={{
          fontSize: { xs: "20px", md: "24px" },
          fontWeight: 600,
          lineHeight: "36px",
          fontFamily: "Poppins",
          mb: 1,
        }}
      >
        Mobile Policy Approval
      </Typography>
      <Paper
        sx={{
          width: "100%",
          p: 2,
          borderRadius: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          px: 3,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Search Box
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              ECode :
            </Typography>
            <TextField
              select
              fullWidth
              variant="outlined"
              sx={textFieldStyle}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              From Date
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              type="date"
              sx={textFieldStyle}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              To Date
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              type="date"
              sx={textFieldStyle}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>
          {/* Search Button */}
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                height: "48px",
                mt: 3,
              }}
            >
              <Button
                variant="contained"
                fullWidth
                sx={{
                  textTransform: "none",
                  fontFamily: "Poppins",
                  background: "#DFBF41",
                  width: "166px",
                  height: "48px",
                  borderRadius: "10px",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "#B89B3E" },
                }}
                onClick={handleSearchClick}
              >
                Search
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* Conditionally display the table after Search is clicked */}
      {showTable && (
        <Paper
          sx={{
            width: "100%",
            mt: 3,
            p: 2,
            borderRadius: "20px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
           <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Policy Data List
        </Typography>
          <TableContainer>
            <Table>
              <TableHead sx={{ backgroundColor: "#0D47A1",borderRadius: "28px", }}>
                <TableRow>
                  <TableCell>
                    <Checkbox />
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>SI No</TableCell>
                  <TableCell sx={{ color: "white" }}>Policy No</TableCell>
                  <TableCell sx={{ color: "white" }}>Applicant Name</TableCell>
                  <TableCell sx={{ color: "white" }}>Policy Date</TableCell>
                  <TableCell sx={{ color: "white" }}>Intro Code</TableCell>
                  <TableCell sx={{ color: "white" }}>Phone No</TableCell>
                  <TableCell sx={{ color: "white" }}>Member Code</TableCell>
                  <TableCell sx={{ color: "white" }}>Plan Code</TableCell>
                  <TableCell sx={{ color: "white" }}>Amount</TableCell>
                  <TableCell sx={{ color: "white" }}>ECode</TableCell>
                  <TableCell sx={{ color: "white" }}>View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Your data goes here */}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Approve and Reject Buttons */}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                backgroundColor: "#D32F2F",
                color: "white",
                fontFamily: "Poppins",
                fontWeight: "bold",
                borderRadius: "10px",
                "&:hover": { backgroundColor: "#B71C1C" },
                mr: 2,
              }}
            >
              Reject
            </Button>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                backgroundColor: "#DFBF41",
                color: "white",
                fontFamily: "Poppins",
                fontWeight: "bold",
                borderRadius: "10px",
                "&:hover": { backgroundColor: "#B89B3E" },
              }}
            >
              Approve
            </Button>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default MoPolicyApproval;
