import axios from "axios";
import { toast } from "react-toastify";

export const loginUser = (userData) => async (dispatch) => {
  console.log(userData);
  try {
    const response = await axios.post(
      // "http://localhost:9191/api/admin/login",
      "https://api.vlnidhi.com/api/public/login",
      userData
    );
    const { Token, username, usertype } = response.data;

    localStorage.setItem("token", Token);
    localStorage.setItem("username", username);
    localStorage.setItem("usertype", usertype);

    dispatch({
      type: "LOGIN_SUCCESS",
      payload: { Token, username, usertype },
    });

    // Navigate to dashboard after successful login
    toast.success("Login Successful!");
    // navigate('/dashboard');
    window.location.href = "/dashboard"; // Use window.location to force a redirect
  } catch (error) {
    const errorMessage = error.response?.data?.message || "Login Failed: Invalid username or password";

    dispatch({
      type: "LOGIN_FAIL",
      payload: { message: errorMessage },
    });
    // Show error toast
    toast.error(errorMessage);
  }
};
