import {
    Container,
    Paper,
    Grid,
    Typography,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    TextField,
    Button,
    Table,
    Box,
    Switch,
    styled,
  } from "@mui/material";import React from 'react'
  import PersonIcon from "@mui/icons-material/Person";
  
  const textFieldStyle = {
    width: { xs: "100%", md: "100%" },
    height: "48px",
    borderRadius: "80%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: "48px",
    },
  };

  const ImageUploadWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid #E0E0E0",
    borderRadius: "8px",
    width: "200px",
    height: "200px",
    backgroundColor: "#F9F9F9",
    alignSelf: "flex-start",
  }));
  
  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    opacity: 0.6,
    width: "100%",
    fontSize: { xs: "12px", md: "14px" },
    mb: 1,
  };

  const UploadButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
    marginTop: theme.spacing(2),
    backgroundColor: "#E0E0E0",
    alignSelf: "flex-start",
  }));
  
  const Caption = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(1),
    fontSize: "12px",
    color: "#BDBDBD",
    alignSelf: "flex-start",
  }));

const FlexiRenewalPayment = () => {
  return (
    <Box>
 <Typography
        sx={{
          // marginTop: { xs: "5%", md: "1%" },
          fontSize: { xs: "20px", md: "24px" },
          fontWeight: 600,
          lineHeight: "36px",
          textAlign: "start",
        }}
      >
       Flexi Deposit/Withdrawal
      </Typography>
      <Paper  sx={{ padding: "2%", marginTop: "1%",borderRadius: "16px"  }}>
      <Typography
          sx={{
            fontSize: { xs: "18px", md: "20px" },
            fontWeight: 900,
            marginBottom: "2%",
          }}
        >
        Policy Details
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
              Txn Date*
            </Typography>
            <TextField fullWidth 
             type='date'
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
              Member Code*
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
       </Grid>
       <Grid container spacing={2} mt={1}>
       <Grid item xs={12} md={6}>
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
              Branch Name*
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="sms-interval-label">Select </InputLabel>
              <Select
                labelId="sms-interval-label"
             
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "10px",
                  height: { xs: "40px", md: "48px" },
                 "& .MuiOutlinedInput-input": {
                  padding: "10px", 
                   height: "100%",
                   },
                }}
              >
              <MenuItem value="MAIN OFFICE">MAIN OFFICE</MenuItem>
              <MenuItem value="BANER OFFICE">BANER OFFICE</MenuItem>
              <MenuItem value="BHAVDHAN OFFICE">BHAVDHAN OFFICE</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
             Name*
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
       </Grid>
       <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
             Select Policy No.*
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="sms-interval-label">Select </InputLabel>
              <Select
                labelId="sms-interval-label"
             
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "10px",
                  height: { xs: "40px", md: "48px" },
                 "& .MuiOutlinedInput-input": {
                  padding: "10px", 
                   height: "100%",
                   },
                }}
              >
              <MenuItem value="MAIN OFFICE">MAIN OFFICE</MenuItem>
              <MenuItem value="BANER OFFICE">BANER OFFICE</MenuItem>
              <MenuItem value="BHAVDHAN OFFICE">BHAVDHAN OFFICE</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
              Mobile Number*
            </Typography>
            <TextField fullWidth 
            sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
       </Grid>
       {/* 4th */}
       <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
            Emp. Code*
            </Typography>
            <TextField fullWidth 
            sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
              Plan Code*
            </Typography>
            <TextField fullWidth 
            sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
       </Grid>
        {/* 15th */}
         <Grid container spacing={2} mt={1}>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            container
            direction="column"
            alignItems="center"
          >
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
              Applicant Photo
            </Typography>
            <ImageUploadWrapper>
              <PersonIcon style={{ fontSize: 100, color: "#BDBDBD" }} />
            </ImageUploadWrapper>
            <UploadButton variant="contained" component="label">
              Choose File
              <input type="file" hidden />
            </UploadButton>
            <Caption>Photo Size: Maximum 10MB</Caption>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            container
            direction="column"
            alignItems="c"
          >
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
              Signature
            </Typography>
            <ImageUploadWrapper>
              {/* Removed PersonIcon for Signature */}
            </ImageUploadWrapper>
            <UploadButton variant="contained" component="label">
              Choose File
              <input type="file" hidden />
            </UploadButton>
            <Caption>Photo Size: Maximum 10MB</Caption>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ marginTop: "2%",borderRadius: "16px" }}>
      <Box
      sx={{
        width: '100%',
        maxWidth: '95%', // Maximum width for large screens
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '15px',
        borderRadius: '100px',        
        margin: 'auto',
      }}
    >
      <h2>Payment Details</h2>
      <Grid container spacing={2}>
        <Grid container spacing={2} mt={1}>
         <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
         Plan Balance *
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
             Txn. Type*
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="sms-interval-label">Select </InputLabel>
              <Select
                labelId="sms-interval-label"
             
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "10px",
                  height: { xs: "40px", md: "48px" },
                 "& .MuiOutlinedInput-input": {
                  padding: "10px", 
                   height: "100%",
                   },
                }}
              >
              <MenuItem value="MAIN OFFICE">MAIN OFFICE</MenuItem>
              <MenuItem value="BANER OFFICE">BANER OFFICE</MenuItem>
              <MenuItem value="BHAVDHAN OFFICE">BHAVDHAN OFFICE</MenuItem>
              </Select>
            </FormControl>
          </Grid>
         </Grid>
         <Grid container spacing={2} mt={1}>
         <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
          Transaction For
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
             Amount *
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
          <Typography
             sx={{
                ...fontStyle,
              }}
            >
            Payment By *
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="sms-interval-label">Select </InputLabel>
              <Select
                labelId="sms-interval-label"
             
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "10px",
                  height: { xs: "40px", md: "48px" },
                 "& .MuiOutlinedInput-input": {
                  padding: "10px", 
                   height: "100%",
                   },
                }}
              >
              <MenuItem value="MAIN OFFICE">MAIN OFFICE</MenuItem>
              <MenuItem value="BANER OFFICE">BANER OFFICE</MenuItem>
              <MenuItem value="BHAVDHAN OFFICE">BHAVDHAN OFFICE</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
             sx={{
                ...fontStyle,
              }}
            >
           Remarks
            </Typography>
            <TextField fullWidth 
             sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
             />
          </Grid>
          <Grid item xs={12} md={6}>
          <Box display="flex" alignItems="center">
            <Switch
            />
            <span>SMS Send *</span>
          </Box>
          </Grid>
         </Grid>
         
        <Grid
          item
          xs={12}
          sx={{textAlign: { xs: "center", sm: "right" },  
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'flex-end',  
                flexWrap: 'wrap',}}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#FFD700',
               color: '#FFFFFF',
              borderRadius: '10px',
              marginRight: { xs: '0', md: '10px' },
              width: { xs: '100%', md: '150px' },
              marginBottom: { xs: '10px', sm: '0' },
             
            }}
          >
            Save
          </Button>
        </Grid>
       </Grid>
       </Box>
      </Paper>
  </Box>
  )
}

export default FlexiRenewalPayment
