import { Box, Typography, Paper,Divider, Grid, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useState } from 'react';
import vllogo from "../../Assets/logo.png";


const CashBook = () => {
  const [showStatement, setShowStatement] = useState(false); // State to control statement visibility


    const textFieldStyle = {
        width: { xs: '100%', md: '100%' },
        height: '48px',
        borderRadius: '10px',
        borderColor: '#D9D9D9CC',
        backgroundColor: '#FFFFFF',
        '& .MuiInputBase-root': {
          height: '48px',
        },
      };
    
      const fontStyle = {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '14px',
        opacity: 0.6,
      };

      const handleViewStatement = () => {
        setShowStatement(true);
      }; 
  return (
    <Box>
      <Typography
        sx={{
          fontSize: { xs: '20px', md: '24px' },
          fontWeight: 600,
          lineHeight: '36px',
          fontFamily: 'Poppins',
          mb: 1,
        }}
      >
      Cash Statement
      </Typography>
      <Paper
      sx={{
        width:'100%',
        p:2,
        borderRadius:'20px',
        boxShadow:'0px 4px 10px rgba(0, 0, 0, 0.1)',
      }}
      >
     <Typography
         sx={{
         fontFamily: 'Poppins',
            fontSize: '20px',
            lineHeight: '36px',
            fontWeight: 500,
            mb: 1,
           }}
          >
        Search information
     </Typography>

     <Grid container spacing={2} alignItems="center">
       <Grid item xs={12} sm={6} md={4}>
         <Typography sx={{ width: '100%', fontSize: { xs: '16px', md: '18px' }, marginBottom: '8px', ...fontStyle }}>
            Select Branch
            </Typography>
            <TextField
              select
              fullWidth
              variant="outlined"
              sx={textFieldStyle}
              InputProps={{ style: { borderRadius: '10px' } }}
            >
              {/* Options for account number */}
            </TextField>
       </Grid>
       <Grid item xs={12} sm={6} md={4}>
           <Typography sx={{ width: '100%', fontSize: { xs: '16px', md: '18px' }, marginBottom: '8px', ...fontStyle }}>
            From Date
            </Typography>
            <TextField
              
              fullWidth
              variant="outlined"
              sx={textFieldStyle}
              InputProps={{ style: { borderRadius: '10px' } }}
            >
              {/* Options for account number */}
            </TextField>
       </Grid>
       <Grid item xs={12} sm={6} md={4}>
           <Typography sx={{ width: '100%', fontSize: { xs: '16px', md: '18px' }, marginBottom: '8px', ...fontStyle }}>
            To Date
            </Typography>
            <TextField
              
              fullWidth
              variant="outlined"
              sx={textFieldStyle}
              InputProps={{ style: { borderRadius: '10px' } }}
            >
              {/* Options for account number */}
            </TextField>
       </Grid>
     </Grid>
     <Grid
          item
          xs={12}
          sx={{textAlign: { xs: "center", sm: "right" },  
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'flex-end',  
                flexWrap: 'wrap',}}
        >
          <Button
            variant="contained"
            // onClick={handleSearchClick}
            sx={{
              backgroundColor: '#FFD700',
               color: '#FFFFFF',
              borderRadius: '10px',
              marginRight: { xs: '0', md: '10px' },
              width: { xs: '100%', md: '150px' },
              marginBottom: { xs: '10px', sm: '0' },     
            }}
            onClick={handleViewStatement}
          >
            View
          </Button>
          <Button
            variant="contained"
            // onClick={handleSearchClick}
            sx={{
              backgroundColor: '#F37D01',
               color: '#FFFFFF',
              borderRadius: '10px',
              marginRight: { xs: '0', md: '10px' },
              width: { xs: '100%', md: '150px' },
              marginBottom: { xs: '10px', sm: '0' },     
            }}
          >
            Show Details
          </Button>
        </Grid>
      </Paper>

      {showStatement && (
        <Paper sx={{ px: 6, py: 3, mt: 2, borderRadius: "20px" }}>
          {/* Header Section */}
          <Box sx={{ marginBottom: 1 }}>
            <Grid container alignItems="center">
              <Grid
                item
                xs={2}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Box sx={{ mr: 2 }}>
                  <img
                    src={vllogo}
                    alt="vllogo"
                    style={{ height: "80px", width: "80px" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: "16px", md: "20px" },
                    mb: 1,
                    fontFamily: "Poppins",
                  }}
                >
                  VENKETLAXMI URBAN PUNE NIDHI LTD
                </Typography>
                <Typography sx={{ fontFamily: "Poppins" }}>
                  Reg. Office: SECOND FLOOR, GOLDEN EMPIRE, BESIDE CROMA, BANER
                  ROAD, BANER - MAHARASHTRA - 411045
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider sx={{ mt: 3, mb: 3, borderBottomWidth: 2 }} />
          
          <Box sx={{ textAlign: 'center' }}>
          <Typography 
            sx={{ 
              fontFamily: 'Poppins', 
              fontSize: '20px', 
              lineHeight: '36px', 
              fontWeight: 500, 
              mb: 2 
            }}
          >
            Bank Statement
          </Typography>
          <Divider 
            sx={{ 
              width: 'fit-content', // Width matches the text
              mx: 'auto', // Centers the divider
              borderBottomWidth: 2, 
              background: "black" 
            }} 
          />
          </Box>
          <TableContainer>
            <Table>
              <TableHead sx={{backgroundColor:'#D1D1D1',borderRadius: '20px',}}>
                <TableRow sx={{backgroundColor:'#D1D1D1',}}>
                  <TableCell sx={{ fontWeight: 'bold' }}>Account No</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Narration</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Credit</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Debit</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Balance</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>0461-YES Bank</TableCell>
                  <TableCell>16/02/2024</TableCell>
                  <TableCell>Carry Forward balance</TableCell>
                  <TableCell>0.00</TableCell>
                  <TableCell>0.00</TableCell>
                  <TableCell>0.00</TableCell>
                </TableRow>
                {/* More rows can be added here */}
              </TableBody>
            </Table>
          </TableContainer>

        </Paper>
      )}
    </Box>
  )
}

export default CashBook
