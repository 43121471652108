import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React from "react";
import vllogo from "../../Assets/logo.png";

function ReceivePaymentsList() {
  const textFieldStyle = {
    width: { xs: "100%", md: "100%" },
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { md: "48px", xs: "40px" },
    },
  };

  const fontStyle = {
    fontFamily: "Poppins",
    fontSize: "14px",
  };

  const rows = [
    {
      slno: 1,
      memberCode: "VL000001",
      memberName: "KALPANA OMPRAKASH JAISWAL",
      shareDate: "12/10/2023",
      shareAmt: 600000,
      noOfShare: 60000,
      folioNo: 1000,
      dnoFromTo: "1 - 60000",
      balance: 592070,
      issueFrom: "VL000001",
      payMode: "Cash",
      txnId: "T102958",
    },
    {
      slno: 2,
      memberCode: "VL000002",
      memberName: "GITA KASHYAP PERTI",
      shareDate: "12/10/2023",
      shareAmt: 200000,
      noOfShare: 20000,
      folioNo: 1001,
      dnoFromTo: "60001 - 80000",
      balance: 200000,
      issueFrom: "VL000002",
      payMode: "Cash",
      txnId: "T102959",
    },
  ];

  // Calculate totals
  const totalShareAmt = rows.reduce((total, row) => total + row.shareAmt, 0);
  const totalNoOfShare = rows.reduce((total, row) => total + row.noOfShare, 0);
  const totalBalance = rows.reduce((total, row) => total + row.balance, 0);

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Recent Payments List
      </Typography>

      <Paper
        sx={{
          padding: 3,
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          "@media (max-width: 600px)": {
            padding: "10px",
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Search Box
        </Typography>

        <Grid container spacing={1}>
          {/* Branch Dropdown */}
          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Search by UTR :
              </Typography>
              <TextField
                select
                sx={textFieldStyle}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>

          {/* From Date */}
          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                From Date :
              </Typography>
              <TextField
                type="date"
                sx={{
                  ...textFieldStyle,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Box>
          </Grid>

          {/* To Date */}
          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                To Date :
              </Typography>
              <TextField
                type="date"
                sx={{
                  ...textFieldStyle,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  fontFamily: "Poppins",
                  background: "#DFBF41",
                  width: { xs: "100%", md: "157px" },
                  height: { md: "48px", xs: "40px" },
                  borderRadius: "10px",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "#B89B3E" },
                  color: "white",
                  mt: { md: 2.5, xs: 1 },
                  mb: { md: 0, xs: 2 },
                }}
              >
                Search
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Paper
        sx={{
          padding: 3,
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          "@media (max-width: 600px)": {
            padding: "10px",
          },
          mt: 2,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Recent Payment List
        </Typography>

        <Box sx={{ marginTop: 4 }}>
          <TableContainer
            component={Paper}
            sx={{ borderRadius: "20px 20px 0px 0px" }}
          >
            <Table>
              <TableHead sx={{ background: "#413AF1", color: "white" }}>
                <TableRow>
                  <TableCell sx={{ color: "#fff" }}>
                    <strong>Sl No</strong>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <strong>Credit Ac No</strong>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <strong>UTR</strong>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <strong>Remitter Account</strong>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <strong>Remitter IFSC</strong>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <strong>Remitter Name</strong>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <strong>Payment Time</strong>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <strong>Trf Mode</strong>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <strong>Amount</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
}

export default ReceivePaymentsList;
