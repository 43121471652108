import {
  Paper,
  Grid,
  Box,
  Typography,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Button,
} from "@mui/material";
import React, { useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { styled } from "@mui/system";
import PersonIcon from "@mui/icons-material/Person";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import Swal from "sweetalert2";
import dayjs from "dayjs";

const SavingAccountClose = () => {
  const [accountNos, setAccountNos] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);
  const [selectedAccountNo, setSelectedAccountNo] = useState("");
  const [accountDetails, setAccountDetails] = useState({});
  const [closingBranches, setClosingBranches] = useState([]);
  const [formData, setFormData] = useState({
    savingAccountNo: "",
    memberCode: "",
    mobileNo: "",
    openingBranch: "",
    openingDate: "",
    balance: "",
    piadInterest: "",
    interestDue: "",
    netBalance: "",
    closingBranch: "",
    paymentBy: "",
    remarks: "",
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    // Fetch account numbers
    axios
      .get("https://api.vlnidhi.com/api/admin/saving-accounts/getAll", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const formattedAccountOptions = response.data.map((account) => ({
          value: account.savingAccountNumber,
          label: `${account.member?.memberName || "Unknown Member"} - ${
            account.savingAccountNumber
          }`,
        }));
        setAccountOptions(formattedAccountOptions);
      })
      .catch((error) =>
        console.error("Error fetching account numbers:", error)
      );

    // Fetch closing branches
    axios
      .get("https://api.vlnidhi.com/api/admin/getallbid", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setClosingBranches(response.data);
      })
      .catch((error) =>
        console.error("Error fetching closing branches:", error)
      );
  }, []);

  useEffect(() => {
    if (selectedAccountNo) {
      const token = localStorage.getItem("token");
      // Fetch account details based on selected account number
      axios
        .get(
          `https://api.vlnidhi.com/api/admin/interest/${selectedAccountNo}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          setAccountDetails(data);
          setFormData({
            savingAccountNo: data.savingAccountNo,
            memberCode: data.memberCode,
            mobileNo: data.mobileNo,
            openingBranch: data.openingBranch,
            openingDate: data.openingDate,
            balance: data.balance,
            piadInterest: data.piadInterest,
            interestDue: data.interestDue,
            netBalance: data.netBalance,
            closingBranch: "",
            paymentBy: "",
            remarks: "",
          });
        })
        .catch((error) =>
          console.error("Error fetching account details:", error)
        );
    }
  }, [selectedAccountNo]);

  const handleAccountNoChange = (event) => {
    setSelectedAccountNo(event.target.value);
  };

  const handleFormChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleCloseAccount = () => {
    const token = localStorage.getItem("token");
    axios
      // .put("http://localhost:9191/api/saving-accounts/closeaccount", formData)
      .put(
        "https://api.vlnidhi.com/api/admin/saving-accounts/closeaccount",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: `Account closed successfully`,
          icon: "success",
          confirmButtonText: "OK",
        });
        handleNew();
      })
      .catch((error) => console.error("Error closing account:", error));
  };

  const handleNew = () => {
    setSelectedAccountNo("");
    setAccountDetails({});
    setFormData({
      savingAccountNo: "",
      memberCode: "",
      mobileNo: "",
      openingBranch: "",
      openingDate: "",
      balance: "",
      piadInterest: "",
      interestDue: "",
      netBalance: "",
      closingBranch: "",
      paymentBy: "",
      remarks: "",
      memberCode: "",
    });
  };

  const ImageUploadWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid #E0E0E0",
    borderRadius: "8px",
    width: "200px",
    height: "200px",
    backgroundColor: "#F9F9F9",
    alignSelf: "flex-start",
  }));

  const UploadButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
    marginTop: theme.spacing(2),
    backgroundColor: "#E0E0E0",
    alignSelf: "flex-start",
  }));

  const Caption = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(1),
    fontSize: "12px",
    color: "#BDBDBD",
    alignSelf: "flex-start",
  }));

  const textFieldStyle = {
    width: { xs: "100%", md: "100%" },
    height: "48px",
    borderRadius: "80%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: "48px",
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
    opacity: 0.6,
  };

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Saving A/C Close
      </Typography>

      <Paper sx={{ padding: "2%", marginTop: "1%", borderRadius: "20px" }}>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
          }}
        >
          Savings Details
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              Select Acc. No.*
            </Typography>
            <FormControl fullWidth>
              <TextField
                select
                value={selectedAccountNo}
                onChange={handleAccountNoChange}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              >
                {accountOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              Member Code
            </Typography>
            <TextField
              fullWidth
              disabled
              value={accountDetails.memberCode || ""}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
        </Grid>

        {/* second */}
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              Mobile Number
            </Typography>
            <TextField
              fullWidth
              disabled
              value={accountDetails.mobileNo || ""}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              Opening Branch
            </Typography>
            <TextField
              fullWidth
              disabled
              value={accountDetails.openingBranch || ""}
              type="text"
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
        </Grid>
        {/* third */}
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              Opening Date
            </Typography>
            <TextField
              fullWidth
              disabled
              value={accountDetails.openingDate || ""}
              type="date"
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              Current Balance
            </Typography>
            <TextField
              fullWidth
              disabled
              value={accountDetails.balance || ""}
              type="text"
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
        </Grid>
        {/* forth */}
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              Paid Interest
            </Typography>
            <TextField
              fullWidth
              disabled
              value={accountDetails.piadInterest || ""}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              Interest Due
            </Typography>
            <TextField
              fullWidth
              disabled
              value={accountDetails.interestDue || ""}
              type="text"
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
        </Grid>
        {/* fifth */}
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              Net Amount
            </Typography>
            <TextField
              fullWidth
              disabled
              value={accountDetails.netBalance || ""}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
        </Grid>
        {/* Save Button */}
        <Grid container spacing={2} mt={1}>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            container
            direction="column"
            alignItems="center"
          >
            <Typography
              sx={{
                width: "100%",
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              Applicant Photo
            </Typography>
            <ImageUploadWrapper>
              <PersonIcon style={{ fontSize: 100, color: "#BDBDBD" }} />
            </ImageUploadWrapper>
            <UploadButton variant="contained" component="label">
              Choose File
              <input type="file" hidden />
            </UploadButton>
            <Caption>Photo Size: Maximum 10MB</Caption>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            container
            direction="column"
            alignItems="c"
          >
            <Typography
              sx={{
                width: "100%",
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              Signature
            </Typography>
            <ImageUploadWrapper>
              {/* Removed PersonIcon for Signature */}
            </ImageUploadWrapper>
            <UploadButton variant="contained" component="label">
              Choose File
              <input type="file" hidden />
            </UploadButton>
            <Caption>Photo Size: Maximum 10MB</Caption>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ padding: "2%", marginTop: "2%", borderRadius: "20px" }}>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 0,
            mt: 0,
          }}
        >
          Payment Details
        </Typography>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              Closing Branch*
            </Typography>
            <FormControl fullWidth>
              <TextField
                select
                name="closingBranch"
                value={formData.closingBranch}
                onChange={handleFormChange}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              >
                {closingBranches.map((branch) => (
                  <MenuItem key={branch.branchId} value={branch.branchName}>
                    {branch.branchName} - {branch.branchCode}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              Closing Date
            </Typography>
            <TextField
              fullWidth
              
              name="closingDate"
              value={dayjs().format("DD-MM-YYYY")} // Sets today's date
              disabled
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              Payment By
            </Typography>
            <FormControl fullWidth>
              <TextField
                select
                name="paymentBy"
                value={formData.paymentBy}
                onChange={handleFormChange}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              >
                <MenuItem value="CASH">CASH</MenuItem>
                <MenuItem value="CHEQUE">CHEQUE</MenuItem>
                <MenuItem value="ONLINE">ONLINE</MenuItem>
                <MenuItem value="NEFT">NEFT</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              Remarks
            </Typography>
            <TextField
              fullWidth
              name="remarks"
              value={formData.remarks}
              onChange={handleFormChange}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            mt: 4,
            "@media (max-width: 600px)": {
              flexDirection: "column",
              alignItems: "center",
            },
          }}
        >
          <Button
            sx={{
              width: "247px",
              height: "48px",
              borderRadius: "10px",
              fontWeight: "bold",
              textTransform: "none",
              color: "#FFFFFF",
              fontFamily: "Poppins",
              background: "#E0C042",
              "&:hover": {
                backgroundColor: "#c5a129",
              },
              "@media (max-width: 600px)": {
                width: "100%",
              },
            }}
            onClick={handleCloseAccount}
          >
            Close Account
          </Button>
          <Button
            sx={{
              width: "247px",
              height: "48px",
              borderRadius: "10px",
              fontWeight: "bold",
              textTransform: "none",
              color: "#FFFFFF",
              fontFamily: "Poppins",
              backgroundColor: "#0000FF",
              "&:hover": {
                backgroundColor: "#0000e0",
              },
              "@media (max-width: 600px)": {
                width: "100%",
              },
            }}
            onClick={handleNew}
          >
            New
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default SavingAccountClose;
