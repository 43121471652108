import React from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

function AccountDetails() {
  const textFieldStyle = {
    width: { xs: "100%", md: "100%" },
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    width: "100%",
    fontSize: { xs: "12px", md: "14px" },
  };
  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Wallet Account Info
      </Typography>
      <Paper
        sx={{
          padding: 3,
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          "@media (max-width: 600px)": {
            padding: "10px",
          },
        }}
      >
        <Box>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "36px",
              mb: 1,
              mt: 0,
            }}
          >
            IMPS/NEFT Wallet
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Account No. : *
              </Typography>
              <TextField
                sx={{ ...textFieldStyle }}
                InputProps={{ style: { borderRadius: "10px" } }}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                IFSC Code : *
              </Typography>
              <TextField
                sx={{ ...textFieldStyle }}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Ledger Balance : *
              </Typography>
              <TextField
                sx={{ ...textFieldStyle }}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Available Balance :
              </Typography>
              <TextField
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Typography sx={{ color: "red", mt: 3 }}>
          Note : Amount in Wallet should be Transferred from Company's Bank
          Current Account Only. (Which canceled cheque/statement was submitted).
        </Typography>
        <Typography sx={{ color: "red", mt:2 }}>
          Otherwise account will be locked for 72hrs.You can transfer money to
          wallet through NEFT/RTGS/IMPS from that particular account only.
        </Typography>
      </Paper>
    </Box>
  );
}

export default AccountDetails;
