import { Box,Typography,Paper,Grid,TextField,Button } from '@mui/material'
import React from 'react'

const MoIrregularEMIApproval = () => {
    const textFieldStyle = {
        width: { xs: "100%", md: "100%" },
        height: "48px",
        borderRadius: "10px",
        borderColor: "#D9D9D9CC",
        backgroundColor: "#FFFFFF",
        "& .MuiInputBase-root": {
          height: "48px",
        },
      };
 
      const fontStyle = {
        fontFamily: "Poppins, sans-serif",
        fontSize: "14px",
        opacity: 0.6,
      };
  return (
    <Box>
          <Typography 
         sx={{
         fontSize: { xs: "20px", md: "24px" },
          fontWeight: 600,
          lineHeight: "36px",
          fontFamily: "Poppins",
          mb: 1,
          }}>
        Mobile Irregular EMI Approval
           </Typography>
           <Paper
        sx={{
        width:'100%',
        p:2,
        borderRadius:'20px',
        boxShadow:'0px 4px 10px rgba(0, 0, 0, 0.1)',
        px:3,
       }}
    >
    <Typography
    sx={{
          fontFamily:'Poppins',
          fontSize:'20px',
          fontWeight:600,
          lineHeight:'36px',
          mb:1,
          mt:0,
        }}
    >
     Search Box
    </Typography>
    <Grid container spacing={2} alignItems="center">
    <Grid item xs={12} sm={6} md={3}>
      <Typography 
           sx={{
             width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
             }}
      >
        ECode :
      </Typography>
      <TextField
              select
              fullWidth
              variant="outlined"
              sx={textFieldStyle}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
         <Typography
         sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
         >
           From Date
         </Typography>
         <TextField
              fullWidth
              variant="outlined"
              type="date"
              sx={textFieldStyle}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              To Date
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              type="date"
              sx={textFieldStyle}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
    </Grid>
                      {/* Search Button */}
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                height: "48px",
                mt: 3,
              }}
            >
              <Button
                variant="contained"
                fullWidth
                sx={{
                  textTransform: "none",
                  fontFamily: "Poppins",
                  background: "#DFBF41",
                  width: "166px",
                  height: "48px",
                  borderRadius: "10px",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "#B89B3E" },
                }}
              >
                Search
              </Button>
            </Box>
          </Grid>
          </Grid>
    </Paper>
    </Box>
  )
}

export default MoIrregularEMIApproval
