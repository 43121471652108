import React, { useState, useEffect, useRef } from "react";
import { Box, Button, TextField, Grid, Typography, Paper } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { styled } from "@mui/system";

const RenewalPayment = () => {
  const textFieldStyle = {
    // width: { xs: "290px", lg: "578px" },
    width: { xs: "100%", lg: "100%" },
    height: "48px",
    borderRadius: "80%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: "48px",
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
    // opacity: 0.6,
  };

  const ImageUploadWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid #E0E0E0",
    borderRadius: "8px",
    width: "200px",
    height: "200px",
    backgroundColor: "#F9F9F9",
    alignSelf: "flex-start",
  }));

  const UploadButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
    marginTop: theme.spacing(2),
    backgroundColor: "#E0E0E0",
    alignSelf: "flex-start",
  }));

  const Caption = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(1),
    fontSize: "12px",
    color: "#BDBDBD",
    alignSelf: "flex-start",
  }));

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Monthly Renewal
      </Typography>
      <Paper
        sx={{
          width: "100%",
          p: 2,
          borderRadius: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          px: 3,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Search Box
        </Typography>
        <Grid container spacing={2} alignItems="center">
          {/* Select A/C No. */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              Policy No.<span style={{color: "red"}}>*</span>
            </Typography>
            <TextField
              select
              fullWidth
              variant="outlined"
              sx={textFieldStyle}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          {/* From Date */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              Renewal Date<span style={{color: "red"}}>*</span>
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              type="date"
              sx={textFieldStyle}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>

          {/* Branch Name */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
                ...fontStyle,
              }}
            >
              Branch Name<span style={{color: "red"}}>*</span>
            </Typography>
            <TextField
              select
              fullWidth
              variant="outlined"
              sx={textFieldStyle}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            ></TextField>
          </Grid>

          {/* Search Button */}
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                height: "48px",
                mt: 3,
              }}
            >
              <Button
                variant="contained"
                fullWidth
                sx={{
                  textTransform: "none",
                  fontFamily: "Poppins",
                  background: "#DFBF41",
                  width: "166px",
                  height: "48px",
                  borderRadius: "10px",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "#B89B3E" },
                }}
              >
                Search
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ borderRadius: "20px", p: 3, mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Policy Date<span style={{color: "red"}}>*</span>
            </Typography>
            <TextField
              type="date"
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Last Inst. Paid<span style={{color: "red"}}>*</span>
            </Typography>
            <TextField
              fullWidth
              disabled
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Maturity Date<span style={{color: "red"}}>*</span>
            </Typography>
            <TextField
              type="date"
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Due Date<span style={{color: "red"}}>*</span>
            </Typography>
            <TextField
              type="date"
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Member Code<span style={{color: "red"}}>*</span>
            </Typography>
            <TextField
              fullWidth
              disabled
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Late Fine<span style={{color: "red"}}>*</span>
            </Typography>
            <TextField
              fullWidth
              disabled
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Applicant Name<span style={{color: "red"}}>*</span>
            </Typography>
            <TextField
              fullWidth
              disabled
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              No. Installment Paid<span style={{color: "red"}}>*</span>
            </Typography>
            <TextField
              fullWidth
              disabled
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Mobile No<span style={{color: "red"}}>*</span>
            </Typography>
            <TextField
              fullWidth
              disabled
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Payment By<span style={{color: "red"}}>*</span>
            </Typography>
            <TextField
              fullWidth
              select
              //   disabled
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Policy Amount<span style={{color: "red"}}>*</span>
            </Typography>
            <TextField
              fullWidth
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Advisor/Collector<span style={{color: "red"}}>*</span>
            </Typography>
            <TextField
              fullWidth
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Scheme Name<span style={{color: "red"}}>*</span>
            </Typography>
            <TextField
              fullWidth
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Name<span style={{color: "red"}}>*</span>
            </Typography>
            <TextField
              fullWidth
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid container item xs={12} md={6} spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Scheme Term<span style={{color: "red"}}>*</span>
              </Typography>
              <TextField
                fullWidth
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Mode
              </Typography>
              <TextField
                fullWidth
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Remark
            </Typography>
            <TextField
              fullWidth
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Maturity Amt.<span style={{color: "red"}}>*</span>
            </Typography>
            <TextField
              fullWidth
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Net Deposited<span style={{color: "red"}}>*</span>
            </Typography>
            <TextField
              fullWidth
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Amount Due<span style={{color: "red"}}>*</span>
            </Typography>
            <TextField
              fullWidth
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              SMS Status<span style={{color: "red"}}>*</span>
            </Typography>
            <TextField
              fullWidth
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Advisor Code<span style={{color: "red"}}>*</span>
            </Typography>
            <TextField
              fullWidth
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid container item mt={1}>
            {/* Applicant Photo */}
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              //   container
              direction="column"
            >
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Applicant Photo
              </Typography>
              <ImageUploadWrapper>
                <PersonIcon style={{ fontSize: 100, color: "#BDBDBD" }} />
              </ImageUploadWrapper>
              <UploadButton variant="contained" component="label">
                Choose File
                <input type="file" hidden />
              </UploadButton>
              <Caption>Photo Size: Maximum 10MB</Caption>
            </Grid>

            {/* Signature */}
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              //   container
              direction="column"
            >
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Signature
              </Typography>
              <ImageUploadWrapper>
                {/* Removed PersonIcon for Signature */}
              </ImageUploadWrapper>
              <UploadButton variant="contained" component="label">
                Choose File
                <input type="file" hidden />
              </UploadButton>
              <Caption>Photo Size: Maximum 10MB</Caption>
            </Grid>
          </Grid>
        </Grid>

        {/* Button */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            mb: 2,
            flexDirection: { md: "row", xs: "column" },
          }}
        >
          <Grid>
            <Button
              variant="contained"
              sx={{
                background: "#F37D01",
                borderRadius: "10px",
                textTransform: "none",
                width: { md: "185px", xs: "100%" },
                ":hover": { background: "#af6211" },
                height: "48px",
                fontWeight: "bold",
              }}
            >
              View Installment
            </Button>
          </Grid>
          <Grid
            sx={{
              gap: 1,
              display: "flex",
              flexDirection: {md: "row", xs : "column"},
              justifyContent: { md: "normal", xs: "space-between" },
              mt: { md: 0, xs: 1 },
            }}
          >
              <Button
                variant="contained"
                sx={{
                  background: "#E8C30B",
                  borderRadius: "10px",
                  textTransform: "none",
                  width: { md: "130px", xs: "100%" },
                  ":hover": { background: "#af6211" },
                  height: "48px",
                  fontWeight: "bold",
                  // ml : 1
                }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                sx={{
                  background: "#05ACD6",
                  borderRadius: "10px",
                  textTransform: "none",
                  width: { md: "130px", xs: "100%" },
                  height: "48px",
                  ":hover": { background: "#af6211" },
                  fontWeight: "bold",
                  // mr: 1,
                }}
              >
                Print
              </Button>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default RenewalPayment;
