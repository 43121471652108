import React, { useState, useEffect } from 'react';
import {
  Box, Grid, Paper, Typography, Button, TextField, 
  Card, FormControl, MenuItem, InputLabel, Select, 
  TableContainer, Table, TableHead, TableRow, TableCell, 
  TableBody, IconButton
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from 'axios';  // Import axios for API requests

const LedgerMaster = () => {
  const [ledgerName, setLedgerName] = useState('');
  const [selectGroup, setSelectGroup] = useState('');
  const [ledgers, setLedgers] = useState([]);

  // Get token from localStorage
  const token = localStorage.getItem("token");

  // Move fetchLedgers outside of useEffect
  const fetchLedgers = async () => {
    try {
      const response = await axios.get('https://api.vlnidhi.com/api/admin/ledgers', {
        headers: { Authorization: `Bearer ${token}` }  // Add token to Authorization header
      });
      setLedgers(response.data); // Set the fetched data to state
    } catch (error) {
      console.error("Error fetching ledgers:", error);
    }
  };

  // Fetch the list of ledgers using useEffect
  useEffect(() => {
    fetchLedgers();  // Call fetchLedgers inside useEffect
  }, [token]);

  // Handler to post new ledger data
  const handleSave = async () => {
    const data = {
      ledgerName,
      selectGroup
    };

    try {
      const response = await axios.post('https://api.vlnidhi.com/api/admin/ledgers', data, {
        headers: { Authorization: `Bearer ${token}` }  // Add token to Authorization header
      });

      if (response.status === 200) {
        alert('Ledger successfully saved');
        // Optionally, refresh the ledger list after adding
        setLedgerName('');
        setSelectGroup('');
        fetchLedgers(); // Fetch updated ledgers after posting
      }
    } catch (error) {
      console.error('Error saving ledger:', error);
    }
  };

  const textFieldStyle = {
    width: { xs: "100%", md: "100%" },
    height: "48px",
    borderRadius: "80%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: "48px",
    },
  };
  
    const fontStyle = {
      fontFamily: "Poppins, sans-serif",
      opacity: 0.6,
      width: "100%",
      fontSize: { xs: "12px", md: "14px" },
      mb: 1,
    };

  return (
    <Box>
      <Typography 
        sx={{
          fontSize: { xs: '20px', md: '24px' },
          fontWeight: 600,
          lineHeight: '36px',
          textAlign: 'start',
        }}
      >
        Ledger Master
      </Typography>
      
      <Paper 
        sx={{
          marginTop: '1%',
          borderRadius: '16px',
          padding: '2%',
        }}
      >
        <Typography 
          sx={{
            fontSize: { xs: '18px', md: '20px' },
            fontWeight: 500,
            marginBottom: '2%',
            lineHeight: '30px',
          }}
        >
          Add Ledger Master
        </Typography>

        <Grid container spacing={1} mt={1}>
  <Grid item xs={12} md={6}>
    <Typography 
      sx={{
        ...fontStyle,
      }}
    >
      Ledger Name *
    </Typography>
    <TextField 
      fullWidth 
      sx={{
        ...textFieldStyle,
      }}
      InputProps={{
        style: {
          borderRadius: "10px",
        },
      }}
      value={ledgerName}
      onChange={(e) => setLedgerName(e.target.value)}
    />
  </Grid>

  <Grid item xs={12} md={6}>
    <Typography sx={{ ...fontStyle }}>
      Select Group *
    </Typography>
    <FormControl fullWidth>
      <Select
        value={selectGroup}
        onChange={(e) => setSelectGroup(e.target.value)}
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          height: "48px", // Ensure same height as TextField
          "& .MuiOutlinedInput-input": {
            padding: "10px", 
            height: "100%", // Ensure input fills the entire height
          },
        }}
      >
        <MenuItem value="CAPITAL_ACCOUNT">CAPITAL ACCOUNT</MenuItem>
        <MenuItem value="MEMBERS_SHARE">MEMBERS SHARE</MenuItem>
        <MenuItem value="LOANS_LIABILITY">LOANS LIABILITY</MenuItem>
        {/* Add other options as needed */}
      </Select>
    </FormControl>
  </Grid>
</Grid>


        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            mt: 4,
            "@media (max-width: 600px)": {
              flexDirection: "column",
              alignItems: "center",
            },
          }}
        >
          <Button
            onClick={handleSave}  // Call save handler on click
            sx={{
              width: "150px",
              height: "48px",
              borderRadius: "10px",
              fontWeight: "bold",
              textTransform: "none",
              color: "#FFFFFF",
              backgroundColor: "#E0C042",
              "&:hover": { backgroundColor: "#c5a129" },
              "@media (max-width: 600px)": { width: "100%" },
            }}
          >
            Save
          </Button>
          <Button
            sx={{
              width: "150px",
              height: "48px",
              borderRadius: "10px",
              fontWeight: "bold",
              textTransform: "none",
              color: "#FFFFFF",
              backgroundColor: "#0000FF",
              "&:hover": { backgroundColor: "#0000e0" },
              "@media (max-width: 600px)": { width: "100%" },
            }}
          >
            Clear
          </Button>
        </Box>
      </Paper>

      <Paper sx={{padding:'2%', marginTop: "1%", borderRadius: "16px", padding: "2%" }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: "#3c3cc1", borderRadius: "16px" }}>
              <TableRow>
                <TableCell sx={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                  Ledger Name
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                  Group Name
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                  View
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ledgers.map((ledger) => (
                <TableRow key={ledger.id}>
                  <TableCell sx={{ textAlign: "center" }}>{ledger.ledgerName}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>{ledger.selectGroup}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <IconButton>
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default LedgerMaster;
