import React from "react";
import {
  Grid,
  Box,
  Button,
  Card,
  Typography,
  TextField,
  Paper,
} from "@mui/material";
import SummaryCard from "../../Components/GenericComponent/SummaryCard";

const cardData = [
  { title: "Member", number: 0, background: ["#360C5E", "#7D1DD6"] },
  { title: "Share Issue", number: 0, background: ["#0C5F55", "#17BFA8"] },
  { title: "RD Policy", number: 0, background: ["#E65100E5", "#F37D01"] },
  { title: "Daily Policy", number: 0, background: ["#360C5E", "#7D1DD6"] },
  { title: "RD Renewal", number: 0, background: ["#0C5F55", "#17BFA8"] },
  { title: "Saving Acc", number: 0, background: ["#E65100E5", "#F37D01"] },
  { title: "Loan", number: 0, background: ["#360C5E", "#7D1DD6"] },
  { title: "Gold Loan", number: 0, background: ["#0C5F55", "#17BFA8"] },
  { title: "Group Loan", number: 0, background: ["#E65100E5", "#F37D01"] },
];

const styles = {
  paper: {
    width: "100%",
    p: 2,
    borderRadius: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    px: 3,
  },
  label: {
    fontFamily: "Poppins, sans-serif",
    fontSize: { xs: "12px", md: "14px" },
    mb: 1,
    fontWeight: "bold",
  },
  textField: {
    width: "100%",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  },
  button: {
    textTransform: "none",
    fontFamily: "Poppins",
    background: "#DFBF41",
    width: { xs: "100%", md: "100%" },
    height: { xs: "38px", md: "48px" },
    borderRadius: "10px",
    fontWeight: "bold",
    "&:hover": { backgroundColor: "#B89B3E" },
    color: "white",
    mt: { md: 3.4, xs: 1 },
  },
};

const Home = () => {
  return (
    <Box>
      {/* Search Filters */}
      <Paper sx={{ ...styles.paper, mb: 2, mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>Branch :</Typography>
              <TextField
                select
                name="branch"
                // value={filters.branch}
                // onChange={handleInputChange}
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
                {/* {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.branchCode}>
                    {`${branch.branchName} (${branch.branchCode})`}
                  </MenuItem>
                ))} */}
              </TextField>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>From Date :</Typography>
            <TextField
              type="date"
              name="fromDate"
              // value={filters.fromDate}
              // onChange={handleInputChange}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={{ ...styles.label }}>To Date :</Typography>
            <TextField
              type="date"
              name="toDate"
              // value={filters.toDate}
              // onChange={handleInputChange}
              sx={styles.textField}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Button
              // onClick={handleSearch}
              sx={styles.button}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {/* Responsive Grid for Cards */}
      <Grid container spacing={4}>
        {cardData.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <SummaryCard
              title={item.title}
              number={item.number}
              background={item.background}
            />
          </Grid>
        ))}
      </Grid>

      {/* Footer Buttons */}
      <Box
        display="flex"
        width="100%"
        justifyContent="center"
        marginTop={3}
        gap={2}
      >
        <Button
          variant="contained"
          color="warning"
          sx={{
            // margin: 1,
            textTransform: "none",
            width: "100%",
            borderRadius: "10px",
            fontWeight: "bold",
            height: "52px",
            fontSize: "16px",
          }}
        >
          Bank Balance
        </Button>
        <Button
          variant="contained"
          color="error"
          sx={{
            // margin: 1,
            textTransform: "none",
            width: "100%",
            borderRadius: "10px",
            fontWeight: "bold",
            height: "52px",
            fontSize: "16px",
          }}
        >
          Overall Business
        </Button>
      </Box>
    </Box>
  );
};

export default Home;
