import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function AddDepartment() {
  const [departmentName, setDepartmentName] = useState("");
  const [departmentList, setDepartmentList] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const token = localStorage.getItem("token");

  // Function to fetch the department list
  const fetchDepartments = async () => {
    try {
      const response = await axios.get(
        "https://api.vlnidhi.com/api/admin/departments/all",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDepartmentList(response.data); // Assuming the API returns the list of departments
      setShowTable(true);
    } catch (error) {
      console.error("Error fetching department list:", error);
    }
  };

  // Function to handle saving the department
  const handleSave = async () => {
    try {
      await axios.post(
        "https://api.vlnidhi.com/api/admin/departments/create",
        { departmentName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // After successful save, fetch the updated list of departments
      fetchDepartments();
      setDepartmentName(""); // Clear the input field
    } catch (error) {
      console.error("Error saving department:", error);
    }
  };

  // Fetch the department list when the component loads
  useEffect(() => {
    fetchDepartments();
  }, []);

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Add Department
      </Typography>

      <Paper
        sx={{
          width: "100%",
          p: 2,
          borderRadius: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
          }}
        >
          Add Department Master
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: { md: "row", xs: "column" },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", mr: 2 }}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                mb: 1,
                mt: 2,
                fontFamily: "Poppins, sans-serif",
                fontSize: "14px",
              }}
            >
              Department Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              value={departmentName}
              onChange={(e) => setDepartmentName(e.target.value)}
              sx={{
                width: { xs: "100%", md: "495px" },
                height: "48px",
                borderRadius: "10px",
                borderColor: "#D9D9D9CC",
                backgroundColor: "#FFFFFF",
                "& .MuiInputBase-root": {
                  height: "48px",
                },
              }}
              InputProps={{
                style: { borderRadius: "10px" },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { md: "row", xs: "column" },
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              onClick={handleSave}
              // variant="contained"
              sx={{
                textTransform: "none",
                fontFamily: "Poppins",
                background: "#DFBF41",
                width: { xs: "50%", md: "157px" },
                height: "48px",
                borderRadius: "10px",
                fontWeight: "bold",
                "&:hover": { backgroundColor: "#B89B3E" },
                mt: 5,
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Paper>

      {/* {showTable && ( */}
      <Paper sx={{ p: 2, mt: 2, borderRadius: "20px", minHeight: "200px" }}>
        <Box sx={{ mt: 0 }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "36px",
              mb: 2,
            }}
          >
            Department List
          </Typography>
          <TableContainer component={Paper} sx={{ borderRadius: "12px" }}>
            <Table size="small">
              <TableHead sx={{ backgroundColor: "#413AF1", color: "#fff" }}>
                <TableRow>
                  <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                    Sl No
                  </TableCell>
                  <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                    Department Code
                  </TableCell>
                  <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                    Department Name
                  </TableCell>
                  {/* <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                    Action
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {departmentList.map((department, index) => (
                  <TableRow key={department.departmentId}>
                    <TableCell sx={{ textAlign: "center" }}>
                      {index + 1}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {department.departmentCode}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {department.departmentName}
                    </TableCell>
                    {/* <TableCell sx={{ textAlign: "center" }}> */}
                      {/* You can add action buttons like edit/delete here */}
                      {/* <Button
                        variant="outlined"
                        startIcon={<VisibilityOutlinedIcon />}
                        size="small"
                      >
                        View
                      </Button> */}
                      {/* <EditIcon sx={{mr: 3, color: "green"}} /><DeleteIcon sx={{ color: "red"}} />
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
      {/* )} */}
    </Box>
  );
}

export default AddDepartment;
