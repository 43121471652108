import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React from "react";
import vllogo from "../../Assets/logo.png";

function ShareReport() {
  const textFieldStyle = {
    width: { xs: "100%", md: "100%" },
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { md: "48px", xs: "40px" },
    },
  };

  const fontStyle = {
    fontFamily: "Poppins",
    fontSize: "14px",
  };

  const rows = [
    {
      slno: 1,
      memberCode: "VL000001",
      memberName: "KALPANA OMPRAKASH JAISWAL",
      shareDate: "12/10/2023",
      shareAmt: 600000,
      noOfShare: 60000,
      folioNo: 1000,
      dnoFromTo: "1 - 60000",
      balance: 592070,
      issueFrom: "VL000001",
      payMode: "Cash",
      txnId: "T102958",
    },
    {
      slno: 2,
      memberCode: "VL000002",
      memberName: "GITA KASHYAP PERTI",
      shareDate: "12/10/2023",
      shareAmt: 200000,
      noOfShare: 20000,
      folioNo: 1001,
      dnoFromTo: "60001 - 80000",
      balance: 200000,
      issueFrom: "VL000002",
      payMode: "Cash",
      txnId: "T102959",
    },
  ];

  // Calculate totals
  const totalShareAmt = rows.reduce((total, row) => total + row.shareAmt, 0);
  const totalNoOfShare = rows.reduce((total, row) => total + row.noOfShare, 0);
  const totalBalance = rows.reduce((total, row) => total + row.balance, 0);

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Share Report
      </Typography>

      <Paper
        sx={{
          padding: 3,
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          "@media (max-width: 600px)": {
            padding: "10px",
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Search Box
        </Typography>

        <Grid container spacing={1}>
          {/* Branch Dropdown */}
          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Branch :
              </Typography>
              <TextField
                select
                sx={textFieldStyle}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
                <MenuItem value="br1">br1</MenuItem>
              </TextField>
            </Box>
          </Grid>

          {/* From Date */}
          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                From Date :
              </Typography>
              <TextField
                type="date"
                sx={{
                  ...textFieldStyle,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Box>
          </Grid>

          {/* To Date */}
          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                To Date :
              </Typography>
              <TextField
                type="date"
                sx={{
                  ...textFieldStyle,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  fontFamily: "Poppins",
                  background: "#DFBF41",
                  width: { xs: "100%", md: "157px" },
                  height: { md: "48px", xs: "40px" },
                  borderRadius: "10px",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "#B89B3E" },
                  color: "white",
                  mt: { md: 2.5, xs: 1 },
                  mb: { md: 0, xs: 2 },
                }}
              >
                Search
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ px: 6, py: 3, mt: 2, borderRadius: "20px" }}>
        {/* Header Section */}
        <Box sx={{ marginBottom: 1 }}>
          <Grid container alignItems="center">
            <Grid
              item
              xs={2}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Box sx={{ mr: 2 }}>
                <img
                  src={vllogo}
                  alt="vllogo"
                  style={{ height: "80px", width: "80px" }}
                />
              </Box>
            </Grid>
            <Grid item xs={10}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "16px", md: "20px" },
                  mb: 1,
                  fontFamily: "Poppins",
                }}
              >
                VENKETLAXMI URBAN PUNE NIDHI LTD
              </Typography>
              <Typography sx={{ fontFamily: "Poppins" }}>
                Reg. Office: SECOND FLOOR, GOLDEN EMPIRE, BESIDE CROMA, BANER
                ROAD, BANER - MAHARASHTRA - 411045
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ mt: 0, mb: 3, borderBottomWidth: 2 }} />

        <Typography
          sx={{
            textAlign: "center",
            textDecoration: "underline",
            fontWeight: "bold",
            fontSize: { xs: "16px", md: "20px" },
            mb: 2,
            fontFamily: "Poppins",
          }}
        >
          MEMBER SHARE REPORT
        </Typography>

        {/* Policy Interest Statement Section */}
        <Box sx={{ marginTop: 4 }}>
          <TableContainer
            component={Paper}
            sx={{ borderRadius: "20px 20px 0px 0px" }}
          >
            <Table size="small">
              <TableHead sx={{ background: "#D1D1D199" }}>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>SLNO</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>MEMBER CODE</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>MEMBER NAME</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>SHARE DATE</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>SHARE AMT</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>NO OF SHARE</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>FOLIO NO</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    DNO FRM & TO
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>BALANCE</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>ISSUE FROM</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>PAY MODE</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>TXN ID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.slno}>
                    <TableCell>{row.slno}</TableCell>
                    <TableCell>{row.memberCode}</TableCell>
                    <TableCell>{row.memberName}</TableCell>
                    <TableCell>{row.shareDate}</TableCell>
                    <TableCell>{row.shareAmt}</TableCell>
                    <TableCell>{row.noOfShare}</TableCell>
                    <TableCell>{row.folioNo}</TableCell>
                    <TableCell>{row.dnoFromTo}</TableCell>
                    <TableCell>{row.balance}</TableCell>
                    <TableCell>{row.issueFrom}</TableCell>
                    <TableCell>{row.payMode}</TableCell>
                    <TableCell>{row.txnId}</TableCell>
                  </TableRow>
                ))}
                {/* Grand Total Row */}
                <TableRow sx={{height: "50px"}}>
                  <TableCell colSpan={4} style={{ fontWeight: "bold" }}>
                    Grand Total
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    {totalShareAmt}
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    {totalNoOfShare}
                  </TableCell>
                  <TableCell colSpan={2}></TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    {totalBalance}
                  </TableCell>
                  <TableCell colSpan={3}></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
}

export default ShareReport;
