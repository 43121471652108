import React, { useEffect, useState } from "react";
import {
  TextField,
  Switch,
  Button,
  MenuItem,
  Box,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import DrdDepositMaturity from "./DrdDepositMaturity";

const DailyDeposit = () => {
  const textFieldStyle = {
    width: { xs: "100%", lg: "100%" },
    height: "48px",
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: "48px",
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    // opacity: 0.6,
    width: "100%",
    fontSize: { xs: "12px", md: "14px" },
    mb: 0,
    mt: 0,
  };

  const handleCalculate = (total, maturity) => {
    setFormData((prev) => ({
      ...prev,
      totalDeposit: total,
      maturityAmount: maturity,
    }));
  };

  const [formData, setFormData] = useState({
    planName: "",
    minimumAmount: "",
    interestRate: "",
    termMode: "",
    term: "",
    commissionNew: "",
    commissionRenew: "",
    compoundInterval: "",
    isFlexi: false,
    gracePeriod: "",
    lateFine: "",
    planStatus: true,
    totalDeposit: "0",
    maturityAmount: "0",
  });

  const [dailyPlans, setDailyPlans] = useState([]); // To store fetched data

  const fetchDailyPlans = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        "https://api.vlnidhi.com/api/admin/getalldd",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDailyPlans(response.data); // Store the fetched data in state
    } catch (error) {
      console.error("Error fetching daily plans:", error);
    }
  };

  useEffect(() => {
    fetchDailyPlans(); // Fetch data when the component mounts
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({ ...prev, [name]: checked }));
  };

  const calculateMaturityAndTotalDeposit = () => {
    const {
      minimumAmount,
      interestRate,
      term, // now treated as total days
      compoundInterval,
      isFlexi,
    } = formData;

    const principal = parseFloat(minimumAmount) || 0;
    const rate = parseFloat(interestRate) || 0;
    const totalDays = parseInt(term, 10) || 0;

    // Input validation
    if (!isFlexi) {
      if (principal <= 0 || rate <= 0 || rate > 100 || totalDays <= 0) {
        alert("Invalid inputs. Check minimum amount, interest rate, and term.");
        return;
      }

      const totalDeposit = principal * totalDays;

      const dailyDeposits = new Array(totalDays).fill(principal);

      const maturityAmount = calculateMaturityAmount(
        dailyDeposits,
        rate,
        totalDays,
        compoundInterval,
        false
      );

      setFormData((prev) => ({
        ...prev,
        totalDeposit: totalDeposit.toFixed(2),
        maturityAmount: maturityAmount.toFixed(2),
      }));
      // } else {
      //   alert("Flexi plans will be handled in the future.");
    }
  };

  const calculateMaturityAmount = (
    dailyDeposits,
    rate,
    totalDays,
    interval,
    isFlexi
  ) => {
    if (!dailyDeposits || dailyDeposits.length === 0) {
      throw new Error("Daily deposits array cannot be null or empty.");
    }
    if (rate <= 0 || rate > 100) {
      throw new Error("Interest rate must be between 0 and 100.");
    }
    if (totalDays <= 0) {
      throw new Error("Total days must be a positive integer.");
    }

    let compoundFrequency;
    switch (interval.toLowerCase()) {
      case "daily":
        compoundFrequency = 365;
        break;
      case "monthly":
        compoundFrequency = 12;
        break;
      case "quarterly":
        compoundFrequency = 4;
        break;
      case "half-yearly":
        compoundFrequency = 2;
        break;
      case "yearly":
        compoundFrequency = 1;
        break;
      default:
        throw new Error("Invalid compound interval.");
    }

    let totalMaturityAmount = 0.0;

    for (let i = 0; i < dailyDeposits.length; i++) {
      const deposit = parseFloat(dailyDeposits[i]) || 0;

      if (deposit < 0) {
        throw new Error("Deposit amounts cannot be negative.");
      }

      const daysRemaining = totalDays - i;

      // Apply compound interest formula: A = P(1 + r/n)^(nt)
      const compoundInterest =
        Math.pow(
          1 + rate / (100 * compoundFrequency),
          compoundFrequency * (daysRemaining / 365)
        ) - 1;

      totalMaturityAmount += deposit + deposit * compoundInterest;
    }

    return totalMaturityAmount;
  };

  const handleGenerate = () => {
    calculateMaturityAndTotalDeposit();
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        "https://api.vlnidhi.com/api/admin/createddplan",
        {
          planName: formData.planName,
          minimumAmount: parseFloat(formData.minimumAmount),
          interestRate: parseFloat(formData.interestRate),
          termMode: formData.termMode,
          term: parseInt(formData.term),
          commissionNew: parseFloat(formData.commissionNew),
          commissionRenew: parseFloat(formData.commissionRenew),
          compoundInterval: formData.compoundInterval,
          isFlexi: formData.isFlexi,
          gracePeriod: parseInt(formData.gracePeriod),
          lateFine: parseFloat(formData.lateFine),
          planStatus: formData.planStatus.toString(),
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      fetchDailyPlans();
      Swal.fire("Success", "Plan created successfully!", "success");
    } catch (error) {
      Swal.fire("Error", "Failed to create plan", "error");
    }
  };

  return (
    <Box>
      <Box>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Daily Deposit
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Plan Name<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              name="planName"
              value={formData.planName}
              onChange={handleInputChange}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Compound Interval<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              name="compoundInterval"
              value={formData.compoundInterval}
              onChange={handleInputChange}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            >
              <MenuItem value="daily">Daily</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="quarterly">Quarterly</MenuItem>
              <MenuItem value="half-yearly">Half-Yearly</MenuItem>
              <MenuItem value="yearly">Yearly</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Minimum Amount<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              name="minimumAmount"
              value={formData.minimumAmount}
              onChange={handleInputChange}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Total Deposit<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled
              name="totalDeposit"
              value={formData.totalDeposit}
              onChange={handleInputChange}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                  fontWeight: 1000,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Interest Rate (%)<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              name="interestRate"
              value={formData.interestRate}
              onChange={handleInputChange}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Maturity Amount<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled
              name="maturityAmount"
              value={formData.maturityAmount}
              onChange={handleInputChange}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  background: "#eee",
                  fontWeight: 1000,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Term Mode<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              name="termMode"
              value={formData.termMode}
              onChange={handleInputChange}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            >
              <MenuItem value="Daily">Daily</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Is Flexi?<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              select
              name="isFlexi"
              value={formData.isFlexi}
              onChange={handleInputChange}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            >
              <MenuItem value="false">NO</MenuItem>
              <MenuItem value="true">YES</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Term(in days)<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              name="term"
              value={formData.term}
              onChange={handleInputChange}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Grace Period<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              name="gracePeriod"
              value={formData.gracePeriod}
              onChange={handleInputChange}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Comm. New(%)<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              name="commissionNew"
              value={formData.commissionNew}
              onChange={handleInputChange}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Late Fine(%)<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              name="lateFine"
              value={formData.lateFine}
              onChange={handleInputChange}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Comm. Renew(%)<span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              name="commissionRenew"
              value={formData.commissionRenew}
              onChange={handleInputChange}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                ...fontStyle,
              }}
            >
              Plan Status<span style={{ color: "red" }}>*</span>
            </Typography>
            <Switch
              name="planStatus"
              checked={formData.planStatus}
              onChange={handleSwitchChange}
              sx={{ transform: "scale(1.5)" }}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 3,
            gap: "2%",
          }}
        >
          <Button
            variant="contained"
            onClick={handleGenerate}
            sx={{
              textTransform: "none",
              fontFamily: "Poppins",
              background: "#A82532",
              width: { xs: "50%", md: "157px" },
              height: "48px",
              borderRadius: "10px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#702129" },
            }}
          >
            Generate
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              textTransform: "none",
              fontFamily: "Poppins",
              background: "#E8C30B",
              width: { xs: "50%", md: "157px" },
              height: "48px",
              borderRadius: "10px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#a99220" },
            }}
          >
            Save
          </Button>
        </Box>
      </Box>

      <DrdDepositMaturity
        minimumAmount={formData.minimumAmount}
        interestRate={formData.interestRate}
        term={formData.term}
        compoundInterval={formData.compoundInterval}
        isFlexi={formData.isFlexi}
        onCalculate={handleCalculate} // Handle calculated values
      />

      <Box sx={{ mt: 3, borderRadius: "20px" }}>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Daily Plan
        </Typography>
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "10px 10px 0px 0px",
            width: { xs: "320px", md: "100%", lg: "100%" },
          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow style={{ backgroundColor: "#3F00FF" }}>
                <TableCell style={{ color: "#fff" }}>Plan Code</TableCell>
                <TableCell style={{ color: "#fff" }}>Plan Name</TableCell>
                <TableCell style={{ color: "#fff" }}>Term</TableCell>
                <TableCell style={{ color: "#fff" }}>ROI</TableCell>
                <TableCell style={{ color: "#fff" }}>Mode</TableCell>
                <TableCell style={{ color: "#fff" }}>Comm(N)</TableCell>
                <TableCell style={{ color: "#fff" }}>Comm(R)</TableCell>
                <TableCell style={{ color: "#fff" }}>isFlexi</TableCell>
                <TableCell style={{ color: "#fff" }}>MinAmount</TableCell>
                <TableCell style={{ color: "#fff" }}>Grace Days</TableCell>
                <TableCell style={{ color: "#fff" }}>Late Fine</TableCell>
                {/* <TableCell style={{ color: "#fff" }}>View</TableCell>
                <TableCell style={{ color: "#fff" }}>Action</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {dailyPlans.map((plan) => (
                <TableRow key={plan.id}>
                  <TableCell>{plan.planCode}</TableCell>
                  <TableCell>{plan.planName}</TableCell>
                  <TableCell>{plan.term}</TableCell>
                  <TableCell>{plan.interestRate}%</TableCell>
                  <TableCell>{plan.termMode}</TableCell>
                  <TableCell>{plan.commissionNew}%</TableCell>
                  <TableCell>{plan.commissionRenew}%</TableCell>
                  <TableCell>{plan.isFlexi ? "Yes" : "No"}</TableCell>
                  <TableCell>{plan.minimumAmount}</TableCell>
                  <TableCell>{plan.gracePeriod}</TableCell>
                  <TableCell>{plan.lateFine}%</TableCell>
                  {/* <TableCell>
                    <Button variant="contained">View</Button>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default DailyDeposit;

// import React, { useState } from "react";
// import {
//   TextField,
//   Switch,
//   Button,
//   MenuItem,
//   Box,
//   Typography,
//   Grid,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   IconButton,
// } from "@mui/material";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import ClearIcon from "@mui/icons-material/Clear";
// import axios from "axios";
// import Swal from "sweetalert2";
// import * as Yup from "yup";
// import { useFormik } from "formik";

// // Validation schema for 'Generate' button
// const generateValidationSchema = Yup.object().shape({
//   minimumAmount: Yup.number().required("Minimum Amount is required"),
//   interestRate: Yup.number().required("Interest Rate is required"),
//   termMode: Yup.string().required("Term Mode is required"),
//   term: Yup.number().required("Term is required"),
//   compoundInterval: Yup.string().required("Compound Interval is required"),
//   isFlexi: Yup.boolean().required("Is Flexi? is required"),
// });

// // Validation schema for 'Save' button (all fields required)
// const saveValidationSchema = Yup.object().shape({
//   planName: Yup.string().required("Plan Name is required"),
//   minimumAmount: Yup.number().required("Minimum Amount is required"),
//   interestRate: Yup.number().required("Interest Rate is required"),
//   termMode: Yup.string().required("Term Mode is required"),
//   term: Yup.number().required("Term is required"),
//   commissionNew: Yup.number().required("Commission New is required"),
//   commissionRenew: Yup.number().required("Commission Renew is required"),
//   compoundInterval: Yup.string().required("Compound Interval is required"),
//   isFlexi: Yup.boolean().required("Is Flexi? is required"),
//   gracePeriod: Yup.number().required("Grace Period is required"),
//   lateFine: Yup.number().required("Late Fine is required"),
//   // planStatus: Yup.boolean().required("Plan Status is required"),
// });

// const DailyDeposit = () => {
//   const formik = useFormik({
//     initialValues: {
//       planName: "",
//       minimumAmount: "",
//       interestRate: "",
//       termMode: "",
//       term: "",
//       commissionNew: "",
//       commissionRenew: "",
//       compoundInterval: "",
//       isFlexi: false,
//       gracePeriod: "",
//       lateFine: "",
//       planStatus: false,
//       totalDeposit: "",
//       maturityAmount: "",
//     },
//     validationSchema: generateValidationSchema, // Default validation schema for 'Generate'
//     onSubmit: (values) => {
//       // Call handleGenerate when form is submitted for the 'Generate' button
//       handleGenerate(values);
//     },
//   });

//   const textFieldStyle = {
//     width: { xs: "100%", lg: "100%" },
//     height: "48px",
//     borderColor: "#D9D9D9CC",
//     backgroundColor: "#FFFFFF",
//     "& .MuiInputBase-root": {
//       height: "48px",
//     },
//   };

//   const fontStyle = {
//     fontFamily: "Poppins, sans-serif",
//     // opacity: 0.6,
//     width: "100%",
//     fontSize: { xs: "12px", md: "14px" },
//     mb: 0,
//     mt: 0,
//   };

//   const data = [
//     {
//       planName: "DRD",
//       planCode: "VENKETLAXMI HONEYBEE",
//       term: 365,
//       roi: 7.2,
//       mode: "Dly.",
//       commN: 3,
//       commR: 3,
//       isFlexi: "No",
//       minAmount: 100,
//       graceDays: 30,
//       lateFine: 0,
//     },
//   ];

//   const [formData, setFormData] = useState({
//     planName: "",
//     minimumAmount: "",
//     interestRate: "",
//     termMode: "",
//     term: "",
//     commissionNew: "",
//     commissionRenew: "",
//     compoundInterval: "",
//     isFlexi: false,
//     gracePeriod: "",
//     lateFine: "",
//     planStatus: false,
//     totalDeposit: "",
//     maturityAmount: "",
//   });

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prev) => ({ ...prev, [name]: value }));
//   };

//   const handleSwitchChange = (e) => {
//     const { name, checked } = e.target;
//     setFormData((prev) => ({ ...prev, [name]: checked }));
//   };

//   // ----------------------------------------------------------

//   const calculateMaturityAndTotalDeposit = () => {
//     const { minimumAmount, interestRate, term, compoundInterval, isFlexi } =
//       formData;

//     // Calculate total deposit
//     const totalDeposit = isFlexi ? formData.totalDeposit : minimumAmount * 365;

//     // Calculate maturity amount
//     const maturityAmount = calculateMaturityAmount(
//       totalDeposit,
//       interestRate,
//       term,
//       compoundInterval,
//       isFlexi
//     );

//     setFormData((prev) => ({
//       ...prev,
//       totalDeposit,
//       maturityAmount,
//     }));
//   };

//   const calculateMaturityAmount = (
//     principal,
//     rate,
//     term,
//     interval,
//     isFlexi
//   ) => {
//     let compoundFrequency;

//     switch (interval.toLowerCase()) {
//       case "daily":
//         compoundFrequency = 365;
//         break;
//       case "monthly":
//         compoundFrequency = 12;
//         break;
//       case "quarterly":
//         compoundFrequency = 4;
//         break;
//       case "half-yearly":
//         compoundFrequency = 2;
//         break;
//       case "yearly":
//         compoundFrequency = 1;
//         break;
//       default:
//         throw new Error("Invalid compound interval");
//     }

//     const compoundInterest =
//       Math.pow(
//         1 + rate / (100 * compoundFrequency),
//         compoundFrequency * (term / 365)
//       ) - 1;
//     const maturityAmount = principal + principal * compoundInterest;

//     return maturityAmount;
//   };

//   // const handleGenerate = () => {
//   //   calculateMaturityAndTotalDeposit();
//   //   Swal.fire({
//   //     title: "Success",
//   //     text: "Total Deposit and Maturity Amount calculated!",
//   //     icon: "success",
//   //   });
//   // };

//   const handleGenerate = () => {
//     if (!formik.isValid) {
//       Swal.fire(
//         "Error",
//         "Please fill the required fields for generation",
//         "error"
//       );
//       return;
//     }
//     // Calculate Total Deposit and Maturity Amount
//     calculateMaturityAndTotalDeposit();
//     Swal.fire({
//       title: "Success",
//       text: "Total Deposit and Maturity Amount calculated!",
//       icon: "success",
//     });
//   };

//   const handleSave = () => {
//     const valid = saveValidationSchema.isValidSync(formik.values);
//     if (!valid) {
//       Swal.fire("Error", "Please fill all required fields", "error");
//       return;
//     }
//     // Submit data for the 'Save' action
//     handleSubmit();
//   };

//   // ----------------------------------------------------------

//   const handleSubmit = async () => {
//     const token = localStorage.getItem("token");

//     try {
//       const response = await axios.post(
//         "https://api.vlnidhi.com/admin/daily-deposit/create",
//         {
//           planName: formData.planName,
//           minimumAmount: parseFloat(formData.minimumAmount),
//           interestRate: parseFloat(formData.interestRate),
//           termMode: formData.termMode,
//           term: parseInt(formData.term),
//           commissionNew: parseFloat(formData.commissionNew),
//           commissionRenew: parseFloat(formData.commissionRenew),
//           compoundInterval: formData.compoundInterval,
//           isFlexi: formData.isFlexi,
//           gracePeriod: parseInt(formData.gracePeriod),
//           lateFine: parseFloat(formData.lateFine),
//           planStatus: formData.planStatus.toString(),
//         },
//         {
//           headers: { Authorization: `Bearer ${token}` },
//         }
//       );

//       Swal.fire("Success", "Plan created successfully!", "success");
//     } catch (error) {
//       Swal.fire("Error", "Failed to create plan", "error");
//     }
//   };

//   return (
//     <Box>
//       <form onSubmit={formik.handleSubmit}>
//         <Box>
//           <Typography
//             sx={{
//               fontFamily: "Poppins",
//               fontSize: "20px",
//               fontWeight: 600,
//               lineHeight: "36px",
//               mb: 1,
//               mt: 0,
//             }}
//           >
//             Daily Deposit
//           </Typography>
//           <Grid container spacing={2}>
//             <Grid item xs={12} md={6}>
//               <Typography
//                 sx={{
//                   ...fontStyle,
//                 }}
//               >
//                 Plan Name<span style={{color: "red", opacity: "200%"}}>*</span>
//               </Typography>
//               <TextField
//                 name="planName"
//                 value={formik.values.planName}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 error={
//                   formik.touched.planName && Boolean(formik.errors.planName)
//                 }
//                 helperText={formik.touched.planName && formik.errors.planName}
//                 sx={{
//                   ...textFieldStyle,
//                 }}
//                 InputProps={{
//                   style: {
//                     borderRadius: "10px",
//                     // height: "48px",
//                   },
//                 }}
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Typography
//                 sx={{
//                   ...fontStyle,
//                 }}
//               >
//                 Compound Interval<span style={{color: "red"}}>*</span>
//               </Typography>
//               <TextField
//                 select
//                 name="compoundInterval"
//                 value={formik.values.compoundInterval}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 error={
//                   formik.touched.compoundInterval &&
//                   Boolean(formik.errors.compoundInterval)
//                 }
//                 helperText={
//                   formik.touched.compoundInterval &&
//                   formik.errors.compoundInterval
//                 }
//                 sx={{
//                   ...textFieldStyle,
//                 }}
//                 InputProps={{
//                   style: {
//                     borderRadius: "10px",
//                   },
//                 }}
//               >
//                 <MenuItem value="Daily">Daily</MenuItem>
//                 <MenuItem value="Monthly">Monthly</MenuItem>
//                 <MenuItem value="Quarterly">Quarterly</MenuItem>
//                 <MenuItem value="Half-Yearly">Half-Yearly</MenuItem>
//                 <MenuItem value="Yearly">Yearly</MenuItem>
//               </TextField>
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Typography
//                 sx={{
//                   ...fontStyle,
//                 }}
//               >
//                 Minimum Amount<span style={{color: "red"}}>*</span>
//               </Typography>
//               <TextField
//                 name="minimumAmount"
//                 value={formik.values.minimumAmount}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 error={
//                   formik.touched.minimumAmount &&
//                   Boolean(formik.errors.minimumAmount)
//                 }
//                 helperText={
//                   formik.touched.minimumAmount && formik.errors.minimumAmount
//                 }
//                 sx={{
//                   ...textFieldStyle,
//                 }}
//                 InputProps={{
//                   style: {
//                     borderRadius: "10px",
//                   },
//                 }}
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Typography
//                 sx={{
//                   ...fontStyle,
//                 }}
//               >
//                 Total Deposit<span style={{color: "red"}}>*</span>
//               </Typography>
//               <TextField
//                 disabled
//                 name="totalDeposit"
//                 value={formik.values.totalDeposit}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 error={
//                   formik.touched.totalDeposit && Boolean(formik.errors.totalDeposit)
//                 }
//                 helperText={formik.touched.totalDeposit && formik.errors.totalDeposit}
//                 sx={{
//                   ...textFieldStyle,
//                 }}
//                 InputProps={{
//                   style: {
//                     borderRadius: "10px",
//                     background: "#eee",
//                   },
//                 }}
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Typography
//                 sx={{
//                   ...fontStyle,
//                 }}
//               >
//                 Interest Rate (%)<span style={{color: "red"}}>*</span>
//               </Typography>
//               <TextField
//                 name="interestRate"
//                 value={formik.values.interestRate}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 error={
//                   formik.touched.interestRate && Boolean(formik.errors.interestRate)
//                 }
//                 helperText={formik.touched.interestRate && formik.errors.interestRate}
//                 sx={{
//                   ...textFieldStyle,
//                 }}
//                 InputProps={{
//                   style: {
//                     borderRadius: "10px",
//                   },
//                 }}
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Typography
//                 sx={{
//                   ...fontStyle,
//                 }}
//               >
//                 Maturity Amount<span style={{color: "red"}}>*</span>
//               </Typography>
//               <TextField
//                 disabled
//                 name="maturityAmount"
//                 value={formik.values.maturityAmount}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 error={formik.touched.maturityAmount && Boolean(formik.errors.maturityAmount)}
//                 helperText={formik.touched.maturityAmount && formik.errors.maturityAmount}

//                 sx={{
//                   ...textFieldStyle,
//                 }}
//                 InputProps={{
//                   style: {
//                     borderRadius: "10px",
//                     background: "#eee",
//                   },
//                 }}
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Typography
//                 sx={{
//                   ...fontStyle,
//                 }}
//               >
//                 Term Mode<span style={{color: "red"}}>*</span>
//               </Typography>
//               <TextField
//                 select
//                 name="termMode"
//                 value={formik.values.termMode}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 error={formik.touched.termMode && Boolean(formik.errors.termMode)}
//                 helperText={formik.touched.termMode && formik.errors.termMode}

//                 sx={{
//                   ...textFieldStyle,
//                 }}
//                 InputProps={{
//                   style: {
//                     borderRadius: "10px",
//                   },
//                 }}
//               >
//                 <MenuItem value="Daily">Daily</MenuItem>
//               </TextField>
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Typography
//                 sx={{
//                   ...fontStyle,
//                 }}
//               >
//                 Is Flexi?<span style={{color: "red"}}>*</span>
//               </Typography>
//               <TextField
//                 select
//                 name="isFlexi"
//                 value={formik.values.isFlexi}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 error={formik.touched.isFlexi && Boolean(formik.errors.isFlexi)}
//                 helperText={formik.touched.isFlexi && formik.errors.isFlexi}

//                 sx={{
//                   ...textFieldStyle,
//                 }}
//                 InputProps={{
//                   style: {
//                     borderRadius: "10px",
//                   },
//                 }}
//               >
//                 <MenuItem value="false">NO</MenuItem>
//                 <MenuItem value="true">YES</MenuItem>
//               </TextField>
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Typography
//                 sx={{
//                   ...fontStyle,
//                 }}
//               >
//                 Term<span style={{color: "red"}}>*</span>
//               </Typography>
//               <TextField
//                 name="term"
//                 value={formik.values.term}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 error={formik.touched.term && Boolean(formik.errors.term)}
//                 helperText={formik.touched.term && formik.errors.term}

//                 sx={{
//                   ...textFieldStyle,
//                 }}
//                 InputProps={{
//                   style: {
//                     borderRadius: "10px",
//                   },
//                 }}
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Typography
//                 sx={{
//                   ...fontStyle,
//                 }}
//               >
//                 Grace Period<span style={{color: "red"}}>*</span>
//               </Typography>
//               <TextField
//                 name="gracePeriod"
//                 value={formik.values.gracePeriod}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 error={formik.touched.gracePeriod && Boolean(formik.errors.gracePeriod)}
//                 helperText={formik.touched.gracePeriod && formik.errors.gracePeriod}

//                 sx={{
//                   ...textFieldStyle,
//                 }}
//                 InputProps={{
//                   style: {
//                     borderRadius: "10px",
//                   },
//                 }}
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Typography
//                 sx={{
//                   ...fontStyle,
//                 }}
//               >
//                 Comm. New(%)<span style={{color: "red"}}>*</span>
//               </Typography>
//               <TextField
//                 name="commissionNew"
//                 value={formik.values.commissionNew}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 error={formik.touched.commissionNew && Boolean(formik.errors.commissionNew)}
//                 helperText={formik.touched.commissionNew && formik.errors.commissionNew}

//                 sx={{
//                   ...textFieldStyle,
//                 }}
//                 InputProps={{
//                   style: {
//                     borderRadius: "10px",
//                   },
//                 }}
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Typography
//                 sx={{
//                   ...fontStyle,
//                 }}
//               >
//                 Late Fine(%)<span style={{color: "red"}}>*</span>
//               </Typography>
//               <TextField
//                 name="lateFine"
//                 value={formik.values.lateFine}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 error={formik.touched.lateFine && Boolean(formik.errors.lateFine)}
//                 helperText={formik.touched.lateFine && formik.errors.lateFine}

//                 sx={{
//                   ...textFieldStyle,
//                 }}
//                 InputProps={{
//                   style: {
//                     borderRadius: "10px",
//                   },
//                 }}
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Typography
//                 sx={{
//                   ...fontStyle,
//                 }}
//               >
//                 Comm. Renew(%)<span style={{color: "red"}}>*</span>
//               </Typography>
//               <TextField
//                 name="commissionRenew"
//                 value={formik.values.commissionRenew}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 error={formik.touched.commissionRenew && Boolean(formik.errors.commissionRenew)}
//                 helperText={formik.touched.commissionRenew && formik.errors.commissionRenew}

//                 sx={{
//                   ...textFieldStyle,
//                 }}
//                 InputProps={{
//                   style: {
//                     borderRadius: "10px",
//                   },
//                 }}
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Typography
//                 sx={{
//                   ...fontStyle,
//                 }}
//               >
//                 Plan Status<span style={{color: "red"}}>*</span>
//               </Typography>
//               <Switch
//                 name="planStatus"
//                 checked={formik.values.planStatus}
//                 onChange={(e) =>
//                   formik.setFieldValue("planStatus", e.target.checked)
//                 }
//                 sx={{ transform: "scale(1.5)" }}
//               />
//             </Grid>
//           </Grid>
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "flex-end",
//               mt: 3,
//               gap: "2%",
//             }}
//           >
//             <Button
//               variant="contained"
//               type="submit"
//               // onClick={handleGenerate}
//               sx={{
//                 textTransform: "none",
//                 fontFamily: "Poppins",
//                 background: "#A82532",
//                 width: { xs: "50%", md: "157px" },
//                 height: "48px",
//                 borderRadius: "10px",
//                 fontWeight: "bold",
//                 "&:hover": { backgroundColor: "#702129" },
//               }}
//             >
//               Generate
//             </Button>
//             <Button
//               variant="contained"
//               // onClick={handleSubmit}
//                type="button"
//               onClick={handleSave}
//               sx={{
//                 textTransform: "none",
//                 fontFamily: "Poppins",
//                 background: "#E8C30B",
//                 width: { xs: "50%", md: "157px" },
//                 height: "48px",
//                 borderRadius: "10px",
//                 fontWeight: "bold",
//                 "&:hover": { backgroundColor: "#a99220" },
//               }}
//             >
//               Save
//             </Button>
//           </Box>
//         </Box>
//       </form>
//       <Box sx={{ mt: 3, borderRadius: "20px" }}>
//         <Typography
//           sx={{
//             fontFamily: "Poppins",
//             fontSize: "20px",
//             fontWeight: 600,
//             lineHeight: "36px",
//             mb: 1,
//             mt: 0,
//           }}
//         >
//           Daily Plan
//         </Typography>
//         <TableContainer
//           component={Paper}
//           sx={{
//             borderRadius: "10px 10px 0px 0px",
//             width: { xs: "320px", md: "100%", lg: "100%" },
//           }}
//         >
//           <Table size="small">
//             <TableHead>
//               <TableRow style={{ backgroundColor: "#3F00FF" }}>
//                 <TableCell style={{ color: "#fff" }}>Plan Name</TableCell>
//                 <TableCell style={{ color: "#fff" }}>Plan Code</TableCell>
//                 <TableCell style={{ color: "#fff" }}>Term</TableCell>
//                 <TableCell style={{ color: "#fff" }}>ROI</TableCell>
//                 <TableCell style={{ color: "#fff" }}>Mode</TableCell>
//                 <TableCell style={{ color: "#fff" }}>Comm(N)</TableCell>
//                 <TableCell style={{ color: "#fff" }}>Comm(R)</TableCell>
//                 <TableCell style={{ color: "#fff" }}>isFlexi</TableCell>
//                 <TableCell style={{ color: "#fff" }}>MinAmount</TableCell>
//                 <TableCell style={{ color: "#fff" }}>Grace Days</TableCell>
//                 <TableCell style={{ color: "#fff" }}>Late Fine</TableCell>
//                 <TableCell style={{ color: "#fff" }}>View</TableCell>
//                 <TableCell style={{ color: "#fff" }}>Action</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {data.map((row, index) => (
//                 <TableRow
//                   key={index}
//                   style={{
//                     backgroundColor: index % 2 === 1 ? "#E2EDFF" : "#fff",
//                   }}
//                 >
//                   <TableCell>{row.planName}</TableCell>
//                   <TableCell>{row.planCode}</TableCell>
//                   <TableCell>{row.term}</TableCell>
//                   <TableCell>{row.roi}</TableCell>
//                   <TableCell>{row.mode}</TableCell>
//                   <TableCell>{row.commN}</TableCell>
//                   <TableCell>{row.commR}</TableCell>
//                   <TableCell>{row.isFlexi}</TableCell>
//                   <TableCell>{row.minAmount}</TableCell>
//                   <TableCell>{row.graceDays}</TableCell>
//                   <TableCell>{row.lateFine}</TableCell>
//                   <TableCell>
//                     <IconButton>
//                       <VisibilityIcon fontSize="small" />
//                     </IconButton>
//                   </TableCell>
//                   <TableCell>
//                     <IconButton>
//                       <ClearIcon fontSize="small" />
//                     </IconButton>
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </Box>
//     </Box>
//   );
// };

// export default DailyDeposit;
