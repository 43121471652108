import React, { useRef } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as XLSX from "xlsx"; // Import XLSX for Excel export

function UnallotedShares() {
  const contentRef = useRef(null);

  // Data for the table
  const tableData = [
    {
      mCode: "128",
      mName: "John Doe",
      shareDate: "14/05/2024",
      shareAmt: "10,000.00",
      numShare: "315.62",
      folioNo: "10,315.62",
      allotedFrom: "14/05/2024",
      allotedTo: "19/09/2024",
      unallotedFrom: "128",
      unallotedToId: "10,000.00",
      userId: "315.62",
    },
    {
      mCode: "93",
      mName: "Jane Smith",
      shareDate: "18/06/2024",
      shareAmt: "10,000.00",
      numShare: "229.32",
      folioNo: "10,229.32",
      allotedFrom: "14/05/2024",
      allotedTo: "19/09/2024",
      unallotedFrom: "128",
      unallotedToId: "10,000.00",
      userId: "315.62",
    },
  ];

  const handlePdfDownload = async () => {
    const content = contentRef.current;
    const canvas = await html2canvas(content);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    const imgWidth = 210; // A4 width in mm
    const pageHeight = 295; // A4 height in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    pdf.save("unalloted-shares-report.pdf");
  };

  const handleXlsDownload = () => {
    const worksheet = XLSX.utils.json_to_sheet(tableData); // Convert table data to sheet
    const workbook = XLSX.utils.book_new(); // Create a new workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Unalloted Shares"); // Append the sheet
    XLSX.writeFile(workbook, "unalloted-shares-report.xlsx"); // Save the file
  };

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Unalloted Share Report
      </Typography>

      <Paper
        sx={{
          padding: 3,
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          "@media (max-width: 600px)": {
            padding: "10px",
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "36px",
              mb: 1,
              mt: 0,
            }}
          >
            Search Result
          </Typography>
          <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
            <Button
              variant="contained"
              onClick={handlePdfDownload}
              sx={{
                width: "200px",
                borderRadius: "20px",
                textTransform: "none",
                background: "#D1D1D1",
                color: "black",
                ":hover": { background: "#c7c0c0" },
              }}
            >
              Download PDF
            </Button>
            <Button
              variant="contained"
              onClick={handleXlsDownload}
              sx={{
                width: "200px",
                borderRadius: "20px",
                textTransform: "none",
                background: "#D1D1D1",
                color: "black",
                ":hover": { background: "#c7c0c0" },
              }}
            >
              Download XLS
            </Button>
          </Box>
        </Box>

        {/* Table */}
        <Box ref={contentRef}>
          <TableContainer
            component={Paper}
            sx={{ borderRadius: "20px 20px 0px 0px" }}
          >
            <Table>
              <TableHead sx={{ background: "#413AF1", color: "white" }}>
                <TableRow>
                  <TableCell sx={{ color: "#fff" }}>
                    <strong>M.Code</strong>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <strong>M.Name</strong>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <strong>Share Date</strong>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <strong>Share AMT</strong>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <strong>No. of Share</strong>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <strong>Folio No</strong>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <strong>Alloted From</strong>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <strong>Alloted To</strong>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <strong>Unalloted From</strong>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <strong>Unalloted to ID</strong>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <strong>User ID</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ background: "#F2F6FA" }}>
                {tableData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.mCode}</TableCell>
                    <TableCell>{row.mName}</TableCell>
                    <TableCell>{row.shareDate}</TableCell>
                    <TableCell>{row.shareAmt}</TableCell>
                    <TableCell>{row.numShare}</TableCell>
                    <TableCell>{row.folioNo}</TableCell>
                    <TableCell>{row.allotedFrom}</TableCell>
                    <TableCell>{row.allotedTo}</TableCell>
                    <TableCell>{row.unallotedFrom}</TableCell>
                    <TableCell>{row.unallotedToId}</TableCell>
                    <TableCell>{row.userId}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
}

export default UnallotedShares;
